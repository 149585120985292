import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class ReportEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = ``;
        this.transformer = null;
    }
    getAll(data) {
        return axios.get(`/datasources/${data.apiData.datasourceSlug}/reports`);
    }
    getDynamicParamOptions(data) {
        return axios.post(`/dynamic_parameter/${data.apiData.parameter_id}/get_options`, data.apiData.data);
    }
    createWebhookReport(data) {
        console.log(data);
        return axios.post(`/datasources/${data.apiData.dataSourceSlug}/reports/add/webhooks`, data.apiData.reportData);
    }
}

export default ReportEndpoints;
