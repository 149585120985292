import React from "react";
import { Button, CircularProgress, Fade, Grid, LinearProgress, Modal, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import config from "constants/config";
import { useDispatch, useSelector } from "react-redux";
import useApi from "hooks/useApi";
import Announcements from "components/common/Announcements/AnnouncementMenu/AnnouncementMenu";
import { fetchLatestAnnouncements } from "redux/actions/usersAction";
import { useNavigate } from "react-router";
const useStyles = makeStyles((theme) => ({
    cross: {
        // margin: "15px 20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4),
        borderRadius: "2px",
        border: "1px solid #E40D0D",
        position: "relative",
        width: "60vw",
        height: "80vh",
        overflow: "scroll",
    },
    icon: {
        paddingRight: "36px",
        height: 50,
    },
}));

function AnnouncementMenu() {
    const dispatch = useDispatch();
    const latestAnnouncements = useSelector((state) => state.announcement.latestAnnouncements);
    const latestAnnouncementsIsLoading = useSelector((state) => state.announcement.latestAnnouncementsIsLoading);
    const navigate = useNavigate();
    React.useEffect(() => {
        dispatch(fetchLatestAnnouncements());
        dispatch({ type: "CLOSE_ANNOUNCEMENT_BANNER", payload: true });
    }, []);

    if (latestAnnouncementsIsLoading && latestAnnouncements.length === 0) {
        return (
            <Grid container style={{ width: "400px", height: "200px" }} justifyContent="center" alignItems="center">
                <CircularProgress size={16} />
            </Grid>
        );
    }
    if (!latestAnnouncementsIsLoading) {
        let noUpdates = true;
        latestAnnouncements.forEach((element) => {
            if (element.items.length > 0) {
                noUpdates = false;
            }
        });
        if (noUpdates) {
            return (
                <Grid style={{ width: "400px", borderTop: "10px #FD9567 solid", marginBottom: 20, marginTop: 0, paddingTop: 30 }}>
                    <Typography
                        style={{ fontSize: 16, fontWeight: 600, fontFamily: "Montserrat", color: "#13253F", textAlign: "center" }}
                    >
                        No new updates
                    </Typography>
                </Grid>
            );
        }
    }
    return (
        <Grid style={{ width: "400px", borderTop: "10px #FD9567 solid", marginBottom: 20, padding: 20 }}>
            {latestAnnouncementsIsLoading && latestAnnouncements.length !== 0 && <LinearProgress />}
            <Grid style={{ height: "30px", marginBottom: 20, marginTop: 10 }}>
                <Typography
                    style={{ fontSize: 16, fontWeight: 700, fontFamily: "Montserrat", color: "#13253F", textAlign: "center" }}
                >
                    What’s New at DataChannel
                </Typography>
            </Grid>
            <Announcements
                newConnectorUpdate={latestAnnouncements.filter((ele) => ele.collection_slug === "integration")[0]}
                newUpdates={latestAnnouncements.filter((ele) => ele.collection_slug === "updates")[0]}
                destinations={latestAnnouncements.filter((ele) => ele.collection_slug === "destinations")[0]}
                blogs={latestAnnouncements.filter((ele) => ele.collection_slug === "blogs")[0]}
            />
            <Grid container justifyContent="center" xs={12}>
                <Button onClick={() => navigate("/product-updates")} variant="outlined" color="primary">
                    See All Updates
                </Button>
            </Grid>
        </Grid>
    );
}
export default AnnouncementMenu;
