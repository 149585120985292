class ScheduleTransormation {
	getSchedulesPostTransformer(data) {
		return [
			...data,
			{
				id: -1,
				schedule_frequency_label: "Advanced Options",
				schedule_frequency_level: 5
			},
			{
				id: -2,
				schedule_frequency_label: "Advanced Options - Hourly",
				schedule_frequency_level: 6
			}
		];
	}
}

export default ScheduleTransormation;
