import {
    SET_TOKENS
} from "../actionTypes/actionTypes"

const initialState = {
    accessToken: "",
    refreshToken: "",
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKENS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken
            }
        default:
            return state
    }
};
