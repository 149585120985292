const moment = require("moment-timezone");

class WorkspaceTransformation {
	getErrorsForDatasourcePreTransformer(data) {
		const toString = moment(data.apiData.data.from).format("YYYY-MM-DD") + " 23:59:59";
		const fromString = moment(data.apiData.data.to).format("YYYY-MM-DD") + " 00:00:00";
		const queryString = `?from_date=${fromString}&to_date=${toString}`;
		data.apiData.data = queryString;
		return data;
	}
	getErrorsForDatasourcePostTransformer(errorsList, hookData) {
		let groupedData = [];
		let errors = errorsList.sort((a, b) => new Date(b.date) - new Date(a.date));
		errors.forEach((el) => {
			let date = new Date(el.date).toDateString();
			let filteredDate = groupedData.filter(
				(element) =>
					new Date(element.date === "Today" ? new Date().toDateString() : element.date).toDateString() ===
					date
			);
			if (filteredDate.length !== 0) {
				filteredDate[0].errors.push({
					reportName: el.dataset_name,
					msg: "Missing Data",
					timeStamp:
						new Date(el.date).toDateString() === new Date().toDateString()
							? moment(el.date).tz(hookData.transformationData.timezone).fromNow()
							: moment(el.date).tz(hookData.transformationData.timezone).format("h:mm a"),
					reportId: el.report_id
				});
			} else {
				groupedData.push({
					date:
						new Date(el.date).toDateString() === new Date().toDateString()
							? "Today"
							: moment(el.date).tz(hookData.transformationData.timezone).format("dddd MMM DD YYYY"),
					errors: [
						{
							reportName: el.dataset_name,
							msg: "Missing Data",
							timeStamp:
								new Date(el.date).toDateString() === new Date().toDateString()
									? moment(el.date).tz(hookData.transformationData.timezone).fromNow()
									: moment(el.date).tz(hookData.transformationData.timezone).format("h:mm a"),
							reportId: el.report_id
						}
					]
				});
			}
		});
		return groupedData;
	}
	getPartialSucesssForDatasourcePreTransformer(data) {
		const toString = moment(data.apiData.data.from).format("YYYY-MM-DD") + " 23:59:59";
		const fromString = moment(data.apiData.data.to).format("YYYY-MM-DD") + " 00:00:00";
		const queryString = `?from_date=${fromString}&to_date=${toString}`;
		data.apiData.data = queryString;
		return data;
	}
	getPartialSucesssForDatasourcePostTransformer(errorsList, hookData) {
		let groupedData = [];
		let errors = errorsList.sort((a, b) => new Date(b.date) - new Date(a.date));
		errors.forEach((el) => {
			let date = new Date(el.date).toDateString();
			let filteredDate = groupedData.filter(
				(element) =>
					new Date(element.date === "Today" ? new Date().toDateString() : element.date).toDateString() ===
					date
			);
			if (filteredDate.length !== 0) {
				filteredDate[0].errors.push({
					reportName: el.dataset_name,
					msg: "Missing Data",
					timeStamp:
						new Date(el.date).toDateString() === new Date().toDateString()
							? moment(el.date).tz(hookData.transformationData.timezone).fromNow()
							: moment(el.date).tz(hookData.transformationData.timezone).format("h:mm a"),
					reportId: el.report_id
				});
			} else {
				groupedData.push({
					date:
						new Date(el.date).toDateString() === new Date().toDateString()
							? "Today"
							: moment(el.date).tz(hookData.transformationData.timezone).format("dddd MMM DD YYYY"),
					errors: [
						{
							reportName: el.dataset_name,
							msg: "Missing Data",
							timeStamp:
								new Date(el.date).toDateString() === new Date().toDateString()
									? moment(el.date).tz(hookData.transformationData.timezone).fromNow()
									: moment(el.date).tz(hookData.transformationData.timezone).format("h:mm a"),
							reportId: el.report_id
						}
					]
				});
			}
		});
		return groupedData;
	}
}

export default WorkspaceTransformation;
