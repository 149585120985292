
const moment = require("moment-timezone");

class ConfigureReportTransormation {
	getReportRunLogGraphPostTransformer(data,endPointData) {
        const newArray = [];
        console.log(endPointData.apiData.timezone)
        data.forEach((el) => {
            const tmp = {
                date: new Date(el.ts_created).toUTCString(),
                time: moment(el.ts_created).tz(endPointData.apiData.timezone).hour(),
                status: el?.status,
                rows_moved: el.rows_moved,
                data_moved: (el.data_moved / 1000).toFixed(2) + "KB"
            };
            newArray.push(tmp);
        });
        return newArray;
    }
}

export default ConfigureReportTransormation;