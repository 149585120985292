import React from "react";
import { ListItem, ListItemIcon, Tooltip, Typography, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "auto",
        width: "97%",
        height: 48,
        marginBottom: 8,
        marginLeft: 8,
        padding: "6px 16px 6px 2px",
        "&:hover": {
            borderRadius: "6px 0 0 6px",
            color: `${theme.palette.primary.main}`,
            backgroundColor: "hsla(18, 97.40%, 69.80%, 0.16)",
            scale: 1.03,
            cursor: "pointer",
        },
        userSelect: "none",
    },
    iconContainer: {
        justifyContent: "center",
        opacity: 0.9,
        strokeOpacity: 0.9,
    },
    subheading: {
        color: "#2C2C2C",
        fontSize: 14,
    },
    onActiveOfSideboard: {
        borderRadius: "6px 0 0 6px",
        color: `${theme.palette.primary.main}`,
        backgroundColor: "hsla(18, 97.40%, 69.80%, 0.16)",
    },
    icon: {
        color: "#FD9567",
        fill: "#FD9567",
        width: 18,
        height: 18,
    },
    iconContainerForExpand: {
        position: "absolute",
        right: "-1%",
        justifyContent: "center",
        rotate: "-90deg",
    },
}));

/**
 * This component is used to display the main navigation items
 *
 * @component
 * @param {Object} data - The data of the main navigation item
 * @param {boolean} open - The state of the sideboard
 * @param {function} setHoveredItem - The function to set the hovered item
 * @param {function} setOpenItem - The function to set the open item
 * @param {string} hoveredItem - The hovered item
 * @param {string} openItem - The open item
 *
 */

function MainNavItem({ data, open, setHoveredItem, setOpenItem, hoveredItem, openItem }) {
    const classes = useStyles();
    const handleMouseEnter = () => {
        setHoveredItem(data?.typography);
        
        // if (data?.link !== undefined) {
        //     setOpenItem(null);
        // }
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const handleClick = () => {
        setOpenItem(openItem === data?.typography ? null : data?.typography);
    };

    React.useEffect(() => {
        if (hoveredItem === null && openItem === null) {
            setOpenItem(null);
        }
    }, [hoveredItem, openItem]);

    return (
        <>
            <ListItem
                className={clsx(classes.listItem, {
                    [classes.onActiveOfSideboard]: openItem === data?.typography,
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <Tooltip title={data?.tooltip} disableInteractive TransitionComponent={Zoom}>
                    <ListItemIcon classes={{ root: classes.iconContainer }}>
                        {(hoveredItem === data?.typography || openItem === data?.typography) && data?.typography ? (
                            <data.selectedIcon />
                        ) : (
                            <data.icon />
                        )}
                    </ListItemIcon>
                </Tooltip>
                {open && (
                    <Typography
                        className={classes.subheading}
                        style={{ fontFamily: "Montserrat", fontWeight: 500, color: "inherit" }}
                    >
                        {data?.typography}
                    </Typography>
                )}
                {data?.link === undefined && open && (hoveredItem === data?.typography || openItem === data?.typography) && (
                    <ListItemIcon className={classes.iconContainerForExpand}>
                        <ExpandMoreIcon className={classes.icon} />
                    </ListItemIcon>
                )}
            </ListItem>
        </>
    );
}

export default MainNavItem;
