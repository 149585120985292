import React from 'react'
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    container: {
        '& p': {
            margin: 0,
            fontFamily: 'Montserrat',
            fontSize: 11,
            fontWeight: 400
        },
        backgroundColor: '#FAFBFF',
        borderRadius: '10px',
        padding: 15,
        marginTop: 15,
    }
}));
const AnnouncementNewUpdates = (props) => {
    const classes = useStyles()
    console.log(props.data)
    if (!props.data?.items?.length > 0) {
        return null
    }
    return (
        <Grid container item xs={12} className={classes.container}>
            <Grid container item justifyContent='space-between' alignItems='center' spacing={2} style={{ marginBottom: 5 }}>

                <Grid item >
                    <Typography style={{ color: '#627082', fontFamily: 'Montserrat', fontSize: 10, fontWeight: 500, padding: 0 }}>{moment(props.data.items[0]['published-on'] ? props.data.items[0]['published-on'] : props.data.items[0]['created-on']).format('MMM DD,YYYY')}</Typography>
                </Grid>
                <Grid item >
                    <Typography style={{ backgroundColor: '#C5F5D4', color: '#5A9576', fontFamily: 'Montserrat', fontSize: 10, fontWeight: 600, padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 15 }}>NEW UPDATES</Typography>
                </Grid>

            </Grid>
            <Grid container item xs={12}>
                {props.data.items.map((item) => {
                    console.log(item)
                    return <Grid item xs={12}>
                        <Typography variant='h5' style={{ fontSize: 14, fontWeight: 600, fontFamily: 'Montserrat', color: '#13253F' }}>{item.name}</Typography>
                        <div dangerouslySetInnerHTML={{ __html: item["update-text"] }} />

                    </Grid>
                })}
            </Grid>
        </Grid >
    )
}

export default AnnouncementNewUpdates