import React from "react";
import { Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";

const useStyles = makeStyles({
    box: {
        position: "relative",
        maxWidth: 600,
        width: "90%",
        height: 400,
        background: "#fff",
        boxShadow: "0 0 15px rgba(0,0,0,.1)",
    },
    ribbon: {
        width: 150,
        height: 150,
        top: -5,
        right: -5,
        overflow: "hidden",
        position: "absolute",
        "&::before": {
            position: "absolute",
            zIndex: -1,
            content: "",
            display: "block",
            border: "5px solid #2980b9",
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            top: 0,
            left: 0,
        },
        "&::after": {
            position: "absolute",
            zIndex: -1,
            content: "",
            display: "block",
            border: "5px solid #2980b9",
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            bottom: 0,
            right: 0,
        },
    },
    span: {
        position: "absolute",
        display: "block",
        width: 225,
        padding: "3px 0",
        backgroundColor: "#ff3b3b !important",
        boxShadow: "0 5px 10px rgba(0,0,0,.1)",
        color: "#fff",
        font: "600 12px/1 Lato, sans-serif",
        textShadow: "0 1px 1px rgba(0,0,0,.2)",
        textAlign: "center",
        left: -5,
        top: 30,
        transform: "rotate(45deg)",
    },
    success: {
        position: "absolute",
        display: "block",
        width: 225,
        padding: "3px 0",
        backgroundColor: "rgb(46, 125, 50) !important",
        boxShadow: "0 5px 10px rgba(0,0,0,.1)",
        color: "#fff",
        font: "600 12px/1 Lato, sans-serif",
        textShadow: "0 1px 1px rgba(0,0,0,.2)",
        textAlign: "center",
        left: -5,
        top: 30,
        transform: "rotate(45deg)",
    },
    secondary: {
        position: "absolute",
        display: "block",
        width: 225,
        padding: "3px 0",
        backgroundColor: `#2E394B !important`,
        boxShadow: "0 5px 10px rgba(0,0,0,.1)",
        color: "#fff",
        font: "600 12px/1 Lato, sans-serif",
        textShadow: "0 1px 1px rgba(0,0,0,.2)",
        textAlign: "center",
        left: -5,
        top: 30,
        transform: "rotate(45deg)",
    },
});

export default function RibbonMaterialCard({ ribbonText = "Deprecated", ribbonType = "ERROR" }) {
    const classes = useStyles();

    return (
        <div className={classes.ribbon}>
            {ribbonType === "SECONDARY" ? (
                <Tooltip title="This connector is in BETA. Some reports may have limited functionality.">
                    <span
                        className={clsx(classes.span, {
                            [classes.success]: ribbonType === "SUCCESS",
                            [classes.secondary]: ribbonType === "SECONDARY",
                        })}
                        dangerouslySetInnerHTML={{
                            __html: ribbonText,
                        }}
                    ></span>
                </Tooltip>
            ) : (
                <span
                    className={clsx(classes.span, {
                        [classes.success]: ribbonType === "SUCCESS",
                        [classes.secondary]: ribbonType === "SECONDARY",
                    })}
                    dangerouslySetInnerHTML={{
                        __html: ribbonText,
                    }}
                ></span>
            )}
        </div>
    );
}
