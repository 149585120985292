import axios from "config/reverseETLaxios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import Config from "constants/config";
import { AccordionSummary } from "@mui/material";
class DataModelEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `data-models`;
        this.transformer = null;
    }
    getAll() {
        return axios.get(`${this.resourceEndpoint}`);
    }

    getLogs(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.dataModelId}/change_history`);
    }
    editDataModel(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.dataModelId}`, data.apiData.data);
    }
    getOne(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.dataModelId}`);
    }
    addDataModel(data) {
        return axios.post(`${this.resourceEndpoint}`, data.apiData.data);
    }
    getModelColumns(data) {
        return axios.get(
            `${Config.dataApiUrl}ui/warehouses/${data.apiData.dataWarehouseId}/tables/${data.apiData.viewName}/schema`,
            {
                headers: {
                    apiKey: data.apiData.apiKey,
                },
            }
        );
    }
    deleteDataModel(data) {
        return axios.delete(`${this.resourceEndpoint}/${data.apiData.dataModelId}`);
    }
    validateSql(data) {
        return axios.post("/validate-sql", data.apiData.data);
    }
    getAllSegments(data) {
        return axios.get("/segments");
    }
}

export default DataModelEndpoints;
