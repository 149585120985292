import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class OrchestrationEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `orchestrations`;
        this.transformer = null;
    }
    add(data) {
        return axios.post(`${this.resourceEndpoint}`, data.apiData.data);
    }
    getAll(data) {
        return axios.get(`${this.resourceEndpoint}`);
    }
    runOrchestration(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.orchestrationId}/runs`);
    }
    getOne(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.orchestrationId}`);
    }
    update(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.orchestrationId}`, data.apiData.data);
    }
    delete(data) {
        return axios.delete(`${this.resourceEndpoint}/${data.apiData.orchestrationId}`);
    }
    getOrchestrationTaskRuns(data) {
        return axios.get(
            `${this.resourceEndpoint}/${data.apiData.orchestrationId}/runs/${data.apiData.orchestrationRunId}/tasks/runs`
        );
    }
    changeStatus(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.orchestrationId}/status`, data.apiData.data);
    }
    getOrchestrationRunStatus(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.orchestrationId}/runs/${data.apiData.orchestrationRunId}`);
    }
    getOrchestrationRuns(data) {
        return axios.get(`/orchestrations/${data.apiData.orchestrationId}/runs`);
    }
    getOrchestrationRunsSeven(data) {
        return axios.get(`/orchestrations/${data.apiData.orchestrationId}/runs/seven`);
    }
    getOrcehstrationVersion(data) {
        return axios.get(
            `${this.resourceEndpoint}/${data.apiData.orchestrationId}/versions/${data.apiData.orcehstrationVersion}`
        );
    }
    getOrcehstrationDynamicParamData(data) {
        return axios.post(`/orchestrations/nodes/${data.apiData.node_slug}/${data.apiData.property_name}`, data.apiData.data);
    }
    getOrchestrationNodeCredentialData(data) {
        return axios.post(`/orchestrations/nodes/${data.apiData.node_slug}/getCreds`, data.apiData.data);
    }
}

export default OrchestrationEndpoints;
