import {
    LATEST_ANNOUNCEMENTS,
    LATEST_ANNOUNCEMENTS_IS_LOADING,
    ALL_ANNOUNCEMENTS,
    ALL_ANNOUNCEMENTS_IS_LOADING,
    CLOSE_ANNOUNCEMENT_BANNER,
    SET_ANNOUNCEMENT_CLOSED_TIME,
} from "../actionTypes/actionTypes";

const initialState = {
    latestAnnouncements: [],
    latestAnnouncementsIsLoading: false,
    allAnnouncements: [],
    allAnnouncementsIsLoading: false,
    closeAnnouncementBanner: false,
    anncouncementBannerLastClosedAt: null,
};

export const announcementReducer = (state = initialState, action) => {
    switch (action.type) {
        case LATEST_ANNOUNCEMENTS:
            return {
                ...state,
                latestAnnouncements: action.payload,
            };
        case LATEST_ANNOUNCEMENTS_IS_LOADING:
            return {
                ...state,
                latestAnnouncementsIsLoading: action.payload,
            };
        case ALL_ANNOUNCEMENTS:
            return {
                ...state,
                allAnnouncements: action.payload,
            };
        case ALL_ANNOUNCEMENTS_IS_LOADING:
            return {
                ...state,
                allAnnouncementsIsLoading: action.payload,
            };

        case CLOSE_ANNOUNCEMENT_BANNER:
            return {
                ...state,
                closeAnnouncementBanner: action.payload,
                anncouncementBannerLastClosedAt: new Date(),
            };
        case SET_ANNOUNCEMENT_CLOSED_TIME:
            return {
                ...state,
                anncouncementBannerLastClosedAt: new Date(),
            };

        default:
            return state;
    }
};
