import React, { useRef } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import axios from "axios";

import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, CircularProgress } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Config from "constants/config";
import Cookies from "js-cookie";
import ForwardSearchResults from "./ForwardSearchResults";
import ReverseSearchResults from "./ReverseSearchResults";
import FeatureFlagHOC from "services/featureFlagHOC";
import { useSelector } from "react-redux";
const DATASOURCE = "DATASOURCE";

const useStyles = makeStyles((theme) => ({
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #dbdbdb",
        backgroundColor: "#f9f9f9",
        "&:hover": {
            backgroundColor: "#ececec",
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "15vw",
            },
        },
    },
    paper: {
        border: "1px solid #dbdbdb",
        borderRadius: 5,
        // padding: theme.spacing(1),
        // overflowY: "hidden",
        backgroundColor: "#f9f9f9",
        height: "90vh",
        width: "48vw",
    },
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

/**
 *
 * @prop - none
 * @component {This component corresponds to the main SEARCH BAR feature which is common in Top Layout in the UI.
 *             User enters a query in Search bar and search results are displayed using ForwardSearchResults and ReverseSearchResults Components.}
 */

export default function Search() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isFocused, setIsFocused] = React.useState(false);
    const [query, setQuery] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [dataIsLoading, setDataIsLoading] = React.useState(false);
    const cancelSource = useRef(null);
    const isSideNavOpen = useSelector((state) => state.uiStates.isSideNavOpen);

    /**
     *
     *  @param {*} event
     * @function {It sets the "isFocused" and "anchorEl" states when it is invoked, which results in expanding the Search Bar.}
     */

    const handleFocus = (event) => {
        setIsFocused(true);
        setAnchorEl(event.target);
    };

    /**
     *
     * @param - none
     * @function {It sets the "anchorEl" state as null when it is invoked, which finally results in shrinking the Search Bar and closing the Search Results in UI.}
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     *
     * @param {*} e
     * @function {It receives an event "e" as param and sets the "query" State if user has provided some input.}
     */

    const handleChange = (e) => {
        if (e.target.value !== "") {
            setQuery(e.target.value);
        } else {
            // cancelSource.current.cancel()
            setData(null);
            setQuery(null);
        }
    };

    /**
     *
     * @param - none
     * @function {Handles the event when user clicks outside the search bar area by calling "handleClose" function.}
     */

    const handleClickAway = () => {
        if (!isFocused) handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "transitions-popper" : undefined;

    const [blockOpen, setBlockOpen] = React.useState(DATASOURCE);

    /**
     *
     * @param - none
     * @function {This useEffect uses the query entered by the User and fetches response from API & sets the "data" state.
     *            This useEffect runs everytime when "query" state is changed.}
     */

    React.useEffect(async () => {
        try {
            if (query !== null) {
                setDataIsLoading(true);
                if (cancelSource?.current != null) {
                    cancelSource?.current.cancel();
                }
                if (query.length > 1) {
                    cancelSource.current = axios.CancelToken.source();
                    const res = await axios.get(`${Config.backendUrl}search?q=${query}`, {
                        cancelToken: cancelSource?.current.token,
                        headers: {
                            Authorization: `Bearer ${Cookies.get("accessToken")}`,
                        },
                    });
                    const data = res.data.data;
                    setData(data);
                } else {
                    setData(null);
                }
                setDataIsLoading(false);
            } else {
                setDataIsLoading(false);
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                setDataIsLoading(true);
            } else {
                setDataIsLoading(false);
            }
        }
    }, [query]);

    const focusSearchBar = (e) => {
        if (e.ctrlKey && (e.key == " " || e.code == "Space" || e.keyCode == 32)) {
            if (document.getElementById("search-input") === document.activeElement) {
                document.getElementById("search-input").blur();
                handleClose();
                return;
            }
            console.log("activating searchnar");
            document.getElementById("search-input").focus();
        }
    };
    React.useEffect(() => {
        document.addEventListener("keyup", focusSearchBar, false);
        return () => document.removeEventListener("keyup", focusSearchBar);
    }, []);

    return (
        <>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    id="search-input"
                    onChange={handleChange}
                    onFocus={(e) => handleFocus(e)}
                    name="searchbar"
                    onBlur={() => setIsFocused(false)}
                    inputProps={{ "aria-label": "search" }}
                />
            </div>
            <Popper
                style={{
                    zIndex: 1202,
                    marginTop: 5,
                    left: `${isSideNavOpen ? "52%" : "49%"}`,
                    transform: `${isSideNavOpen ? "translate(-64%, 0)" : "translate(-67%, 0)"}`,
                    position: "fixed",
                    top: 50,
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
                modifiers={{
                    preventOverflow: {
                        boundariesElement: "viewport",
                    },
                }}
                transition
            >
                {({ TransitionProps }) => (
                    <div {...TransitionProps}>
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Grid className={classes.paper}>
                                {dataIsLoading ? (
                                    <Grid container justifyContent="center" className="fullHeight" alignItems="center">
                                        <CircularProgress />
                                    </Grid>
                                ) : data ? (
                                    <Grid style={{ height: "100%" }} container onClick={() => setAnchorEl(anchorEl)}>
                                        <ForwardSearchResults data={data} />
                                        <FeatureFlagHOC requiredFeature={["REVERSE"]}>
                                            <ReverseSearchResults data={data} />
                                        </FeatureFlagHOC>
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent="center" className="fullHeight" alignItems="center">
                                        <Typography variant="subtitle1">Enter something to search.</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </ClickAwayListener>
                    </div>
                )}
            </Popper>
        </>
    );
}
