import axios from "axios";
import Cookies from "js-cookie";
import { refreshAccessToken } from "../services/Api/api";
import config from "../constants/config";
import { getAuth } from "firebase/auth";

const axiosInstance = axios.create({
    baseURL: config.backendUrl,
    // baseURL: `http://localhost:4000/`
});

axiosInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axiosInstance.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, PUT, POST, DELETE, OPTIONS";
// axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get("accessToken")}`;

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const auth = getAuth();
                console.log(auth);
                let user = auth.currentUser;
                console.log(user);

                const idToken = await user.getIdToken(true);

                // const res = await refreshAccessToken(Cookies.get("refreshToken"));
                console.log(idToken);
                Cookies.set("accessToken", idToken);
                Cookies.set("idToken", idToken);
                // Cookies.set("expiresIn", res.data.expiresIn * 1000);
                axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
                originalRequest.headers.Authorization = `Bearer ${idToken}`;
                console.log("after get id token");
                return axiosInstance(originalRequest);
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 405 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                window.location = config.frontendUrl;
                return axiosInstance(originalRequest);
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);
export default axiosInstance;
