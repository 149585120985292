import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const useStyles = makeStyles({
    outer: {
        width: "350px",
        height: "10px",
        backgroundColor: "#FED6C5",
    },
    inner: {
        width: "60%",
        height: "10px",
        backgroundColor: "#FD9567",
        borderRadius: "10px 0px 0px 10px ",
    },
    overflow: {
        width: "50px",
        height: "10px",
        backgroundColor: "#D32F2F",
        borderRadius: "0 10PX 10PX 0",
    },
});
const LimitBarWithOutPointer = (props) => {
    const classes = useStyles();
    return (
        <>
            <div className="App" style={{ width: "100%", display: "flex" }}>
                <div
                    className={classes.outer}
                    style={{
                        borderRadius: props.value > 100 ? "10px 0px 0px 10px" : "10px",
                        backgroundColor: `${props.backgroundColor}`,
                        height: `${props.height ? props.height : "10px"}`,
                    }}
                >
                    <div
                        className={classes.inner}
                        style={{
                            borderRadius:
                                props.value === 100 ? "10px" : props.value > 100 ? "10px 0px 0px 10px" : "10px 0px 0px 10px",
                            width: `${props.value >= 100 ? 100 : props.value}%`,
                            backgroundColor: `${props.color}`,
                            height: `${props.height ? props.height : "10px"}`,
                        }}
                    ></div>
                </div>
                <div
                    className={classes.overflow}
                    style={{ display: props.value > 100 ? "flex" : "none", height: `${props.height ? props.height : "10px"}` }}
                ></div>
            </div>
        </>
    );
};

export default LimitBarWithOutPointer;
