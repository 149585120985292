import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { TOGGLE_BANNER, TOGGLE_UPGRADE_TO_PREMIUM_POPUP } from "redux/actionTypes/actionTypes";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import GlobalNotifications from "./GlobalNotifications";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import { set } from "js-cookie";
import useApi from "hooks/useApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const drawerWidth = 140;
const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        // margin: "0px 40px 0px 10px",
        padding: "2px  0px",
        width: "100%",
        top: "0px",
        // right: 10,
        borderRadius: "0px 0px 5px 5px",
        border: "1px solid #dfdfdf",
        zIndex: 999999,
        backgroundColor: "#e8edff",
        boxShadow: "0px 6px 25px -7px rgba(71,71,71,0.73)",

        // left: 0,
    },
    drawerOpen: {
        width: "calc(100% - 20px);", // remove 240px from 100% to extend the banner
        backgroundColor: "#e8edff",
        right: 10,
        zIndex: 999999,
    },
    drawerClose: {
        width: "calc(100% -20px);", // remove 64px from 100% to extend the banner
        backgroundColor: "white",
        // right: 0,
        zIndex: 999999,
    },
    size14: {
        fontSize: 14,
        fontWeight: 600,
    },
    size16: {
        fontSize: 16,
        fontWeight: 600,
        color: "rgba(40, 40, 118, 1)",
    },
    line: {
        width: "100%",
        height: "5px",
        position: "absolute",
        // borderRadius: "0px 0px 5px 5px",
        top: "0px",
        height: "5px",
        left: 0,
        // right: "-0.5%",
        backgroundColor: "rgba(253, 149, 103)",
        cursor: "pointer",
        padding: 0,
        margin: 0,
    },
    zIndex: {
        zIndex: 999999,
    },
}));

const bannerJson = {
    module: "user",
    method: "getAllBanners",
    returnResult: true,
};

/**
 * Component to render banners from the database
 * @component
 * @returns
 */

export default function Banner({ banners, setBanners }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.uiStates.isSideNavOpen);
    const navigate = useNavigate();
    const trialAvailed = useSelector((state) => state.users.user.trial_used);
    const upgradedToPremiumTrial = useSelector((state) => state.onboarding.upgradedToPremiumTrial);

    // States
    const [currentActive, setCurrentActive] = React.useState(0);
    const user = useSelector((state) => state.users.user);
    const uiStates = useSelector((state) => state.uiStates);
    // console.log(uiStates);

    // Api Calls
    const [, bannerIsLoading, fetchBanners] = useApi();

    const handleNext = () => {
        if (currentActive < banners.length - 1) setCurrentActive((currentActive) => currentActive + 1);
    };

    const handleBack = () => {
        if (currentActive > 0) setCurrentActive((currentActive) => currentActive - 1);
    };

    const filterBanners = (res) => {
        const result = [];
        if (!trialAvailed && !upgradedToPremiumTrial && user.zoho_plan_code === "datachannel_free_monthly") {
            result.push({
                text_part_1:
                    "You are eligible for a 14 days full feature free trial. During the trial you will  have access to all",
                button_1_text: "PRO",
                text_part_2: "Plan features. ",
                button_2_text: " Start Your Trial Now",
                style: "trial_banner",
            });
        }
        if (user?.subscription_status === "trial") {
            result.push({
                banner_text: "Need help getting started? Book an on-boarding call with one of our product experts today.",
                buttons: "Book On-Boarding Call, Request a Sales Demo",
                end_time: null,
                id: 2,
                links: "https://calendly.com/datachannel-support/30min, https://calendly.com/mitali-narula/demo-with-datachannel",
            });
        }
        res.forEach((el) => {
            if (el.end_time === null) {
                result.push(el);
            } else {
                if (new Date(el.end_time) > new Date()) {
                    result.push(el);
                }
            }
        });
        return result;
    };

    React.useEffect(() => {
        (async () => {
            const res = await fetchBanners(bannerJson);
            setBanners(filterBanners(res));
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            const res = await fetchBanners(bannerJson);
            setBanners(filterBanners(res));
        })();
    }, [upgradedToPremiumTrial]);

    /**
     * if there are global notifications to show
     */
    if (uiStates.globalNotifications.length > 0) {
        return <GlobalNotifications notifications={uiStates.globalNotifications} />;
    }

    /**
     * return in case banner is null or empty
     */
    if (banners === undefined || banners === null || banners?.length === 0 || bannerIsLoading) {
        return null;
    }

    const handleBannerClose = () => {
        dispatch({ type: TOGGLE_BANNER });
        console.log("close banner");
    };

    const handleBannerOpen = () => {
        dispatch({ type: TOGGLE_BANNER });
        console.log("open banner");
    };

    return (
        <>
            {/*added a line on top of the banner to open it when clicked  */}
            {!uiStates.showBanner && (
                <motion.div
                    className={clsx(classes.root, classes.line, classes.zIndex, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    onClick={() => {
                        handleBannerOpen();
                    }}
                    whileHover={{
                        scaleY: 2,
                        zIndex: 999999,
                    }}
                    transition={{
                        duration: 0.1,
                        ease: "easeIn",
                    }}
                    style={{ border: "none" }}
                />
            )}
            <motion.div
                className={classes.root}
                initial={{ y: "-50vh" }}
                animate={{ y: uiStates.showBanner ? "0" : "-40vh" }}
                transition={{ duration: 1 }}
            >
                <Box elevation={2}>
                    {banners[currentActive]?.style === "trial_banner" ? (
                        <Grid
                            container
                            className={clsx(classes.root, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            style={{
                                backgroundColor: "rgba(237, 239, 255, 1)",
                            }}
                        >
                            <Grid
                                container
                                item
                                style={{ marginLeft: 15, marginRight: 10 }}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item container>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        {currentActive !== 0 && (
                                            <Grid item>
                                                <ArrowBackIosIcon
                                                    style={{ fontSize: 20 }}
                                                    className="hoverable"
                                                    onClick={handleBack}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={11}>
                                            <Grid container alignItems="center" justifyContent="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" className={classes.size16} align="center">
                                                        <span>{banners[currentActive]?.text_part_1}</span>
                                                        <span>
                                                            <Button
                                                                style={{
                                                                    marginLeft: 5,
                                                                    marginRight: 5,
                                                                    marginTop: 0,
                                                                    marginBottom: 0,
                                                                    paddingTop: 2,
                                                                    paddingBottom: 2,
                                                                }}
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/settings?tab=subscription_management&subTab=0&open_plan=true"
                                                                    )
                                                                }
                                                            >
                                                                PRO
                                                            </Button>
                                                        </span>
                                                        <span>{banners[currentActive]?.text_part_2}</span>
                                                        <span
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: 600,
                                                                color: "rgba(253, 149, 103, 1)",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                dispatch({
                                                                    type: TOGGLE_UPGRADE_TO_PREMIUM_POPUP,
                                                                    payload: true,
                                                                })
                                                            }
                                                        >
                                                            {" "}
                                                            {banners[currentActive]?.button_2_text}
                                                        </span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container alignItems="center">
                                                {currentActive !== banners.length - 1 ? (
                                                    <NavigateNextIcon
                                                        style={{ fontSize: 25 }}
                                                        className="hoverable"
                                                        onClick={handleNext}
                                                    />
                                                ) : (
                                                    <CloseIcon className="hoverable" onClick={handleBannerClose} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            className={clsx(classes.root, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                        >
                            <Grid
                                container
                                item
                                style={{ marginLeft: 15, marginRight: 10 }}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Grid container alignItems="center">
                                        {currentActive !== 0 && (
                                            <ArrowBackIosIcon
                                                style={{ fontSize: 20 }}
                                                className="hoverable"
                                                onClick={handleBack}
                                            />
                                        )}

                                        <Typography variant="h5" className={classes.size14}>
                                            {banners[currentActive]?.banner_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        {banners[currentActive]?.buttons.split(",").map((button, index) => (
                                            <Button
                                                key={index}
                                                style={{ marginLeft: 5, marginTop: 0, marginBottom: 0 }}
                                                variant={index % 2 === 0 ? "contained" : "outlined"}
                                                color={index % 2 === 0 ? "primary" : "secondary"}
                                                size="small"
                                                onClick={() => window.open(banners[currentActive]?.links.split(",")[index])}
                                            >
                                                {button}
                                            </Button>
                                        ))}
                                        {currentActive !== banners.length - 1 ? (
                                            <NavigateNextIcon
                                                style={{ fontSize: 25 }}
                                                className="hoverable"
                                                onClick={handleNext}
                                            />
                                        ) : (
                                            <IconButton onClick={handleBannerClose}>
                                                <Tooltip title="Close Banner">
                                                    <CloseIcon className="hoverable" />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </motion.div>
        </>
    );
}
