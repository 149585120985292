import React from "react";
import { Grid, IconButton, Tooltip, Typography, Chip, Avatar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import LinkIcon from "@mui/icons-material/Link";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
const useStyles = makeStyles((theme) => ({
    container: {
        "& p": {
            margin: 0,
            fontFamily: "Montserrat",
            fontSize: 11,
            fontWeight: 400,
        },
        "& li": {
            margin: 0,
            fontFamily: "Montserrat",
            fontSize: 11,
            fontWeight: 400,
        },
        backgroundColor: "#FAFBFF",
        borderRadius: "10px",
        padding: 15,
        marginTop: 15,
    },

    large: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));
const AnnouncementNewConnector = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    console.log(props.data);
    if (!props.data?.items?.length > 0) {
        return null;
    }
    return (
        <Grid container item xs={12} className={classes.container}>
            <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 5, marginTop: 5 }}
            >
                <Grid item>
                    <Typography style={{ color: "#627082", fontFamily: "Montserrat", fontSize: 10, fontWeight: 500, padding: 0 }}>
                        {moment(
                            props.data.items[0]["releasedate"]
                                ? props.data.items[0]["releasedate"]
                                : props.data.items[0]["created-on"]
                        ).format("MMM DD,YYYY")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        style={{
                            backgroundColor: "#BEE3F8",
                            color: "#3C7AB9",
                            fontFamily: "Montserrat",
                            fontSize: 10,
                            fontWeight: 600,
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 15,
                        }}
                    >
                        NEW CONNECTOR
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                {props.data.items.map((item) => {
                    console.log(item["available-data-text"]);
                    return (
                        <Grid item xs={12}>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ marginBottom: 10, marginTop: 5 }}
                            >
                                <Grid item container justifyContent="flex-start" xs={9} alignItems="center">
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            marginRight: 10,
                                            fontFamily: "Montserrat",
                                            color: "#13253F",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            item?.integration_tag[0]?.name !== "Coming Soon" &&
                                            navigate(`/datasource/${item.slug}`)
                                        }
                                    >
                                        {item.name}
                                    </Typography>
                                    <Tooltip title="Explore more about connector">
                                        <IconButton
                                            onClick={() => window.open(`https://www.datachannel.co/integration/${item.slug}`)}
                                            size="large">
                                            {" "}
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Read DC documentaion">
                                        <IconButton onClick={() => window.open(item["dc-documentation-url"])} size="large">
                                            {" "}
                                            <HelpOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {item["integration-image"] && (
                                    <img
                                        src={item["integration-image"]["url"]}
                                        alt={item.name}
                                        style={{ width: 50, marginLeft: 10 }}
                                    />
                                )}
                            </Grid>
                            {item?.integration_tag?.length > 0 && (
                                <Chip
                                    label={item?.integration_tag[0]?.name}
                                    style={{
                                        backgroundColor: item?.integration_tag[0]["background-color"],
                                        marginLeft: 5,
                                        marginBottom: 10,
                                    }}
                                />
                            )}

                            <div dangerouslySetInnerHTML={{ __html: item["about-integration-text"] }} />
                            <Grid>
                                {item["available-data-text"] && (
                                    <>
                                        <Typography
                                            variant="h3"
                                            style={{
                                                fontSize: 11,
                                                fontWeight: 600,
                                                fontFamily: "Montserrat",
                                                color: "#13253F",
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        >
                                            Available Pipelines
                                        </Typography>
                                        <div dangerouslySetInnerHTML={{ __html: item["available-data-text"] }} />
                                    </>
                                )}
                            </Grid>
                            <Grid style={{ marginBottom: 20 }}>
                                {item["warehouse_item"] && (
                                    <>
                                        <Typography
                                            variant="h3"
                                            style={{
                                                fontSize: 11,
                                                fontWeight: 600,
                                                fontFamily: "Montserrat",
                                                color: "#13253F",
                                                marginBottom: 10,
                                                marginTop: 10,
                                            }}
                                        >
                                            Possible Destinations
                                        </Typography>

                                        <Grid container spacing={2}>
                                            {item["warehouse_item"]?.map((ele) => (
                                                <Grid item>
                                                    <Tooltip title={ele.name}>
                                                        <Avatar
                                                            variant="square"
                                                            alt={ele.name}
                                                            src={ele.icon.url}
                                                            className={classes.large}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default AnnouncementNewConnector;
