import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AuthEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `auth`;
        this.transformer = null;
    }
    register(data) {
        return axios.post(`${this.resourceEndpoint}/register`, data.apiData.data);
    }
    confirmLink(data) {
        console.log(data);
        return axios.post(`${this.resourceEndpoint}/confirm_link/${data.apiData.data}`);
    }
    userUpdate(data) {
        return axios.post(`${this.resourceEndpoint}/user_input`, data.apiData.data);
    }
    socialRegister(data) {
        return axios.post(`${this.resourceEndpoint}/social-register`, data.apiData.data);
    }
    checkUserFromFirebase(data) {
        return axios.post(`${this.resourceEndpoint}/check_user/${data.apiData.firebase_id}`, data.apiData.data);
    }
    verifyEmailLink(data) {
        console.log(data)
        return axios.post(`${this.resourceEndpoint}/verify_link/${data.apiData.data}`);

    }
    verifyOTP(data){
        return axios.post(`${this.resourceEndpoint}/verify_otp`,data.apiData.data);

    }
}

export default AuthEndpoints;
