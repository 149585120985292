const formatParameterOptions = (parameterOptions) => {
    let finalData = [];
    parameterOptions.forEach((option) => {
        let objToInsert = { ...option };
        objToInsert.param_value = objToInsert.value;
        delete objToInsert["value"];
        finalData.push(objToInsert);
    });
    return finalData;
};

const formatParameters = (parameters) => {
    const finalData = [];
    parameters.forEach((param) => {
        let objToInsert = { ...param };
        objToInsert.display_name = objToInsert.display_label;
        objToInsert.required = objToInsert.is_required;
        objToInsert.param_name = objToInsert.label;
        objToInsert.param_operator_hardcoded = objToInsert?.param_operator[0];
        objToInsert.report_parameter_options = formatParameterOptions(objToInsert.sync_parameter_options);
        objToInsert.param_parent_id = objToInsert.parent_id;
        objToInsert.parameter_type = objToInsert.type;
        objToInsert.visibility_options = objToInsert.visibilty_selection;
        delete objToInsert["visibilty_selection"];
        delete objToInsert["display_label"];
        delete objToInsert["parent_id"];
        delete objToInsert["is_required"];
        delete objToInsert["label"];
        delete objToInsert["sync_parameter_options"];
        delete objToInsert["type"];
        finalData.push(objToInsert);
    });
    return finalData;
};

const formatSyncLevelParameters = (sync_level_parameters) => {
    console.log(sync_level_parameters);
    const finalData = [];
    sync_level_parameters.forEach((param) => {
        let objToInsert = { ...param };
        objToInsert.display_name = objToInsert.display_label;
        objToInsert.required = objToInsert.is_required;
        objToInsert.param_name = objToInsert.label;
        objToInsert.param_operator_hardcoded = objToInsert?.param_operator[0];
        objToInsert.report_parameter_options = formatParameterOptions(objToInsert.sync_level_parameter_options);
        objToInsert.param_parent_id = objToInsert.parent_id;
        objToInsert.parameter_type = objToInsert.type;
        objToInsert.visibility_options = objToInsert.visibilty_selection;
        objToInsert.validation_rule = objToInsert.validation_rules;
        objToInsert.validation_rules = {
            max_no_of_selects: objToInsert.max_no_of_selects,
            required: objToInsert.is_required,
            validation_rule: objToInsert.validation_rules,
        };
        delete objToInsert["visibilty_selection"];
        delete objToInsert["display_label"];
        delete objToInsert["parent_id"];
        delete objToInsert["is_required"];
        delete objToInsert["label"];
        delete objToInsert["sync_parameter_options"];
        delete objToInsert["type"];
        finalData.push(objToInsert);
    });
    console.log(finalData);
    return finalData;
};

const formatLevels = (levels) => {
    let finalData = [];
    levels.forEach((level) => {
        let objToInsert = { ...level };
        objToInsert.display_name = objToInsert.display_label;
        objToInsert.name = objToInsert.label;
        objToInsert.max_no_of_select = objToInsert.max_selects;
        objToInsert.is_editable = objToInsert.is_editable ? 1 : 0;
        objToInsert.required = objToInsert.is_required ? 1 : 0;
        objToInsert.parent_level_id = objToInsert.parent_id;
        objToInsert.tooltip_description = objToInsert.tooltip;
        objToInsert.sync_level_parameters = formatSyncLevelParameters(objToInsert.sync_level_parameters);
        delete objToInsert["display_label"];
        delete objToInsert["label"];
        delete objToInsert["max_selects"];
        delete objToInsert["is_required"];
        delete objToInsert["parent_id"];
        delete objToInsert["tooltip"];
        finalData.push(objToInsert);
    });
    return finalData;
};

const formatConfiguredLevels = (configuredLevels) => {
    const finalData = [];
    configuredLevels.forEach((configuredLevel) => {
        let objectToInsert = { ...configuredLevel };
        objectToInsert.data = objectToInsert.additional_data;
        objectToInsert.report_level = objectToInsert.sync_level_id;
        delete objectToInsert.additional_data;
        delete objectToInsert.sync_level_id;
        finalData.push(objectToInsert);
    });
    return finalData;
};

const formatConfiguredParameters = (configuredParameters) => {
    let finalData = [];
    configuredParameters.forEach((configuredParameter) => {
        let objectToInsert = { ...configuredParameter };
        objectToInsert.configured_report = objectToInsert.configured_sync;
        objectToInsert.report_parameter = objectToInsert.sync_parameter_id;
        objectToInsert.report_parameter_parent_id = objectToInsert.sync_parameter_parent_id;
        delete objectToInsert.sync_parameter_id;
        delete objectToInsert.sync_parameter;
        delete objectToInsert.configured_sync;
        delete objectToInsert.sync_parameter_parent_id;
        finalData.push(objectToInsert);
    });
    return finalData;
};

class ReverseConnectorTransformation {
    getOneSyncPostTransformer(data) {
        const temp = {
            ...data,
        };
        temp.parameters = formatParameters(temp.sync_parameters);
        temp.levels = formatLevels(temp.sync_levels);
        temp.display_name = temp.display_label;
        delete temp["sync_parameters"];
        delete temp["sync_levels"];
        delete temp["display_label"];
        console.log(temp, "cool data");
        return temp;
    }
    getOneConfiguredSyncPostTransformer(data) {
        const temp = {
            ...data,
        };
        temp.parameters = formatConfiguredParameters(temp.configured_sync_parameters);
        temp.levels = formatConfiguredLevels(temp.configured_sync_levels);
        temp.credential_id = temp.datasource_cred_id;
        temp.account_id = temp.account;
        delete temp.configured_sync_parameters;
        delete temp.configured_sync_levels;
        delete temp.account;
        return temp;
    }
}

export default ReverseConnectorTransformation;
