import {
	EXTEND_NOTIFICATION_LIST,
	SET_NOTIFICATION_LIST,
	SET_NOTIFICATIONS_IS_LOADING
} from "../actionTypes/actionTypes";

import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";
// import { getNotificationsList } from "../../services/Api/api";
import ApiProvider from "services/Api/ApiProvider/ApiProvider";

const fetchNotificationList = (data) => (dispatch) => {
	apiWrapper(
		dispatch,
		SET_NOTIFICATIONS_IS_LOADING,
		SET_NOTIFICATION_LIST,
		{
			module: "notification",
			method: "getAll",
			apiData: {
				page: data.page
			}
		},
		HttpErrorHandler
	);
};

const fetchNotificationListNextPage = (data) => async (dispatch) => {
	const apiService = new ApiProvider({
		module: "notification",
		method: "getAll",
		apiData: {
			page: data.page
		}
	});
	dispatch({ type: SET_NOTIFICATIONS_IS_LOADING, payload: true });
	try {
		const res = await apiService.makeRequest();
		dispatch({ type: EXTEND_NOTIFICATION_LIST, payload: res });
		dispatch({ type: SET_NOTIFICATIONS_IS_LOADING, payload: false });
	} catch (e) {
		console.log(e);
		dispatch({ type: SET_NOTIFICATIONS_IS_LOADING, payload: false });
		HttpErrorHandler(dispatch, e?.response?.data?.message);
		dispatch({ type: SET_NOTIFICATION_LIST, payload: [] });
	}
};

export { fetchNotificationList, fetchNotificationListNextPage };
