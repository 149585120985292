import {
    SET_SELECTED_WAREHOUSE,
    SET_SELECTED_DATASOURCE,
    SET_SELECTED_CREDENTIAL,
    UPDATE_DATASOURCE_DATAWAREHOUSE_ID,
} from "../actionTypes/actionTypes";

const initialState = {
    selectedDataWarehouse: null,
    selectedDatasource: null,
    selectedCredentialForDatasource: null,
};

export const homepageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_WAREHOUSE:
            return {
                ...state,
                selectedDataWarehouse: action.payload,
            };
        case SET_SELECTED_DATASOURCE:
            return {
                ...state,
                selectedDatasource: action.payload,
            };
        case SET_SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredentialForDatasource: action.payload,
            };
        case UPDATE_DATASOURCE_DATAWAREHOUSE_ID:
            return {
                ...state,
                selectedDatasource: {
                    ...state.selectedDatasource,
                    data_warehouse_id: action.payload,
                },
            };
        default:
            return state;
    }
};
