import CloseIcon from '@mui/icons-material/Close';

import {
    enqueueSnackbar as enqueueSnackbarAction,
    closeSnackbar as closeSnackbarAction,
} from 'redux/actions/NotifierActions';

const closeSnackbar = (dispatch , ...args) => dispatch(closeSnackbarAction(...args));

const SuccessSnackbar = (dispatch , msg) => {  
    dispatch(enqueueSnackbarAction({
        message: msg,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            action: key => (
                <CloseIcon className="hoverable" fontSize="small" onClick={() => closeSnackbar(dispatch , key)} />
            ),
        },
    }));
}

export default SuccessSnackbar