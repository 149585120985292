import ConfiguredSyncTransormation from "../DataTransformations/ConfiguredSyncTransformer";
import BaseEndpointsClass from "./BaseEndpointsClass";
import axios from "config/reverseETLaxios";

class ConfiguredReportEndpoints extends BaseEndpointsClass {
    constructor(data) {
        super();
        this.resourceEndpoint = ``;
        this.transformer = new ConfiguredSyncTransormation();
    }
    getConfiguredSyncRunData(data) {
        return axios.get(
            `/reverse-connectors/${data.apiData.reverseConnectorSlug}/configured-syncs/${data.apiData.configuredSyncId}/runs/graph`
        );
    }

    getReverseSyncDashboardData(data) {
        return axios.get(
            `/reverse-connectors/${data.apiData.data.reverse_sync_slug}/configured-syncs/datewise${data.apiData.data.queryString}`
        );
    }
}

export default ConfiguredReportEndpoints;
