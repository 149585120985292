import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#FD9567",
        fontSize: "20px",
    },
    parent: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "not-allowed",
        "&:hover": {
            background: "#d5d5d573",
        },
    },
    component: {
        pointerEvents: "none",
        opacity: "0.5",
    },
    child: {
        position: "absolute",
        right: "5%",
        bottom: 0,
    },
}));

/**
 * Component Used to Render Disabled features
 * @param {*} props
 * @returns
 */
const DisabledComponent = (props) => {
    const classes = useStyles();
    return (
        <Tooltip title={`Please complete your onboarding wizard`}>
            <div>
                <div className={classes.parent}>
                    <div className={classes.component}>{props.children}</div>
                </div>
            </div>
        </Tooltip>
    );
};

/**
 * HOC component that displays a children
 * @param {*} props
 * @returns
 */
const FeatureFlagHOC = (props) => {
    // console.log(props.link)
    if (props.isOnboarding && props.link !== "/") {
        return (
            <DisabledComponent featureName={props.featureName} featureType={props.featureType}>
                {props.children}
            </DisabledComponent>
        );
    }
    return props.children;
};

export default FeatureFlagHOC;
