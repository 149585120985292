import {
	SET_COUNT,
	SET_NOTIFICATION_LIST,
	INCREMENT_COUNT,
	SET_NOTIFICATIONS_IS_LOADING,
	EXTEND_NOTIFICATION_LIST,
	APPEND_NOTIFICATION_LIST
} from "../actionTypes/actionTypes";

const initialState = {
	count: 0,
	notificationList: {
		notification_list: [],
		has_more: false
	},
	page: 1,
	notificationsIsLoading: false
};

export const realtimeNotification = (state = initialState, action) => {
	switch (action.type) {
		case SET_COUNT:
			return {
				...state,
				count: action.payload
			};
		case SET_NOTIFICATION_LIST:
			return {
				...state,
				notificationList: action.payload,
				page: state.page + 1
			};
		case INCREMENT_COUNT:
			console.log("from reducer", action.payload);
			return {
				...state,
				count: state.count + 1,
				notificationList: {
					...state.notificationList,
					notification_list: [action.payload, ...state.notificationList.notification_list]
				}
			};
		case EXTEND_NOTIFICATION_LIST:
			return {
				...state,
				notificationList: {
					notification_list: [
						...state.notificationList?.notification_list,
						...action.payload.notification_list
					],
					has_more: action.payload.has_more
				},
				page: state.page + 1
			};
		case SET_NOTIFICATIONS_IS_LOADING:
			return {
				...state,
				notificationsIsLoading: action.payload
			};
		// case APPEND_NOTIFICATION_LIST:
		// 	console.log("inside append notification reducer");
		// 	return {
		// 		...state,
		// 		notificationList: {
		// 			notification_list: [action.payload, ...state.notificationList.has_more],
		// 			has_more: action.payload.has_more
		// 		}
		// 	};
		default:
			return state;
	}
};
