import {
    FETCH_DATASOURCE_DETAIL,
    FETCH_CREDENTIALS,
    FETCH_REPORTS,
    FETCH_CONFIGURED_REPORTS,
    FETCH_TEMPLATES,
    SET_DATASOURCE_IS_LOADING,
    SET_CREDENATIALS_IS_LOADING,
    SET_CONFIGURED_REPORTS_IS_LOADING,
    SET_REPORTS_IS_LOADING,
    SET_TEMPLATE_IS_LOADING
} from "../actionTypes/actionTypes"

const initialState = {
    dataSource: null,
    credentials: [],
    reports: [],
    configuredReports: [],
    templates: [],
    dataSourceIsLoading: false,
    credentialsIsLoading: false,
    reportsIsLoading: false,
    configuredReportsIsLoading: false,
    templatesIsLoading: false
};

export const dataSourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATASOURCE_DETAIL:
            return {
                ...state,
                dataSource: action.payload,
                configuredReports: []
            }
        case FETCH_CREDENTIALS:
            return {
                ...state,
                credentials: action.payload
            }
        case FETCH_REPORTS:
            return {
                ...state,
                reports: action.payload
            }
        case FETCH_CONFIGURED_REPORTS:
            return {
                ...state,
                configuredReports: action.payload
            }
        case FETCH_TEMPLATES:
            return {
                ...state,
                templates: action.payload
            }
        case SET_DATASOURCE_IS_LOADING:
            return {
                ...state,
                dataSourceIsLoading: action.payload
            }
        case SET_CREDENATIALS_IS_LOADING:
            return {
                ...state,
                credentialsIsLoading: action.payload
            }
        case SET_REPORTS_IS_LOADING:
            return {
                ...state,
                reportsIsLoading: action.payload
            }
        case SET_CONFIGURED_REPORTS_IS_LOADING:
            return {
                ...state,
                configuredReportsIsLoading: action.payload
            }
        case SET_TEMPLATE_IS_LOADING:
            return {
                ...state,
                templatesIsLoading: action.payload
            }
        default:
            return state
    }
};
