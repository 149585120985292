import { SET_SEGMENTS, SET_SEGMENTS_IS_LOADING } from "../actionTypes/actionTypes";

import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchSegments = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_SEGMENTS_IS_LOADING,
        SET_SEGMENTS,
        {
            module: "dataModels",
            method: "getAllSegments",
        },
        HttpErrorHandler
    );
};

const fetchDataModelsLogs = (data) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DATA_MODELS_LOGS_IS_LOADING,
        SET_DATA_MODELS_LOGS,
        {
            module: "dataModels",
            method: "getLogs",
            apiData: {
                dataModelId: data.dataModelId,
            },
        },
        HttpErrorHandler
    );
};

export { fetchSegments, fetchDataModelsLogs };
