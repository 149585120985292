import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getToken, getUser } from "../../services/Api/api";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../config/axios";
import config from "constants/config";

import { useDispatch } from "react-redux";
import { saveTokens } from "../../redux/actions/authActions";
import CircularProgress from "@mui/material/CircularProgress";

import Cookies from "js-cookie";

const Callback = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const params = new URLSearchParams(location.search);
    const [hasToken, setHasToken] = useState(false);

    React.useEffect(() => {
        (async function asyncCallback() {
            try {
                console.log(params.get("token"));
                if (params.get("token")) {
                    setHasToken(true);
                    Cookies.remove("accessToken");
                    Cookies.remove("refreshToken");
                    Cookies.remove("expiresIn");
                    window.localStorage.clear();
                    dispatch({ type: "SET_USER", payload: null });

                    console.log(Cookies.get("accessToken"));
                    console.log(hasToken, "hasToken");
                    setLoading(true);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${params.get("token")}`;
                    // const user = await getUser();
                    //dispatch({ type: "SET_USER", payload: user.data.data });
                    Cookies.set("accessToken", params.get("token"));
                    // Cookies.set("refreshToken", res.data.refresh_token);
                    // Cookies.set("expiresIn", new Date().getTime() + res.data.expires_in * 1000);
                    setLoading(false);
                    navigate("/");
                } else {
                    setHasToken(true);
                    Cookies.remove("accessToken");
                    Cookies.remove("refreshToken");
                    Cookies.remove("expiresIn");
                    window.localStorage.clear();
                    dispatch({ type: "SET_USER", payload: null });

                    console.log(Cookies.get("accessToken"));
                    console.log(hasToken, "hasToken");
                    setLoading(true);
                    const res = await getToken(params.get("authorization_code"));
                    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access_token}`;
                    // const user = await getUser();
                    //dispatch({ type: "SET_USER", payload: user.data.data });
                    Cookies.set("accessToken", res.data.access_token);
                    Cookies.set("refreshToken", res.data.refresh_token);
                    Cookies.set("expiresIn", new Date().getTime() + res.data.expires_in * 1000);
                    setLoading(false);
                    navigate("/");
                }
            } catch (e) {
                setLoading(false);
                window.localStorage.clear();
                window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
            }
        })();
    }, []);
    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
            <CircularProgress />
        </Grid>
    );
};

export default React.memo(Callback);
