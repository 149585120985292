import React from 'react'
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import backgroundImage from '../../../../assets/images/loginPage/backgroundImage.jpg'
import Carousel from "react-material-ui-carousel";
import BannerImg from 'assets/images/loginPage/banner_1.webp'
import Banner2 from 'assets/images/loginPage/banner_2.webp'
import Banner3 from 'assets/images/loginPage/banner_3.webp'


const useStyle = makeStyles((theme) => {
    return {
        banner: {
            height: "100vh",
        },
        img: {
            background:
                `url(${backgroundImage})`,
            backgroundSize: 'cover'
        },
        text: {
            color: "#FFFFFF"
        },
        icon: {
            marginRight: 12
        }
    }
});


const Banner = () => {
    const classes = useStyle();
    return (
        <Grid container item xs={12} md={6} className={classes.banner}>
            <Grid
                container
                xs={12}
                className={classes.banner}
                style={{ backgroundColor: "#011C43" }}
                justifyContent="center"
                alignItems="center"
            >
                <Carousel interval={4000} sx={{width:'100%'}}>
                    <Grid container item xs={12} justifyContent='center' style={{ minHeight: "550px" }}>
                        <Grid item xs={10}>
                            <img style={{ width: '100%' }} src={BannerImg} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography align='center' variant='h6' style={{ fontSize: 22, color: '#FFFFFF', marginTop: 10 }}>
                                Your <span style={{ fontSize: 22, color: '#F87000' }}>Ultimate Data Partner</span>
                            </Typography>
                            <Typography align='center' variant='h6' style={{ fontSize: 16, color: '#FFFFFF', marginTop: 20, marginBottom: 20, fontWeight: 300 }}>
                                A hassle free DC Managed warehouse without any maintenance worries
                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid container item xs={12} justifyContent='center' style={{ minHeight: "550px" }}
                    >
                        <Grid item xs={10} >
                            <img style={{ width: '100%', }} src={Banner2} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography align='center' variant='h6' style={{ fontSize: 22, color: '#FFFFFF', marginTop: 30, lineHeight: 1 }}>
                                Work <span style={{ fontSize: 22, color: '#F87000' }}> Smarter</span> With Your Data

                            </Typography>
                            <Typography align='center' variant='h6' style={{ fontSize: 16, color: '#FFFFFF', marginTop: 20, marginBottom: 20, fontWeight: 300 }}>
                                A No-code, Self-serve Platform That Supports Both ETL & Reverse ETL                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid container item xs={12} justifyContent='center' style={{ minHeight: "550px" }}
                    >
                        <Grid item xs={10} >
                            <img style={{ width: '100%' }} src={Banner3} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography align='center' variant='h6' style={{ fontSize: 22, color: '#FFFFFF', marginTop: 10, lineHeight: 1 }}>
                                From Data Complexity to
                                <span style={{ fontSize: 22, color: '#F87000' }}> Data Productivity</span>

                            </Typography>
                            <Typography align='center' variant='h6' style={{ fontSize: 16, color: '#FFFFFF', marginTop: 20, marginBottom: 20, fontWeight: 300 }}>
                                A No-code, Self-serve Platform That Supports Both ETL & Reverse ETL                          </Typography>
                        </Grid>

                    </Grid>

                </Carousel>



            </Grid>
        </Grid >
    );
}


export default Banner
