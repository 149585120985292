import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class UserEndpoints extends BaseEndpointsClass {
	constructor() {
		super();
		this.resourceEndpoint = `notifications`;
		this.transformer = null;
	}
	getAll(data) {
		return axios.get(`/notification-logs?category=notification&page=${data.apiData.page}`);
	}
	getAllEngines() {
		return axios.get(`${this.resourceEndpoint}`);
	}
	configureNotification(data) {
		return axios.post(`/account/${data.apiData.data.account_slug}/notification_engine/${data.apiData.data.notification_engine}/credentials/save`, data.apiData.data)
	}
	editConfigureNotification(data) {
		return axios.post(`/account/${data.apiData.data.account_slug}/notification_engine/${data.apiData.data.notification_engine}/credentials/save/${data.apiData.data.cred_id}`, data.apiData.data)
	}
}

export default UserEndpoints;
