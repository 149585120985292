import { WORKSPACE_OVERALL_RUN_STATS,WORKSPACE_OVERALL_STATS,WORKSPACE_OVERALL_RUN_STATS_IS_LOADING,WORKSPACE_OVERALL_STATS_IS_LOADING,WORKSPACE_ACCOUNT_USERS,WORKSPACE_ACCOUNT_USERS_IS_LOADING,ACCOUNT_WORKSPACES,ACCOUNT_WORKSPACES_IS_LOADING,ACCOUNT_ROWS_MOVED, WORKSPACE_DATASOURCE_STATS, WORKSPACE_DATASOURCE_STATS_IS_LOADING, WORKSPACE_DATA_MOVED_BY_DATE, WORKSPACE_DATA_MOVED_BY_DATE_IS_LOADING, WORKSPACE_DESTINATION_STATS, WORKSPACE_DESTINATION_STATS_IS_LOADING, WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE, WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING, WORKSPACE_ROWS_MOVED_BY_DATE, WORKSPACE_ROWS_MOVED_BY_DATE_IS_LOADING} from "../actionTypes/actionTypes";

const initialState = {
    workspaceOverallStats:null,
    workspaceOverallRunStats:null,
    workspaceOverallStatsIsLoading:false,
    workspaceOverallRunStatsIsLoading:false,
    accountUsers:[],
    accountUsersIsLoading:false,
    accountWorkspaces:null,
    accountWorkspacesIsLoading:false,
    accountRowsMoved:null,
    workspaceDatasouceStats:null,
    workspaceDatasouceStatsIsLoading:false,
    workspaceReverseDestinationStats:null,
    workspaceReverseDestinationStatsIsLoading:false,
    workspaceRowsMovedByDate:null,
    workspaceReverseRowsMovedByDate:null,
    workspaceDataMovedByDate:null,
    workspaceRowsMovedByDateIsLoading:false,
    workspaceReverseRowsMovedByDateIsLoading:false,
    workspaceDataMovedByDateIsLoading:false

};

export const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case WORKSPACE_OVERALL_RUN_STATS:
            return {
                ...state,
                workspaceOverallRunStats: action.payload
            }
        case WORKSPACE_OVERALL_STATS:
            return {
                ...state,
                workspaceOverallStats: action.payload
            }
        case WORKSPACE_OVERALL_RUN_STATS_IS_LOADING:
            return {
                ...state,
                workspaceOverallRunStatsIsLoading: action.payload
            }
        case WORKSPACE_OVERALL_STATS_IS_LOADING:
            return {
                ...state,
                workspaceOverallStatsIsLoading: action.payload
            }
        case WORKSPACE_ACCOUNT_USERS:
            return {
                ...state,
                accountUsers:action.payload
            }

        case WORKSPACE_ACCOUNT_USERS_IS_LOADING:
            return {
                ...state,
                accountUsersIsLoading:action.payload
            }
        case ACCOUNT_WORKSPACES:
            return {
                ...state,
                accountWorkspaces:action.payload
            }

        case ACCOUNT_WORKSPACES_IS_LOADING:
            return {
                ...state,
                accountWorkspacesIsLoading:action.payload
            }

        case ACCOUNT_ROWS_MOVED:
            return {
                ...state,
                accountRowsMoved:action.payload
            }
        case WORKSPACE_DATASOURCE_STATS_IS_LOADING:
            return{
                ...state,
                workspaceDatasouceStatsIsLoading:action.payload
            }
        case WORKSPACE_DATASOURCE_STATS:
            return{
                ...state,
                workspaceDatasouceStats:action.payload
            }
        case WORKSPACE_DESTINATION_STATS_IS_LOADING:
            return{
                ...state,
                workspaceReverseDestinationStatsIsLoading:action.payload
            }
        case WORKSPACE_DESTINATION_STATS:
            return{
                ...state,
                workspaceReverseDestinationStats:action.payload
            }
        case WORKSPACE_ROWS_MOVED_BY_DATE_IS_LOADING:
            return{
                ...state,
                workspaceRowsMovedByDateIsLoading:action.payload
            } 
        case WORKSPACE_ROWS_MOVED_BY_DATE:
            return{
                ...state,
                workspaceRowsMovedByDate:action.payload
            }   
        case WORKSPACE_DATA_MOVED_BY_DATE_IS_LOADING:
            return{
                ...state,
                workspaceDataMovedByDateIsLoading:action.payload
            }   
        case WORKSPACE_DATA_MOVED_BY_DATE:
            return{
                ...state,
                workspaceDataMovedByDate:action.payload
            }   
        case WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING:
            return{
                ...state,
                workspaceReverseRowsMovedByDateIsLoading:action.payload
            }   
        case WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE:
            return{
                ...state,
                workspaceReverseRowsMovedByDate:action.payload
            }         
        default:
            return state;
    }
};
