import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class WebflowAnnouncement extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `webflow`;
        this.transformer = null;
    }
    getLatestAnnouncement() {
        return axios.get(`${this.resourceEndpoint}/announcement/latest`);
    }
    getAllAnnouncement() {
        return axios.get(`${this.resourceEndpoint}/announcement/all`);
    }
}
export default WebflowAnnouncement