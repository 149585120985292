import {
    SET_DBT_TRANSFORMATIONS,
    SET_DBT_TRANSFORMATIONS_LOADING,
    SET_DBT_CREDENTIALS,
    SET_DBT_CREDENTIALS_LOADING,
} from "../actionTypes/actionTypes";

const initialState = {
    dbtTransformations: [],
    dbtCredentials: [],
    isLoading: false,
    credentialsLoading: false,
};

export const dbtTransformationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DBT_TRANSFORMATIONS:
            return {
                ...state,
                dbtTransformations: action.payload,
            };
        case SET_DBT_TRANSFORMATIONS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_DBT_CREDENTIALS:
            return {
                ...state,
                dbtCredentials: action.payload,
            };
        case SET_DBT_CREDENTIALS_LOADING:
            return {
                ...state,
                credentialsLoading: action.payload,
            };
        default:
            return state;
    }
};
