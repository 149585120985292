import {
    DATAWAREHOUSES_LIST_LOADED,
    SET_IS_LOADING
} from "../actionTypes/actionTypes"

const initialState = {
    dataWareHouses: [],
    isLoading: false
};

export const dataWareHousesReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATAWAREHOUSES_LIST_LOADED:
            return {
                ...state,
                dataWareHouses: action.payload
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
};
