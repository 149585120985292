import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class DBTTransformationEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `transformations/dbt`;
        this.transformer = null;
    }
    getAll() {
        return axios.get(`${this.resourceEndpoint}`);
    }
    getAllCreds() {
        return axios.get(`${this.resourceEndpoint}/credentials`);
    }
    saveCred(data) {
        return axios.post(`${this.resourceEndpoint}/credentials/${data.apiData.provider}/save`, data.apiData.data);
    }
    getAllRepos(data) {
        return axios.get(
            `${this.resourceEndpoint}/credentials/${data.apiData.cred_provider}/${data.apiData.cred_id}/repositories`
        );
    }
    getAllBranches(data) {
        return axios.get(
            `${this.resourceEndpoint}/credentials/${data.apiData.cred_provider}/${data.apiData.cred_id}/repositories/${data.apiData.repo_name}/branches?owner_name=${data.apiData.owner_name}`
        );
    }
    getAllModels(data) {
        return axios.get(
            `${this.resourceEndpoint}/credentials/${data.apiData.cred_provider}/${data.apiData.cred_id}/repositories/${data.apiData.repo_id}/branches/${data.apiData.branch_name}/models`
        );
    }
    createDbtProject(data) {
        return axios.post(`${this.resourceEndpoint}/projects`, data.apiData.data);
    }
    getOne(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.dbtTransformationId}`);
    }
    getChangeHistory(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.dbtTransformationId}/change-history`);
    }
    editDbtProject(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.dbtTransformationId}`, data.apiData.data);
    }
    deleteTransformation(data) {
        return axios.delete(`${this.resourceEndpoint}/${data.apiData.dbtTransformationId}`);
    }
    getLogText(data) {
        return axios.get(`${this.resourceEndpoint}/logs`, { headers: { "X-S3-File-Path": data.apiData.s3FilePath } });
    }
    runDbtProject(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.dbtTransformationId}/runs`);
    }
}

export default DBTTransformationEndpoints;
