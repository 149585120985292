import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class SubscriptionEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `subscription`;
        this.transformer = null;
    }
    getPlans() {
        return axios.get(`${this.resourceEndpoint}/getPlans`);
    }
    checkout(data) {
        return axios.post(`${this.resourceEndpoint}/checkout`, data.apiData.data);
    }
    acknowledge(data) {
        return axios.post(`${this.resourceEndpoint}/acknowledge/${data.apiData.hostedPageId}`);
    }
    cancel(data) {
        return axios.post(`${this.resourceEndpoint}/cancel/${data.apiData.subscription_id}`);
    }
    getSubscriptionItems(data) {
        return axios.post(`${this.resourceEndpoint}/subscription_items/${data.apiData.subscription_id}`);
    }
    updatePaymentMethod(data) {
        console.log(data);
        return axios.post(`${this.resourceEndpoint}/update_payment_method/${data.apiData.subscription_id}`, data.apiData);
    }
    removeScheduleCancellation(data) {
        return axios.post(`${this.resourceEndpoint}/remove_schedule_cancellation/${data.apiData.subscription_id}`);
    }
    getCustomerInvoices(data) {
        return axios.post(`/subscription/zoho/customer/${data.apiData.customer_id}/invoices/pending_filtered`);
    }
    getInvoice(data) {
        return axios.post(`${this.resourceEndpoint}/invoice/${data.apiData.invoice_id}`);
    }
    getSubcriptionData(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.subscription_id}/get_subcription`);
    }
    updateAddress(data) {
        return axios.post(`${this.resourceEndpoint}/update_address`, data.apiData.data);
    }
    subscribeToFreePlan(data) {
        return axios.post(`${this.resourceEndpoint}/subscribe-to-free-plan`);
    }
    upgradeToPremiumTrial(data) {
        return axios.post(`${this.resourceEndpoint}/upgrade_to_premium`);
    }
}

export default SubscriptionEndpoints;
