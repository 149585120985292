import { Grid, Typography, Button, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotFound } from "./routing";
import { useNavigate } from "react-router-dom";
import axios from "config/axios";
import Cookies from "js-cookie";
import { TOGGLE_RESTRICTED_FEATURE_POPUP } from "redux/actionTypes/actionTypes";
import config from "constants/config";
// import StarsIcon from "@mui/icons-material/Stars";
import StarRateIcon from "@mui/icons-material/StarRate";
import { getAuth } from "firebase/auth";
import { TOGGLE_BANNER, TOGGLE_UPGRADE_TO_PREMIUM_POPUP } from "redux/actionTypes/actionTypes";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#FD9567",
        fontSize: "20px",
    },
    parent: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "not-allowed",
        "&:hover": {
            background: "#d5d5d573",
        },
    },
    component: {
        pointerEvents: "none",
        opacity: "0.5",
    },
    child: {
        position: "absolute",
        right: "5%",
        bottom: 0,
    },
}));
/**
 * Function that returns true if req feature exists in user features
 * @param {*} userFeature
 * @param {*} reqFeature
 * @returns {Boolean}
 */
export const checkForFeature = (userFeature, reqFeature) => {
    let check = false;
    if (!Array.isArray(reqFeature)) {
        return true;
    }
    reqFeature.forEach((requiredFeature) => {
        userFeature.forEach((userFeature) => {
            if (requiredFeature === userFeature) {
                check = true;
            }
        });
    });

    return check;
};

export const checkForMultipleFeature = (userFeature, reqFeature) => {
    let check = true;
    if (!Array.isArray(reqFeature)) {
        return true;
    }
    reqFeature.forEach((requiredFeature) => {
        let contains = false
        userFeature.forEach((userFeature) => {
            if (requiredFeature === userFeature) {
                contains = true;
            }
        });
        if (contains===false){
            check = false
            return false
        }
    });

    return check;
};

/**
 * Shadow component when free tier ends if account status is TRIAL_LIMITS_EXCEEDED
 * @Component
 * @param {*} props
 * @returns
 */
const BanAccountComponent = (props) => {
    const navigate = useNavigate();
    const trialAvailed = useSelector((state) => state.users.user.trial_used);
    const dispatch = useDispatch();

    const handleLogout = async () => {
            try {
                const auth = getAuth();
                await auth.signOut();
                // await axios.post(`${config.authUrl}logout`, { refresh_token: Cookies.get("refreshToken") });
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                Cookies.remove("expiresIn");
                window.localStorage.clear();
                window.location.href = `${config.frontendUrl}login`;
    
                // window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
            } catch (e) {
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                Cookies.remove("expiresIn");
                window.localStorage.clear();
                window.location.href = `${config.frontendUrl}login`;
                // window.location.href = `${config.authUrl}auth?grant_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
            }
        handleClose();
    };
    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    opacity: "0.4",
                    zIndex: 1502,
                    backgroundColor: "#a6a6a6",
                }}
            ></Grid>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    right: 0,
                    zIndex: 1602,
                    backgroundColor: "transparent",
                }}
            >
                <Grid item xs={6} container justifyContent="center" style={{ zIndex: 1603, marginLeft: "5%" }}>
                    <Grid
                        container
                        item
                        style={{ padding: 10, backgroundColor: "white", borderRadius: 10 }}
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className="size16" align="center">
                                You have exceeded your account limits for the month. Please upgrade to keep enjoying DataChannel
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center" spacing={1}>
                            <Grid item>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => navigate("/settings?tab=subscription_management&subTab=0&open_plan=true")}                                >
                                    Upgrade
                                </Button>
                            </Grid>
                            {!trialAvailed&&<Grid item>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                        dispatch({
                                            type: TOGGLE_UPGRADE_TO_PREMIUM_POPUP,
                                            payload: true,
                                        })
                                    }>                                 
                                Start your trial
                                </Button>
                            </Grid>}
                            <Grid item>
                                <Button variant="outlined" color="primary" size="small" onClick={handleLogout}>
                                    Sign out
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
/**
 * Component Used to Render Disabled features
 * @param {*} props
 * @returns
 */
const DisabledComponent = (props) => {
    const classes = useStyles();
    return (
        <Tooltip title={`Activate your data today, add ${props.featureName} to your subscription`}>
            <div>
                <div className={classes.parent}>
                    <div className={classes.component}>{props.children}</div>
                </div>
            </div>
        </Tooltip>
    );
};

/**
 * HOC component that displays a children
 * @param {*} props
 * @returns
 */
const FeatureFlagHOC = (props) => {
    const user = useSelector((state) => state.users.user);
    const isRestrictedPop = useSelector((state) => state.uiStates.restrictedFeaturePopup?.show_popup);
    console.log(isRestrictedPop);
    const dispatch = useDispatch();
    let userFeature = [];

    if (user?.subscription_meta_data?.features_flags) {
        userFeature = user?.subscription_meta_data?.features_flags;
    }
    if (props?.requiredFeature?.length === 0) {
        if (isRestrictedPop) {
            dispatch({
                type: TOGGLE_RESTRICTED_FEATURE_POPUP,
                payload: { show_popup: false, isClosable: null, showUpgradeButtons: null },
            });
        }
        return props.children;
    }
    if (userFeature == null) {
        return null;
    }
    if (checkForFeature(userFeature, props.requiredFeature)) {
        if (user.status === "FREE_TIER_LIMITS_EXCEEDED" && props.disablePage) {
            return (
                <div>
                    <BanAccountComponent />
                    <div>{props.children}</div>
                </div>
            );
        }
        if (isRestrictedPop) {
            dispatch({
                type: TOGGLE_RESTRICTED_FEATURE_POPUP,
                payload: { show_popup: false, isClosable: null, showUpgradeButtons: null },
            });
        }
        return props.children;
    }
    if (!checkForFeature(userFeature, props.requiredFeature) && props.showPayPopup) {
        console.log(isRestrictedPop);
        if (!isRestrictedPop) {
            dispatch({
                type: TOGGLE_RESTRICTED_FEATURE_POPUP,
                payload: { show_popup: true, isClosable: false, showUpgradeButtons: true },
            });
        }
        return props.children;
    }

    if (props.redirect) {
        return <NotFound />;
    }
    if (!checkForFeature(userFeature, props.requiredFeature) && props.hideComponent === true) {
        return null;
    }
    return (
        <DisabledComponent featureName={props.featureName} featureType={props.featureType}>
            {props.children}
        </DisabledComponent>
    );
};

export default FeatureFlagHOC;
