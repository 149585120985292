import {
    SET_ONBOARDING_CREDENTIAL,
    SET_ONBOARDING_DATASOURCE,
    SET_ONBOARDING_STEP,
    SET_ONBOARDING_CREDENTIAL_NAME,
    SET_ONBOARDING_WAREHOUSE,
    TOGGLE_UPGRADED_TO_PREMIUM_TRIAL
} from "../actionTypes/actionTypes";

const initialState = {
    selectedCredentials: null,
    selectedDatasource: null,
    step: null,
    credentialName: null,
    selectedWarehouse: null,
    upgradedToPremiumTrial: false
};

export const onBoardingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ONBOARDING_CREDENTIAL:
            return {
                ...state,
                selectedCredentials: action.payload,
            };
        case SET_ONBOARDING_DATASOURCE:
            return {
                ...state,
                selectedDatasource: action.payload,
            };
        case SET_ONBOARDING_STEP:
            return {
                ...state,
                step: action.payload,
            };
        case SET_ONBOARDING_CREDENTIAL_NAME:
            return {
                ...state,
                credentialName: action.payload,
            };
        case SET_ONBOARDING_WAREHOUSE:
            return {
                ...state,
                selectedWarehouse: action.payload,
            };
        case TOGGLE_UPGRADED_TO_PREMIUM_TRIAL:
            return {
                ...state,
                upgradedToPremiumTrial: action.payload,
            };
        default:
            return state;
    }
};
