import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AsyncJobsEndpoints extends BaseEndpointsClass {
    constructor(data) {
        super();
        this.resourceEndpoint = "async-job-status";
        this.transformer = null;
    }
    checkAsyncJobStatus(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.job_name}`);
    }
}

export default AsyncJobsEndpoints;
