import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import reverseAxios from "config/reverseETLaxios";
import WorkspaceTransformation from "../DataTransformations/WorkspaceTransformation";

class WorkspaceEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `workspace`;
        this.transformer = new WorkspaceTransformation();
    }

    switchWorkspace(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.workspace_id}/switch`);
    }
    getWorkspaceOverAllRunStats(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/overall_run_stats${data.apiData.data}`);

    }
    getWorkspaceOverallStats(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/overall_stats${data.apiData.data}`);

    }
    addWorkspace(data){
        return axios.post(`${this.resourceEndpoint}`,data.apiData);
    }
    editWorkspace(data){
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.workspace_id}`,data.apiData);
    }
    addWorkspaceUser(data){
        console.log(data)
        return axios.post(`${this.resourceEndpoint}/${data.apiData.workspace_id}/add_user`,data.apiData)
    }
    removeUserToWorkspace(data){
        return axios.post(`${this.resourceEndpoint}/${data.apiData.workspace_id}/remove_user`,data.apiData)
    }
    getAccountUser(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/account_users`)
    }
    getAccountWorkspaces(data){
        return axios.get(`${this.resourceEndpoint}/account_workspaces`)
    }
    getDatasourceStats(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/data_sources_stats${data.apiData.data}`)
    }
    getReverseConnectorDashboardData(data){
        return reverseAxios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/reverse_connector_logs${data.apiData.data}`)
    }
    getRowsMovedByDate(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/datewise-stats/rows-moved${data.apiData.data}`)
    }
    getDataMovedByDate(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/datewise-stats/data-moved${data.apiData.data}`)
    }
    getReverseRowsMovedByDate(data){
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/datewise-stats/reverse-rows-moved${data.apiData.data}`)
    }
    getAccountsRowsMoved(data){
        return axios.get(`${this.resourceEndpoint}/rows_moved${data.apiData.data}`)
    }
    getErrorsForDatasource(data) {
        console.log(data)
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/datasource/${data.apiData.datasourceSlug}/datewise-error-logs${data.apiData.data}`);
    }
    getPartialSucesssForDatasource(data) {
        console.log(data)
        return axios.get(`${this.resourceEndpoint}/${data.apiData.workspace_id}/datasource/${data.apiData.datasourceSlug}/datewise-partial-success-logs${data.apiData.data}`);
    }
}

export default WorkspaceEndpoints;
