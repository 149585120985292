import {
    SET_ORCHESTRATIONS_LIST,
    SET_ORCHESTRATIONS_LOADING,
    SET_ORCHESTRATION_MODE,
    SET_ADDING_NODES_TO_CANVAS,
} from "../actionTypes/actionTypes";

const initialState = {
    orchestrations: [],
    orchestrationsIsLoading: false,
    orchestrationMode: null,
    addingNodesToCanvas: false,
};

export const orchestrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORCHESTRATIONS_LIST:
            return {
                ...state,
                orchestrations: action.payload,
            };
        case SET_ORCHESTRATIONS_LOADING:
            return {
                ...state,
                orchestrationsIsLoading: action.payload,
            };
        case SET_ORCHESTRATION_MODE:
            return {
                ...state,
                orchestrationMode: action.payload,
            };
        case SET_ADDING_NODES_TO_CANVAS:
            return {
                ...state,
                addingNodesToCanvas: action.payload,
            };
        default:
            return state;
    }
};
