import { SET_SRC,SET_SRC_IS_LOADING } from "redux/actionTypes/actionTypes";

const initialState = {
    logo_src: null,
    icon_src: null,
    src_is_loading:true
};

export const assetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SRC:
            return {
                ...state,
                logo_src: action.payload.logo_src,
                icon_src: action.payload.icon_src,
            }
        case SET_SRC_IS_LOADING:
            return {
                ...state,
                src_is_loading: action.payload,
            }
        default:
            return state
    }
};