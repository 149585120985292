import {
	FETCH_DATASOURCE_DETAIL,
	FETCH_CREDENTIALS,
	FETCH_REPORTS,
	FETCH_CONFIGURED_REPORTS,
	FETCH_TEMPLATES,
	SET_DATASOURCE_IS_LOADING,
	SET_CREDENATIALS_IS_LOADING,
	SET_CONFIGURED_REPORTS_IS_LOADING,
	SET_REPORTS_IS_LOADING,
	SET_TEMPLATE_IS_LOADING
} from "../actionTypes/actionTypes";
// import {
// 	getDataSourceDetail,
// 	getCredentials,
// 	getReports,
// 	getConfiguredReports,
// 	getDefaultReports
// } from "../../services/Api/api";
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
// import { getAPIWrapper } from "../../utils/api-try-catch-wrapper/get-api-wrapper";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchDatasource = (slug) => async (dispatch) => {
	apiWrapper(
		dispatch,
		SET_DATASOURCE_IS_LOADING,
		FETCH_DATASOURCE_DETAIL,
		{
			module: "datasource",
			method: "getOne",
			apiData: {
				slug: slug
			}
		},
		HttpErrorHandler
	);
};

const fetchCredentials = (slug) => async (dispatch) => {
	apiWrapper(
		dispatch,
		SET_CREDENATIALS_IS_LOADING,
		FETCH_CREDENTIALS,
		{
			module: "datasource",
			method: "getCredentials",
			apiData: {
				slug: slug
			}
		},
		HttpErrorHandler
	);
};

const fetchReports = (slug) => async (dispatch) => {
	apiWrapper(
		dispatch,
		SET_REPORTS_IS_LOADING,
		FETCH_REPORTS,
		{
			module: "datasource",
			method: "getReports",
			apiData: {
				slug: slug
			}
		},
		HttpErrorHandler
	);
};

const fetchConfiguredReports = (slug) => async (dispatch) => {
	apiWrapper(
		dispatch,
		SET_CONFIGURED_REPORTS_IS_LOADING,
		FETCH_CONFIGURED_REPORTS,
		{
			module: "datasource",
			method: "getConfiguredReports",
			apiData: {
				slug: slug
			}
		},
		HttpErrorHandler
	);
};

const fetchTemplates = (slug) => async (dispatch) => {
	apiWrapper(
		dispatch,
		SET_TEMPLATE_IS_LOADING,
		FETCH_TEMPLATES,
		{
			module: "datasource",
			method: "getTemplates",
			apiData: {
				slug: slug
			}
		},
		HttpErrorHandler
	);
};

export { fetchDatasource, fetchCredentials, fetchReports, fetchConfiguredReports, fetchTemplates };
