import {
    SET_CHANGE_HISTORY,
    SET_CHANGE_HISTORY_IS_LOADING
} from "../actionTypes/actionTypes"

const initialState = {
    changeHistory: [],
    changeHistoryIsLoading: false
};

export const configuredSyncReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHANGE_HISTORY:
            return {
                ...state,
                changeHistory: action.payload
            }
        case SET_CHANGE_HISTORY_IS_LOADING:
            return {
                ...state,
                changeHistoryIsLoading: action.payload
            }
        default:
            return state
    }
};
