const checkIfExceededLimitForActiveReports = (user, dataSources, dataSourceSlug) => {
    const limit = user.feature_flags_with_limits?.filter((el) => el.name === "ACTIVE_CONNECTORS");

    let pipeline_limit = null;

    if (limit?.length === 0) {
        return true;
    }
    if (limit?.length > 0) {
        pipeline_limit = limit[0]["limit"];
    }
    let configured_datasources = dataSources?.dataSources?.filter((el) => el.data_warehouse_id !== null);
    let is_current_datasource_included = false;
    configured_datasources.forEach((el) => {
        if (el.slug === dataSourceSlug) {
            is_current_datasource_included = true;
        }
    });

    if (
        pipeline_limit &&
        configured_datasources.length >= pipeline_limit &&
        !is_current_datasource_included &&
        limit[0]["feature_billing_type"] == "RESTRICTED"
    ) {
        return true;
    }
    return false;
};

const checkIfExceededLimitForActiveSync = (user, reverseConnectors, slug) => {
    const limit = user.feature_flags_with_limits?.filter((el) => el.name === "ACTIVE_DESTINATION");
    let sync_limit = null;
    if (limit?.length === 0) {
        return true;
    }

    let billing_type = null;
    if (limit?.length > 0) {
        sync_limit = limit[0]["limit"];
        billing_type = limit[0]["feature_billing_type"];
    }
    let configured_destination = reverseConnectors?.reverseConnectors?.filter((el) => el.no_of_configured_syncs > 0);
    let is_current_datasource_included = false;
    configured_destination.forEach((el) => {
        if (el.slug === slug) {
            is_current_datasource_included = true;
        }
    });
    if (
        sync_limit !== null &&
        configured_destination.length >= sync_limit &&
        !is_current_datasource_included &&
        billing_type === "RESTRICTED"
    ) {
        return true;
    }
    return false;
};
export { checkIfExceededLimitForActiveReports, checkIfExceededLimitForActiveSync };
