import { useSelector } from "react-redux";
import EventTracker from "./index";
import Config from "constants/config";

const useEventTracker = () => {
    const users = useSelector((state) => state.users);
    const init = (app_id) => {
        EventTracker.init(app_id);
    };
    const setUserId = () => {
        EventTracker.setUserId(users?.user?.id);
        window.gtag("config", Config.gaTagId, {
            user_id: users?.user?.account_id,
        });
    };
    const trackPageView = () => {
        EventTracker.trackPageView();
    };
    const trackSelfDescribingEvent = (event_name, event_data = {}) => {
        EventTracker.trackSelfDescribingEvent(event_name, event_data, users?.user?.id, users?.user?.account_id);
    };
    const trackGaEvent = (event_name, event_data = {}) => {
        window.gtag("config", Config.gaTagId, {
            user_id: users?.user?.account_id,
        });
        window.gtag("event", event_name, event_data);
    };
    return { init, setUserId, trackPageView, trackSelfDescribingEvent, trackGaEvent };
};

export default useEventTracker;
