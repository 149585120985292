import React from "react";
import useApi from "./useApi";

/**
 *  This Hook make call to the useApi hook after a fixed Interval of Time to poll the data .
 * @param {Number} timeout
 * @param {JSON} data
 * @param {Boolean} startPolling
 * @param {Number} maxTimeout
 * @param {Boolean} shouldFetchOnce
 * @returns
 */
const usePollingApi = (timeout, data, startPolling, maxTimeout, shouldFetchOnce) => {
    const [responseData, setResponseData] = React.useState();
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    const [isPollingStarted, setIsPollingStarted] = React.useState(startPolling);
    const [, isLoading, makeRequest, , errorData] = useApi();
    let pollTimeout;

    // First fetch if shouldFetchOnce is true
    React.useEffect(() => {
        if (shouldFetchOnce) {
            handleFirstFetch();
        }
    }, []);

    // Consecutive Polling till maxTimeout if isPollingStarted is true
    React.useEffect(() => {
        let pollInterval;

        if (isPollingStarted && !isTimedOut) {
            pollInterval = setInterval(() => {
                fetchResponse();
            }, timeout);
        }
        if (isPollingStarted && isTimedOut) {
            clearInterval(pollInterval);
        }
        return () => {
            clearInterval(pollInterval);
        };
    }, [isPollingStarted, isTimedOut]);

    // Set isTimedOut on mount as well as when isPollingStarted changes

    React.useEffect(() => {
        if (isPollingStarted) {
            pollTimeout = setTimeout(() => {
                setIsTimedOut(true);
            }, maxTimeout);
        } else {
            if (pollTimeout) {
                clearTimeout(pollTimeout);
            }
        }
        return () => {
            clearTimeout(pollTimeout);
        };
    }, [isPollingStarted]);

    /**
     * Fetches response from Api and sets responseData state with it
     */
    const fetchResponse = async () => {
        const res = await makeRequest(data);
        setResponseData(res);
    };

    /**
     * Fetches first response on page mount
     */
    const handleFirstFetch = () => {
        fetchResponse();
    };

    return [isLoading, responseData, setIsPollingStarted, errorData];
};

export default usePollingApi;
