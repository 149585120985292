import React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useSelector, useDispatch } from "react-redux";
import { fetchNotificationList, fetchNotificationListNextPage } from "redux/actions/realtimeNotificationActions";
import makeStyles from "@mui/styles/makeStyles";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
    notification: {
        marginTop: 25,
    },
    hr: {
        marginTop: 11,
        marginBottom: 12,
        color: "#D9D9D9",
    },
    infoIcon: {
        fontSize: 18,
        color: "#2ae42a",
    },
    cancelIcon: {
        fontSize: 18,
        color: "#ff6464",
    },
}));

const notificationToMessageMapper = {
    orchestration: {
        "orchestration.run": {
            Success: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Orchestration {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link className="link" to={`/orchestrations/${additionalData.orchestration_id}`}>
                        {additionalData.orchestra_name}
                    </Link>{" "}
                    Completed Successfully
                </Typography>
            ),
            Error: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Orchestration {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link className="link" to={`/orchestrations/${additionalData.orchestration_id}`}>
                        {additionalData.orchestra_name}
                    </Link>{" "}
                    Completed with status: Error
                </Typography>
            ),
            RunTruncated: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Orchestration {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link className="link" to={`/orchestrations/${additionalData.orchestration_id}`}>
                        {additionalData.orchestra_name}
                    </Link>{" "}
                    Completed with status: Error(run truncated by subsequent run)
                </Typography>
            ),
        },
        "orchestration.node.run": {
            Success: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data Orchestration
                    <Link className="link" to={`/orchestrations/${additionalData.orchestration_id}`}>
                        {additionalData.orchestra_name}
                    </Link>
                    {"-"} Node type {additionalData.node_type} entity name {additionalData.entity_name} completed with status{" "}
                    {additionalData.error_description}
                </Typography>
            ),
            Error: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data Orchestration
                    <Link className="link" to={`/orchestrations/${additionalData.orchestration_id}`}>
                        {additionalData.orchestra_name}
                    </Link>
                    {"-"} Node type {additionalData.node_type} entity name {additionalData.entity_name} completed with status{" "}
                    {additionalData.error_description}
                </Typography>
            ),
        },
    },
    report: {
        "report.run": {
            Success: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data pipeline {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/datasource/${additionalData.datasource_slug}/detail/editreport/${additionalData.report_id}`}
                    >
                        {additionalData.pipeline_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/datasource/${additionalData.datasource_slug}`}>
                        {additionalData.datasource_name}
                    </Link>{" "}
                    completed with status: Success ({additionalData.rows_moved} rows moved)
                </Typography>
            ),
            Error: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data pipeline {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/datasource/${additionalData.datasource_slug}/detail/editreport/${additionalData.report_id}`}
                    >
                        {additionalData.pipeline_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/datasource/${additionalData.datasource_slug}`}>
                        {additionalData.datasource_name}
                    </Link>{" "}
                    completed with status: Error
                </Typography>
            ),
            "Partial Success": (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data pipeline {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/datasource/${additionalData.datasource_slug}/detail/editreport/${additionalData.report_id}`}
                    >
                        {additionalData.pipeline_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/datasource/${additionalData.datasource_slug}`}>
                        {additionalData.datasource_name}
                    </Link>{" "}
                    completed with status: Partial Success ({additionalData.rows_moved} rows moved)
                </Typography>
            ),
            RunTruncated: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data pipeline {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/datasource/${additionalData.datasource_slug}/detail/editreport/${additionalData.report_id}`}
                    >
                        {additionalData.pipeline_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/datasource/${additionalData.datasource_slug}`}>
                        {additionalData.datasource_name}
                    </Link>{" "}
                    completed with status: Error (run truncated by subsequent run)
                </Typography>
            ),
        },
    },
    sync: {
        "sync.run": {
            Success: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data sync {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/reverse-connectors/${additionalData.reverse_connector_slug}/sync/${additionalData.configured_sync_id}`}
                    >
                        {additionalData.configured_sync_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/reverse-connectors/${additionalData.reverse_connector_slug}`}>
                        {additionalData.reverse_connector_name}
                    </Link>{" "}
                    completed with status: Success ({additionalData.rows_moved} rows moved, {additionalData?.rows_processed} rows
                    processed)
                </Typography>
            ),
            Error: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data sync {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/reverse-connectors/${additionalData.reverse_connector_slug}/sync/${additionalData.configured_sync_id}`}
                    >
                        {additionalData.configured_sync_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/reverse-connectors/${additionalData.reverse_connector_slug}`}>
                        {additionalData.reverse_connector_name}
                    </Link>{" "}
                    completed with status: Error
                </Typography>
            ),
            PartialSuccess: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data sync {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/reverse-connectors/${additionalData.reverse_connector_slug}/sync/${additionalData.configured_sync_id}`}
                    >
                        {additionalData.configured_sync_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/reverse-connectors/${additionalData.reverse_connector_slug}`}>
                        {additionalData.reverse_connector_name}
                    </Link>{" "}
                    completed with status: Partial Success ({additionalData.rows_moved} rows moved,{" "}
                    {additionalData?.rows_processed} rows processed)
                </Typography>
            ),
            RunTruncated: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Data sync {additionalData.runtype} run (Start time: {additionalData.start_time}) for{" "}
                    <Link
                        className="link"
                        to={`/reverse-connectors/${additionalData.reverse_connector_slug}/sync/${additionalData.configured_sync_id}`}
                    >
                        {additionalData.configured_sync_name}
                    </Link>{" "}
                    of{" "}
                    <Link className="link" to={`/reverse-connectors/${additionalData.reverse_connector_slug}`}>
                        {additionalData.reverse_connector_name}
                    </Link>{" "}
                    completed with status: Error (run truncated by subsequent run)
                </Typography>
            ),
        },
    },
    dbt_transformation: {
        "transformation.dbt.run": {
            Success: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Dbt project{" "}
                    <Link className="link" to={`/transformations/dbt/edit/${additionalData.dbt_project_id}`}>
                        {additionalData.dbt_project_name}
                    </Link>{" "}
                    completed with status: Success
                </Typography>
            ),
            Error: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Dbt project{" "}
                    <Link className="link" to={`/transformations/dbt/edit/${additionalData.dbt_project_id}`}>
                        {additionalData.dbt_project_name}
                    </Link>{" "}
                    completed with status: Failed
                </Typography>
            ),
            "Partial Success": (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    Dbt project{" "}
                    <Link className="link" to={`/transformations/dbt/edit/${additionalData.dbt_project_id}`}>
                        {additionalData.dbt_project_name}
                    </Link>{" "}
                    completed with status: Partial Success
                </Typography>
            ),
        },
    },
    sql_transformation: {
        "transformation.run": {
            Success: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    SQL Transformation
                    <Link className="link" to={`/transformations/sql/edit/${additionalData.transformation_id}`}>
                        {additionalData.transformation_name}
                    </Link>{" "}
                    completed with status: Success
                </Typography>
            ),
            Error: (notification, additionalData) => (
                <Typography variant="subtitle1" className="boldText">
                    SQL Transformation
                    <Link className="link" to={`/transformations/sql/edit/${additionalData.transformation_id}`}>
                        {additionalData.transformation_name}
                    </Link>{" "}
                    completed with status: Failed
                </Typography>
            ),
        },
    },
};

const getMessageForNotification = (notification) => {
    const additionalData = JSON.parse(notification.additional_data);
    console.log(notification.notification_module);
    console.log(additionalData.error_description);
    return notificationToMessageMapper[notification.notification_module][notification.type][additionalData.error_description](
        notification,
        additionalData
    );
};

const NotificationList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const timezone = useSelector((state) => state?.users?.timezone);
    const realtimeNotification = useSelector((state) => state.realtimeNotification);
    React.useEffect(() => {
        if (realtimeNotification?.page === 1) dispatch(fetchNotificationList({ page: 1 }));
    }, []);

    const fetchMoreData = () => {
        dispatch(fetchNotificationListNextPage({ page: realtimeNotification.page }));
    };

    return (
        <>
            <Typography className="size16" variant="h3" style={{ marginBottom: 15 }}>
                Notifications
            </Typography>
            {realtimeNotification.notificationsIsLoading &&
            realtimeNotification?.notificationList.notification_list.length === 0 ? (
                <Grid style={{ height: "20vh" }} alignItems="center" container justifyContent="center">
                    <CircularProgress size={19} />
                </Grid>
            ) : (
                <InfiniteScroll
                    dataLength={
                        realtimeNotification?.notificationList?.notification_list?.length
                            ? realtimeNotification?.notificationList?.notification_list?.length
                            : 0
                    }
                    next={fetchMoreData}
                    hasMore={realtimeNotification?.notificationList?.has_more}
                    loader={
                        <Grid style={{ height: 25, overflow: "hidden" }} container justifyContent="center" alignItems="center">
                            <CircularProgress size={15} />
                        </Grid>
                    }
                    endMessage=""
                    scrollableTarget={props.containerId}
                >
                    {realtimeNotification?.notificationList?.notification_list?.map((notification, i) => (
                        <Grid key={i}>
                            <Grid key={i} container>
                                <Grid xs={1} item style={{ display: "flex", alignItems: "center" }}>
                                    {notification.notification_type === "success" ? (
                                        <CheckCircleOutlineIcon className={classes.infoIcon} />
                                    ) : (
                                        <CancelOutlinedIcon className={classes.cancelIcon} />
                                    )}
                                </Grid>
                                <Grid xs={11} item>
                                    {getMessageForNotification(notification)}
                                    <Typography variant="subtitle2">
                                        {moment(notification.created_at).tz(timezone).format("DD MMM YYYY") ===
                                        moment(new Date()).tz(timezone).format("DD MMM YYYY")
                                            ? moment(notification.created_at).tz(timezone).fromNow()
                                            : moment(notification.created_at).tz(timezone).format("DD MMM YYYY, HH:mm:ss")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr className={classes.hr} />
                        </Grid>
                    ))}
                </InfiniteScroll>
            )}
        </>
    );
};

export default NotificationList;
