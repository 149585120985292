import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import Config from "constants/config";
import { AccordionSummary } from "@mui/material";
class ExternalEntitiesEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `external-entites`;
        this.transformer = null;
    }
    getCredentials() {
        return axios.get(`external-entity-creds`);
    }
    getEntities() {
        return axios.get("external-entities");
    }
    deleteCredential(data) {
        return axios.delete(`external-entity-cred/${data.apiData.cred_id}`);
    }
}
export default ExternalEntitiesEndpoints;
