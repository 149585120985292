import endpointMapper from "./endpointMapper";

class ApiProvider {
	constructor(data) {
		this.data = data;
	}
	async makeRequest() {
		const endpoint = new endpointMapper[this.data.module](this.data);
		const res = await endpoint.makeRequest(this.data);
		return res;
	}
}

export default ApiProvider;
