import React from 'react'
import AnnouncementNewConnector from './MenuNewConnector'
import AnnouncementNewUpdates from './MenuNewUpdates'
import AnnouncementNewReverseConnector from './MenuNewReverseConnector'
import { Grid } from '@mui/material'
import MenuBlogs from './MenuBlogs'

const Announcements = (props) => {
    console.log(props.newConnectorUpdate)
    console.log(props.newUpdates)
    console.log(props.destinations)
    return (
        <Grid>
            <AnnouncementNewUpdates data={props.newUpdates} />
            <AnnouncementNewConnector data={props.newConnectorUpdate} />
            <AnnouncementNewReverseConnector data={props.destinations} />
            <MenuBlogs data={props.blogs} />
        </Grid>
    )
}

export default Announcements