import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import ScheduleTransormation from "../DataTransformations/ScheduleTransormation";
class ScheduleEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `schedule_options`;
        this.transformer = new ScheduleTransormation();
    }
    getSchedules(data) {
        console.log(data);
        return axios.get(`${this.resourceEndpoint}?module=${data.data.module}&module_id=${data.data.data}`);
    }
}

export default ScheduleEndpoints;
