import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
const useStyles = makeStyles((theme) => ({
    notificationContainer: {
        display: "flex",
        justifyContent: "center",
    },
    notification: {
        position: "fixed",
        bottom: "25px",
        background: "rgba(182, 189, 197, 0.9)",
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        zIndex: "9999",
        borderRadius: 8,
    },
    text: {
        textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        margin: "0 10px",
    },
    link: {
        textDecoration: "underline",
        color: "#616161",
    },
    button: {
        margin: 0,
    },
}));
/**
 *
 * @param {string} message
 * @param {link} redirect_url
 * @param {boolean} condition
 * @returns  An Alert on the Top of A Component If the Condition is true
 */
const AlertBanner = ({ message, redirect_url, condition, buttonText }) => {
    const classes = useStyles();
    if (!condition) return null;
    return (
        <div className={classes.notificationContainer}>
            <div className={classes.notification}>
                <ReportProblemOutlinedIcon />
                <Typography className={classes.text}>{message}</Typography>
                <a href={redirect_url}>
                    <Button color="primary" variant="contained" className={classes.button}>
                        {buttonText}
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default AlertBanner;
