import {
    SET_REVERSE_CONNECTORS,
    SET_REVERSE_CONNECTORS_IS_LOADING,
} from "../actionTypes/actionTypes";

const initialState = {
    reverseConnectors: [],
    reverseConnectorsIsLoading: false,
};

export const reverseConnectorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REVERSE_CONNECTORS:
            return {
                ...state,
                reverseConnectors: action.payload,
            };
        case SET_REVERSE_CONNECTORS_IS_LOADING:
            return {
                ...state,
                reverseConnectorsIsLoading: action.payload,
            };
        default:
            return state;
    }
};
