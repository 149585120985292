import { initializeApp } from "firebase/app";
import { getAuth,connectAuthEmulator } from "firebase/auth";
import config from "constants/config";

// const firebaseConfig = {
//     apiKey: "AIzaSyARx6DrUszYvguf9QsPDKUJ1Khzj-tnrto",
//     authDomain: "social-poc-6c9b2.firebaseapp.com",
//     projectId: "social-poc-6c9b2",
//     storageBucket: "social-poc-6c9b2.appspot.com",
//     messagingSenderId: "146654547030",
//     appId: "1:146654547030:web:2b9db17196025661bafff7",
//     measurementId: "G-YW7ZJX6T1D",
// };

// Initialize Firebase
const firebaseApp = initializeApp(config.firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseApp);
if(process.env.REACT_APP_ENV_TYPE == "local")
{
    connectAuthEmulator(auth, "http://localhost:9099");
}
export { auth, firebaseApp };
