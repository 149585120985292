class BaseApiTransformation {
    preApiCallTransformation(data) {
        return data;
    }
    postApiCallTransformation(data) {
        return data.data.data;
    }
}

export default BaseApiTransformation;
