import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class DBTCloudTransformationEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `transformations/dbt-cloud`;
        this.transformer = null;
    }
    getAll() {
        return axios.get(`transformations/dbt-cloud/pipelines`);
    }
    editCred(data) {
        return axios.patch(`${this.resourceEndpoint}/credentials/${data.apiData.credId}`, data.apiData.data);
    }
    getAllCreds() {
        return axios.get(`transformations/dbt-cloud/credentials`);
    }
    getAccounts(data) {
        return axios.get(`transformations/dbt-cloud/accounts?cred_id=${data.apiData.cred_id}`, {
            headers: {
                "api-key": data.apiData.apiKey,
            },
        });
    }
    getJobs(data) {
        return axios.get(`/transformations/dbt-cloud/accounts/${data.apiData.accountId}/jobs?cred_id=${data.apiData.cred_id}`, {
            headers: {
                "api-key": data.apiData.apiKey,
            },
        });
    }
    addProject(data) {
        return axios.post(`/transformations/dbt-cloud/pipeline`, data.apiData.data);
    }
    pipelineStatusChange(data) {
        return axios.patch(`/transformations/dbt_cloud/${data.apiData.dbtCloudTransformationId}/status`, data.apiData.data.data);
    }
    runProject(data) {
        return axios.post(`/transformations/dbt-cloud/pipeline/${data.apiData.dbtCloudTransformationId}/runs`);
    }
    deleteProject(data) {
        return axios.delete(`/transformations/dbt-cloud/pipeline/${data.apiData.dbtCloudTransformationId}`);
    }
    getOne(data) {
        console.log("adfasdfasdfasdf");
        return axios.get(`/transformations/dbt-cloud/pipelines/${data.apiData.dbtCloudTransformationId}`);
    }
    editDbtCloudProject(data) {
        return axios.patch(`/transformations/dbt-cloud/pipeline`, data.apiData.data);
    }

    saveCred(data) {
        return axios.post(`${this.resourceEndpoint}/credentials`, data.apiData.data, {
            headers: {
                "api-key": data.apiData.data.apiKey,
            },
        });
    }
    getAllRepos(data) {
        return axios.get(
            `${this.resourceEndpoint}/credentials/${data.apiData.cred_provider}/${data.apiData.cred_id}/repositories`
        );
    }
    getAllBranches(data) {
        return axios.get(
            `${this.resourceEndpoint}/credentials/${data.apiData.cred_provider}/${data.apiData.cred_id}/repositories/${data.apiData.repo_name}/branches?owner_name=${data.apiData.owner_name}`
        );
    }
    getAllModels(data) {
        return axios.get(
            `${this.resourceEndpoint}/credentials/${data.apiData.cred_provider}/${data.apiData.cred_id}/repositories/${data.apiData.repo_id}/branches/${data.apiData.branch_name}/models`
        );
    }
    createDbtProject(data) {
        return axios.post(`${this.resourceEndpoint}/projects`, data.apiData.data);
    }
    getChangeHistory(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.dbtCloudTransformationId}/change-history`);
    }
    editDbtProject(data) {
        return axios.patch(`${this.resourceEndpoint}/${data.apiData.dbtCloudTransformationId}`, data.apiData.data);
    }
    deleteTransformation(data) {
        return axios.delete(`${this.resourceEndpoint}/${data.apiData.dbtCloudTransformationId}`);
    }
    getLogText(data) {
        return axios.get(`${this.resourceEndpoint}/logs`, { headers: { "X-S3-File-Path": data.apiData.s3FilePath } });
    }
    runDbtProject(data) {
        return axios.post(`${this.resourceEndpoint}/${data.apiData.dbtCloudTransformationId}/runs`);
    }
    getDbtCloudRunLogGraph(data) {
        return axios.get(`${this.resourceEndpoint}/pipeline/${data.apiData.dbtCloudTransformationId}/run/logs/graph`);
    }
    runDbtCloudProject(data) {
        return axios.post(`${this.resourceEndpoint}/pipeline/${data.apiData.dbtCloudTransformationId}/runs`);
    }
    deleteDbtCloudCredential(data) {
        return axios.delete(`${this.resourceEndpoint}/credentials/${data.apiData.credId}`);
    }
}

export default DBTCloudTransformationEndpoints;
