import {
    SET_DATA_MODELS,
    SET_DATA_MODELS_IS_LOADING,
    SET_DATA_MODELS_LOGS_IS_LOADING,
    SET_DATA_MODELS_LOGS,
} from "../actionTypes/actionTypes";
// import { getTransformations } from "../../services/Api/api"
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchDataModels = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DATA_MODELS_IS_LOADING,
        SET_DATA_MODELS,
        {
            module: "dataModels",
            method: "getAll",
        },
        HttpErrorHandler
    );
};

const fetchDataModelsLogs = (data)=> async (dispatch)=>{
	apiWrapper(
		dispatch,
		SET_DATA_MODELS_LOGS_IS_LOADING,
		SET_DATA_MODELS_LOGS,
		{
			module:"dataModels",
			method:"getLogs",
			apiData: {
				dataModelId:data.dataModelId
			}
		},
		HttpErrorHandler
	)
}

export { fetchDataModels ,fetchDataModelsLogs};
