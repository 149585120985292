import ApiProvider from "services/Api/ApiProvider/ApiProvider";

export async function reduxApiWrapper(
    reduxDispatchArg,
    loaderActionType,
    setResponseActionType,
    apiData,
    errorHandler
    // getRequestParam = null
) {
    reduxDispatchArg({ type: loaderActionType, payload: true });
    try {
        const ApiService = new ApiProvider(apiData);
        const response = await ApiService.makeRequest();
        // const res = getRequestParam === null ? await apiToCall() : await apiToCall(getRequestParam);
        reduxDispatchArg({ type: setResponseActionType, payload: response });
        reduxDispatchArg({ type: loaderActionType, payload: false });
    } catch (e) {
        console.log(e);
        reduxDispatchArg({ type: loaderActionType, payload: false });
        if (errorHandler) errorHandler(reduxDispatchArg, e?.response?.data?.message);
        reduxDispatchArg({ type: setResponseActionType, payload: [] });
    }
}
