const moment = require("moment-timezone");

class ConfiguredSyncTransormation {
    getConfiguredSyncRunDataPostTransformer(data, endPointData) {
        const newArray = [];
        data.forEach((el) => {
            const created_at = moment(el.created_at).format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
            const tmp = {
                date: new Date(created_at).toUTCString(),
                time: moment(created_at).tz(endPointData.apiData.timezone).hour(),
                status: el?.status,
                rows_moved: el.rows_moved,
            };
            newArray.push(tmp);
        });
        console.log(newArray);
        return newArray;
    }
}

export default ConfiguredSyncTransormation;
