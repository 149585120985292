import React from "react";
import { ButtonBase, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import workspaceIcon from "assets/images/workspace/Workspace icon.svg";
import ExpandIcon from "assets/images/workspace/Workspace dropdown.svg";
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "200px",
        boxShadow: "0px 1px 10px 0px rgba(95, 95, 95, 0.16) inset",
        backgroundColor: "rgba(236, 236, 236, 1)",
        marginRight: 10,
        borderRadius: 10,
        maxWidth: "210px",
        padding: 0,
    },
}));
const WorkspaceSelector = ({ onClick }) => {
    const classes = useStyles();
    const workspaces = useSelector((state) => state.users?.user.workspaces);
    const currentWorkspaceId = useSelector((state) => state.users?.user.current_workspace_id);
    let currentWorkspace = workspaces?.filter((workspace) => workspace.workspace_id === currentWorkspaceId);
    if (currentWorkspace?.length > 0) {
        currentWorkspace = currentWorkspace[0];
    }
    return (
        <ButtonBase className={classes.root} onClick={onClick}>
            <Grid xs={12} container justifyContent="space-between" alignItems="center">
                <Grid xs={10} container flexDirection={"row"}>
                    <Grid xs={2} item>
                        <img src={workspaceIcon} style={{ marginLeft: 10 }} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography
                            style={{
                                fontSize: 16,
                                marginLeft: 16,
                                color: "rgba(21, 41, 69, 1)",
                                textOverflow: "ellipsis",
                                width: "80%",
                                textAlign: "left",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {" "}
                            {currentWorkspace?.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid xs={2} container item alignItems="center" style={{ height: "100%" }}>
                    <Grid item>
                        <img src={ExpandIcon} style={{ width: 32 }} />
                    </Grid>
                </Grid>
            </Grid>
        </ButtonBase>
    );
};

export default WorkspaceSelector;
