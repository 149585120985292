import {
    quickStartIcon,
    quickStartIconSelected,
    dashboardIcon,
    dashboardIconSelected,
    etlIcon,
    etlIconSelected,
    ReverseEtlIcon,
    ReverseEtlIconSelected,
    OrchestrationIcon,
    OrchestrationIconSelected,
    transformationsIcon,
    transformationsIconSelected,
    warehouseIcon,
    warehouseIconSelected,
    settingsIcon,
    settingsIconSelected,
    AskNeoIcon,
    AskNeoIconSelected,
} from "./Icons";

/**
 *  Array to display data in side Navbar with all the dependencies
 *
 * @constant NavMapper
 * @type {Object}
 *
 * @property {String} link - The link to the page
 * @property {Array} requiredFeature - The required feature to access the page
 * @property {Array} requiredPermission - The required permission to access the page
 * @property {String} tooltip - The tooltip to be displayed
 * @property {String} typography - The typography to be displayed
 * @property {Function} icon - The icon to be displayed
 * @property {Function} selectedIcon - The selected icon to be displayed
 *
 * @default
 */
const navData = [
    {
        // link: "/",
        requiredFeature: [],
        requiredPermission: ["report.view", "sync.view", "datawarehouse.view"],
        tooltip: "Quick Start",
        typography: "Quick Start",
        icon: quickStartIcon,
        selectedIcon: quickStartIconSelected,
    },
    {
        link: "/dashboard",
        requiredFeature: [],
        requiredPermission: ["dashboard.view"],
        typography: "Dashboard",
        tooltip: "Dashboard",
        icon: dashboardIcon,
        selectedIcon: dashboardIconSelected,
    },
    {
        requiredFeature: ["REVERSE"],
        requiredPermission: ["report.view"],
        tooltip: "ETL",
        typography: "ETL",
        icon: etlIcon,
        selectedIcon: etlIconSelected,
        featureType: "AddOn",
        featureName: "ETL",
    },
    {
        requiredFeature: ["REVERSE"],
        requiredPermission: ["sync.view"],
        tooltip: "Reverse ETL",
        typography: "Reverse ETL",
        icon: ReverseEtlIcon,
        selectedIcon: ReverseEtlIconSelected,
        featureType: "AddOn",
        featureName: "Reverse ETL",
    },
    {
        link: "/orchestrations",
        requiredFeature: [
            "DATAPIPELINE",
            "DATAWAREHOUSE",
            "TRANSFORMATION",
            "REVERSE",
            "DC_MANAGED_WAREHOUSE",
            "DBT",
            "ORCHESTRATION",
            "WORKSPACE",
        ],
        requiredPermission: ["orchestration.view"],
        tooltip: "Orchestrations",
        typography: "Orchestration",
        icon: OrchestrationIcon,
        selectedIcon: OrchestrationIconSelected,
        featureType: "Plan",
        featureName: "Orchestration",
    },
    {
        requiredFeature: ["TRANSFORMATION"],
        requiredPermission: [
            "sql_transformation.view",
            "sql_transformation.create",
            "sql_transformation.run",
            "sql_transformation.delete",
            "sql_transformation.edit",
        ],
        tooltip: "Transformations",
        typography: "Transformations",
        icon: transformationsIcon,
        selectedIcon: transformationsIconSelected,
        featureType: "Plan",
        featureName: "Pay As You Go",
    },
    {
        link: "/datawarehouse",
        requiredFeature: ["DATAWAREHOUSE", "DC_MANAGED_WAREHOUSE"],
        requiredPermission: ["datawarehouse.view", "datawarehouse.create", "datawarehouse.delete", "datawarehouse.edit"],
        tooltip: "Datawarehouse",
        typography: "Warehouses",
        icon: warehouseIcon,
        selectedIcon: warehouseIconSelected,
        featureType: "Plan",
        featureName: "Pay As You Go",
    },
    {
        // link: "/ask-neo/collections",
        requiredFeature: ["ASK_DC"],
        requiredPermission: [],
        tooltip: "Ask Neo",
        typography: "Ask Neo",
        icon: AskNeoIcon,
        selectedIcon: AskNeoIconSelected,
        featureType: "Plan",
        featureName: "Pay As You Go",
    },
    {
        requiredFeature: [],
        requiredPermission: ["settings.view"],
        tooltip: "Settings",
        typography: "Settings",
        icon: settingsIcon,
        selectedIcon: settingsIconSelected,
        account_level_permission: true,
    },
];

/**
 * Object to display data in side Navbar with all the dependencies
 *
 * @constant NavMapper
 * @type {Object}
 *
 * @property {Object} Quick Start - The object to display Quick Start
 * @property {Boolean} hasDropdown - The flag to show dropdown
 * @property {Function} icon - The icon to be displayed
 * @property {Array} data - The data to be displayed
 * @property {String} role - The role to access the dropdown like admin cant see the workspace management
 * @property {String} name - The name to be displayed
 * @property {String} subtitle - The subtitle to be displayed
 * @property {String} link - The link to the page
 * @property {Array} features - The features that are required to access the page
 */
const NavMapper = {
    "Quick Start": {
        hasDropdown: false,
        icon: quickStartIcon,
        data: [
            {
                name: "Quick Start- ETL",
                subtitle: "Move data from a Data Source to your Data Warehouse ",
                link: "/",
                features: ["REVERSE"],
            },
            {
                name: "Quick Start - Reverse ETL",
                subtitle: "Move data from  your Data Warehouse",
                link: "/?tab=reverseETL",
                features: ["REVERSE"],
            },
        ],
    },
    Transformations: {
        icon: transformationsIcon,
        hasDropdown: false,
        data: [
            {
                name: "dbt™",
                subtitle: "Create & Manage Transformations using dbt™",
                link: "/transformations?tab=dbt",
                features: ["DBT"],
                permission: ["dbt_transformation.view"],
            },
            {
                name: "SQL",
                subtitle: "Create & Manage Transformations using SQL",
                link: "/transformations",
                features: ["TRANSFORMATION"],
                permission: ["sql_transformation.view"],
            },
            {
                name: "DBT Cloud",
                subtitle: "Create & Manage Transformations using DBT Cloud",
                link: "/transformations?tab=dbtCloud",
                features: ["TRANSFORMATION"],
                permission: ["dbt_transformation.view"],
            },
        ],
    },
    ETL: {
        icon: etlIcon,
        hasDropdown: false,
        data: [
            {
                name: "My Connectors",
                subtitle: "View your configured Data Sources",
                link: "/datasource",
            },
            {
                name: "All Connectors",
                subtitle: "View all available connectors",
                link: "/datasource?tab=exploreConnectors",
            },
            {
                name: "Data Explorer",
                subtitle: "Preview data moved from your configured Data Sources",
                link: "/data-explorer",
            },
        ],
    },
    "Reverse ETL": {
        icon: ReverseEtlIcon,
        hasDropdown: false,
        data: [
            {
                name: "My Destinations",
                subtitle: "View & manage your configured Syncs",
                link: "/reverse-connectors",
                features: ["REVERSE"],
                permission: ["sync.view"],
            },
            {
                name: "All Destinations",
                subtitle: "View all available Destinations ",
                link: "/reverse-connectors?tab=explore_connectors",
                features: ["REVERSE"],
                permission: ["sync.view"],
            },
            {
                name: "Data Models",
                subtitle: "View & Create Data Models",
                link: "/data-models",
                features: ["REVERSE"],
                permission: ["sync.view"],
            },
            {
                name: "Segments",
                subtitle: "View & Create Customer Segments",
                link: "/segments",
                features: ["REVERSE"],
                permission: ["sync.view"],
            },
        ],
    },
    "Ask Neo": {
        icon: AskNeoIcon,
        hasDropdown: false,
        data: [
            {
                name: "Collections",
                subtitle: "View & manage your collections",
                link: "/ask-neo/collections",
                features: ["ASK_DC"],
                permission: ["collection.view"],
            },
            {
                name: "Playground",
                subtitle: "ask your queries here",
                link: "/ask-neo/playground",
                features: ["ASK_DC"],
                permission: ["thread.view"],
            },
        ],
    },
    Settings: {
        icon: settingsIcon,
        hasDropdown: true,
        data: {
            "Account Settings": [
                {
                    role: "none",
                    // feature: [],
                    permission: ["account_setting.view"],
                    account_level_permission: true,
                },
                {
                    name: "Profile",
                    subtitle: "Manage your profile settings",
                    link: "/settings?tab=account_settings&subTab=profile",
                },
                {
                    name: "Users",
                    subtitle: "Invite & manage your team members",
                    link: "/settings?tab=account_settings&subTab=users",
                    permission: ["user.view"],
                    account_level_permission: true,
                },
                {
                    name: "Roles",
                    subtitle: "Create and edit custom roles",
                    link: "/settings?tab=account_settings&subTab=roles",
                    permission: ["role.view"],
                    account_level_permission: true,
                },
                {
                    name: "Notifications",
                    subtitle: "Configure notifications ",
                    link: "/settings?tab=account_settings&subTab=notifications",
                    permission: ["notification.view"],
                },
                {
                    name: "Credential Management ",
                    subtitle: "Manage your all Data Source credentials",
                    link: "/settings?tab=account_settings&subTab=credential_management",
                },
                {
                    name: "Time Zone",
                    subtitle: "Select the time zone as per your requirement",
                    link: "/settings?tab=account_settings&subTab=timezone",
                },
            ],
            "API Management": [
                {
                    role: "none",
                    permission: ["api.view"],
                    account_level_permission: true,
                },
                {
                    name: "Key Management",
                    subtitle: "Generate API Key to access our Datachannel Management API",
                    link: "/settings?tab=api_keys&subTab=Keys_Management",
                    permission: ["api.view"],
                    account_permission: ["api.view"],
                    account_level_permission: true,
                },
                {
                    name: "Powered By Datachannel",
                    subtitle: "Api Key to access our Datachannel Management API",
                    link: "/settings?tab=api_keys&subTab=Powered_by_Datachannel",
                    features: ["POWERED_BY_DATACHANNEL"],
                    account_permission: ["api.view"],
                    permission: ["api.view"],
                    account_level_permission: true,
                },
            ],
            "Plans & Billing": [
                {
                    role: "none",
                    permission: ["subscription.view"],
                    account_level_permission: true,
                    selfServeOnly: true,
                    // feature: [],
                },
                {
                    name: "Plan & Subscription",
                    subtitle: "View & Manage your plans & add ons",
                    link: "/settings?tab=subscription_management&subTab=0",
                    account_permission: ["subscription.view"],
                    permission: ["subscription.view"],
                    account_level_permission: true,
                },
                {
                    name: "Billing & Payment Info",
                    subtitle: "View & Edit billing and payment info",
                    link: "/settings?tab=subscription_management&subTab=1",
                    account_permission: ["subscription.view"],
                    permission: ["subscription.view"],
                    account_level_permission: true,
                },
                {
                    name: "Invoices",
                    subtitle: "View & Manage your invoices ",
                    link: "/settings?tab=subscription_management&subTab=2",
                    account_permission: ["subscription.view"],
                    permission: ["subscription.view"],
                    account_level_permission: true,
                },
            ],
            Workspace: [
                {
                    role: "none",
                    features: ["WORKSPACE"],
                    account_permissions: ["workspace.view"],
                    permission: ["workspace.view"],
                    account_level_permission: true,
                },
                {
                    name: "Manage Workspaces",
                    subtitle: "Manage & Edit your workspaces",
                    link: "/settings?tab=workspaces",
                    features: ["WORKSPACE"],
                    account_permission: ["workspace.view"],
                    permission: ["workspace.view"],
                    account_level_permission: true,
                },
                {
                    name: "Add New Workspace",
                    subtitle: "Create New Workspace",
                    link: "/settings?tab=workspaces&open=true",
                    features: ["WORKSPACE"],
                    account_permission: ["workspace.create"],
                    permission: ["workspace.create"],
                    account_level_permission: true,
                },
            ],
        },
    },
};

export { navData, NavMapper };
