
export const Categories = [
    {
        label : "Global",
        events: [
            {
                label: "List Shortcuts",
                control: "ctrl+/",
                route: '',
            },
            {
                label : "Home",
                control: 'ctrl+h',
                route: '/',
            },
            {
                label: "Settings",
                control: "ctrl+i",
                route: '/settings'
            },
            {
                label: "Search Bar",
                control: "ctrl+shift+space",
                route: '',
            },
            {
                label : "Dashboard",
                control: 'ctrl+shift+b',
                route: '/dashboard',
            },
            {
                label : "Datasource",
                control: 'ctrl+shift+s',
                route: '/datasource',
            },
            {
                label : "DataWarehouse",
                control: 'ctrl+shift+a',
                route: '/datawarehouse',
            },
            {
                label : "Transformations",
                control: 'ctrl+shift+q',
                route: '/transformations',
            },
            {
                label : "Data Explorer",
                control: 'ctrl+shift+e',
                route: '/data-explorer',
            },
            {
                label : "Data Models",
                control: 'ctrl+shift+m',
                route: '/data-models',
            },
            {
                label : "Reverse ETL",
                control: 'ctrl+shift+d',
                route: '/reverse-connectors',
            },
            {
                label : "Segments",
                control: 'ctrl+shift+g',
                route: '/segments',
            },
        ]
    },
]


export const getShortcuts = () => {
    return Categories.reduce((shortCuts, category) => {
        const { events = [] } = category;
        return shortCuts.concat(events);
    }, []);
}
