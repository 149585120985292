import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, Tooltip } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import Config from "constants/config";
import DatasourceActionButtons from "components/common/ActionButtonGroups/DatasourceActionButtons";
import ConfiguredReportActionButtons from "components/common/ActionButtonGroups/ConfiguredReportActionButtons";
import TransformationActionButtons from "components/common/ActionButtonGroups/TransformationActionButtons";
import Image from "components/common/UIComponents/Image";
import { useNavigate } from "react-router";
import useFeatureFlag from "services/useFeatureFlag";
import RibbonMaterialCard from "components/common/Ribbon";
import Chip from "@mui/material/Chip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
    nested: {
        overflowWrap: "anywhere",
    },
    listImage: {
        height: 35,
        width: 35,
        borderRadius: 5,
    },
    headerContainer: {
        height: "15%",
        backgroundColor: "#f3f3f3",
    },
    list: {
        overflowY: "scroll",
        height: "85%",
    },
}));

/**
 *
 * @prop - data
 * @component {Receives data as prop and displays search results for "Datasources", "Data Pipelines" and "Transformations" in list form}
 */

const ForwardSearchResults = ({ data }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const hasReverse = useFeatureFlag("REVERSE");

    /**
     * function to filter out deprecated datasources
     * @param {*} datasources
     * @returns
     */
    const filterDs = (datasources) => {
        const filteredDatasource = datasources.filter((ds) => {
            if (ds.display_name?.split(" ").splice(-1)[0]?.toLowerCase() === "dep") {
                return false;
            }
            return true;
        });
        return filteredDatasource;
    };
    return (
        <>
            <Grid style={{ height: hasReverse ? "50%" : "100%" }} xs={4}>
                <ListItem className={classes.headerContainer} style={{ height: hasReverse ? "15%" : "8%" }}>
                    <ListItemText primary={<Typography variant="h6">Data Sources</Typography>} />
                </ListItem>
                <List className={classes.list} component="div" disablePadding>
                    {filterDs(data.datasources).map((el) => (
                        <ListItem
                            onClick={(e) => navigate(`/datasource/${el.slug}`)}
                            button
                            key={el.id}
                            className={classes.nested}
                            style={{ position: "relative" }}
                        >
                            <ListItemIcon>
                                <Image
                                    className={classes.listImage}
                                    src={`${Config.imageBaseUrl}images/connectors/${el.slug}.png`}
                                    alt="image"
                                    name={el.slug}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Grid item>
                                        <Typography variant="h6">{el.display_name}</Typography>
                                        {el?.status === "BETA" && (
                                            <Tooltip title="This connector is in BETA. Some reports may have limited functionality.">
                                                <Chip
                                                    size="small"
                                                    label="BETA"
                                                    avatar={<ErrorOutlineIcon style={{ color: "white" }} />}
                                                    style={{
                                                        backgroundColor: "#2E394B",
                                                        color: "white",
                                                        marginBottom: 5,
                                                        fontWeight: 500,
                                                        margin: "5px 0px",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Grid>
                                }
                                secondary={<DatasourceActionButtons fontSize={15} datasourceSlug={el.slug} />}
                            />
                        </ListItem>
                    ))}
                    {data.datasources.length === 0 ? (
                        <Grid style={{ height: "100%" }} alignItems="center" container justifyContent="center">
                            <Typography variant="h6">No Datasource Available</Typography>
                        </Grid>
                    ) : null}
                </List>
            </Grid>

            <Grid xs={4} style={{ height: hasReverse ? "50%" : "100%" }}>
                <ListItem className={classes.headerContainer} style={{ height: hasReverse ? "15%" : "8%" }}>
                    <ListItemText primary={<Typography variant="h6">Data Pipelines</Typography>} />
                </ListItem>
                <List className={classes.list} component="div" disablePadding>
                    {data.configured_reports.map((el) => (
                        <ListItem
                            onClick={(e) => navigate(`/datasource/${el.datasource_slug}/detail/editreport/${el.id}`)}
                            key={el.id}
                            button
                            className={classes.nested}
                        >
                            <ListItemIcon>
                                <Image
                                    className={classes.listImage}
                                    src={`${Config.imageBaseUrl}images/connectors/${el.datasource_slug}.png`}
                                    alt="image"
                                    name={el.datasource_slug}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="h6">{el.dataset_name}</Typography>}
                                secondary={
                                    <Grid style={{ marginTop: 5 }}>
                                        <ConfiguredReportActionButtons
                                            fontSize={15}
                                            datasourceSlug={el.datasource_slug}
                                            reportId={el.id}
                                        />
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))}
                    {data.configured_reports.length === 0 ? (
                        <Grid style={{ height: "100%" }} alignItems="center" container justifyContent="center">
                            <Typography variant="h6">No Data Pipelines Available</Typography>
                        </Grid>
                    ) : null}
                </List>
            </Grid>

            <Grid xs={4} style={{ height: hasReverse ? "50%" : "100%" }}>
                <ListItem className={classes.headerContainer} style={{ height: hasReverse ? "15%" : "8%" }}>
                    <ListItemText primary={<Typography variant="h6">Transformations</Typography>} />
                </ListItem>
                <List className={classes.list} component="div" disablePadding>
                    {data.transformations.map((el) => (
                        <ListItem
                            onClick={() => navigate(`/transformations/sql/edit/${el.id}`)}
                            key={el.id}
                            button
                            className={classes.nested}
                        >
                            <ListItemIcon>
                                <ShuffleIcon className={classes.listImage} />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="h6">{el.name}</Typography>}
                                secondary={
                                    <Grid style={{ marginTop: 5 }}>
                                        <TransformationActionButtons
                                            fontSize={15}
                                            datasourceSlug={el.datasource_slug}
                                            transformationId={el.id}
                                        />
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))}
                    {data.transformations.length === 0 ? (
                        <Grid style={{ height: "100%" }} alignItems="center" container justifyContent="center">
                            <Typography variant="h6">No Transformations Available</Typography>
                        </Grid>
                    ) : null}
                </List>
            </Grid>
        </>
    );
};

export default ForwardSearchResults;
