import { TOGGLE_SHOW_LOGS_BANNER } from "../actionTypes/actionTypes";

const initialState = {
    showLogsBanner: false,
    shownOnce: false,
};

export const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SHOW_LOGS_BANNER:
            return {
                ...state,
                showLogsBanner: action.payload,
                shownOnce: true,
            };
        default:
            return state;
    }
};
