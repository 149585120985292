import { Backdrop, Button, CircularProgress, Fade, Grid, Modal, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import tickCircle from 'assets/images/subscription/tickcircle.svg'
import { TOGGLE_UPGRADE_TO_PREMIUM_POPUP, TOGGLE_UPGRADED_TO_PREMIUM_TRIAL } from "redux/actionTypes/actionTypes";
import useApi from "hooks/useApi";
import starIcon from 'assets/images/onboarding/Star_icon_png.png'
import congratsIcon from 'assets/images/onboarding/Confettie_icon_png.png'
import config from "constants/config";
const useStyles = makeStyles((theme) => ({
    cross: {
        // margin: "15px 20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex:2000
    },
    paper: {
        height: '50vh',
        width: '53vw',
        display: "flex",
        backgroundColor: 'rgba(251, 251, 255, 1)',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4),
        borderRadius: "6px",
        position: "relative",
        overflow: 'scroll'
    },
    icon: {
        paddingRight: "36px",
    },
}));

function UpgradeToPremiumPopup(props) {
    const open = useSelector((state) => state.uiStates.showUpgradeToPremiumPopup)
    const [res, upgradeIsLoading, upgradeToPremiumTrial] = useApi()
    const classes = useStyles();
    const [upgraded, setUpgraded] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector((state) => state.users)
    const navigate = useNavigate()
    const handleClose = () => {
        dispatch({ type: TOGGLE_UPGRADE_TO_PREMIUM_POPUP, payload: false })
        window.location.href = `${config.frontendUrl}`
    };

    const upgradeTopermium = async () => {
        try {

            await upgradeToPremiumTrial({
                module: 'subscription',
                method: "upgradeToPremiumTrial",
                returnResult: true,
                throwError: true,
            })
            setUpgraded(true)
            dispatch({ type: TOGGLE_UPGRADED_TO_PREMIUM_TRIAL, payload: true })
        }
        catch (e) {

        }
    }

    return (
        <Modal className={classes.modal} open={open} onClose={handleClose} components={Backdrop} >
            <Fade in={open}>
                <div className={classes.paper}>

                    {!upgraded ? (<Grid container alignItems="center" justifyContent="center">

                        <Grid item>
                            <img src={starIcon} width={80} />
                        </Grid>
                        <Grid container item xs={12} style={{ height: '80%' }} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h5" align='center' style={{ fontSize: 22, color: 'rgba(40, 40, 118, 1)', fontWeight: 600 }}>
                                    Get full access to all the features  of DataChannel
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent="space-evenly">
                                <Grid item xs={5}>
                                    <Grid container xs={12} alignItems="center">
                                        <Grid item xs={1} style={{ marginRight: 5 }} container justifyContent='flex-start' > <img src={tickCircle} ></img></Grid>
                                        <Grid>
                                            <Typography variant="h5" align='center' style={{ fontSize: 15, color: 'rgba(40, 40, 118, 1)', fontWeight: 500 }}>
                                                Unlimited Data Sources
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container xs={12} alignItems="center">
                                        <Grid item xs={1} style={{ marginRight: 5 }} container justifyContent='flex-start' > <img src={tickCircle} ></img></Grid>
                                        <Grid>
                                            <Typography variant="h5" align='center' style={{ fontSize: 15, color: 'rgba(40, 40, 118, 1)', fontWeight: 500 }}>
                                                Unlimited Reverse ETL Syncs         </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container xs={12} alignItems="center">
                                        <Grid item xs={1} style={{ marginRight: 5 }} container justifyContent='flex-start' > <img src={tickCircle} ></img></Grid>
                                        <Grid>
                                            <Typography variant="h5" align='center' style={{ fontSize: 15, color: 'rgba(40, 40, 118, 1)', fontWeight: 500 }}>
                                                No limits on number of Rows
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container xs={12} alignItems="center">
                                        <Grid item xs={1} style={{ marginRight: 5 }} container justifyContent='flex-start' > <img src={tickCircle} ></img></Grid>
                                        <Grid>
                                            <Typography variant="h5" align='center' style={{ fontSize: 15, color: 'rgba(40, 40, 118, 1)', fontWeight: 500 }}>
                                                Migrate all your historical data for free
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container xs={12} alignItems="center">
                                        <Grid item xs={1} style={{ marginRight: 5 }} container justifyContent='flex-start' > <img src={tickCircle} ></img></Grid>
                                        <Grid>
                                            <Typography variant="h5" align='center' style={{ fontSize: 15, color: 'rgba(40, 40, 118, 1)', fontWeight: 500 }}>
                                                Explore the full power of dbt<sup>TM</sup>

                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container xs={12} alignItems="center">
                                        <Grid item xs={1} style={{ marginRight: 5 }} container justifyContent='flex-start' > <img src={tickCircle} ></img></Grid>
                                        <Grid>
                                            <Typography variant="h5" align='center' style={{ fontSize: 15, color: 'rgba(40, 40, 118, 1)', fontWeight: 500 }}>
                                                Get priority  assistance
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" xs={12}>
                                <Grid item xs={4}>

                                    <Button variant="contained" color="primary" style={{ borderRadius: 30 }} fullWidth onClick={upgradeTopermium}>Start My Trial Now
                                        {upgradeIsLoading && < CircularProgress size={16} style={{ marginLeft: 10, color: 'white' }} />}</Button>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>) : (
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item style={{ position: 'relative', top: 20, zIndex: 50 }}>
                                <img src={congratsIcon} width={100} />
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" style={{ backgroundColor: "rgba(255, 106, 57, 1)", padding: 20, borderRadius: 5, marginBottom: 20 }}>
                                <Grid item  >
                                    <Typography variant="h5" align='center' style={{ fontSize: 16, color: 'white', fontWeight: 500 }}>
                                        Congratulations! Your 14 days full feature free trial has started.
                                    </Typography>
                                    <Typography variant="h5" align='center' style={{ fontSize: 14, color: 'white', fontWeight: 500, marginTop: 10 }}>
                                        You can upgrade to a paid plan anytime during the trial.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} justifyContent="space-evenly">
                                <Grid item xs={3}>
                                    <Button variant="outlined" color="primary" style={{ borderRadius: 30 }} fullWidth onClick={() => (window.open('https://calendly.com/datachannel-support/30min'))}>Contact Support</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" style={{ borderRadius: 30 }} fullWidth onClick={() => (window.open('https://calendly.com/datachannelsales/15min'))}>Contact Sales</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" style={{ borderRadius: 30 }} fullWidth onClick={handleClose}>Close</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    }
                    <CloseIcon
                        className="hoverable"
                        style={{ position: "absolute", margin: 12, top: 0, right: 0, color: 'rgba(253, 149, 103, 1)' }}
                        onClick={() => handleClose()}
                        fontSize="small"
                    />

                </div>
            </Fade>
        </Modal>
    );
}

export default UpgradeToPremiumPopup;
