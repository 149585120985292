import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createMigrate } from "redux-persist";

const migrations = {
    0: (state) => {
        delete state["dashboard"];
        return state;
    },
    4: (state) => {
        return {};
    },
    5: (state) => {
        return {};
    },
    6: (state) => {
        return {};
    },
    7: (state) => {
        return {};
    },
    8: (state) => {
        delete state["users"];
        return state;
    },
    9: (state) => {
        return {};
    },
    10: (state) => {
        return {};
    },
};

const persistConfig = {
    key: "root",
    version: 10,
    storage,
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: ["notification", "realtimeNotification", "users", "uiStates", "homepage", "orchestration"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(Store);
