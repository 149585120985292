import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import Config from "constants/config";
import Image from "components/common/UIComponents/Image";
import ReverseConnectorActionButtons from "components/common/ActionButtonGroups/ReverseConnectorActionButtons";
import DataModelActionButtons from "components/common/ActionButtonGroups/DataModelActionButtons";
import ConfiguredSyncsActionButtons from "components/common/ActionButtonGroups/ConfiguredSyncsActionButtons";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
    nested: {
        overflowWrap: "anywhere",
    },
    listImage: {
        height: 35,
        width: 35,
        borderRadius: 5,
    },
    headerContainer: {
        height: "15%",
        backgroundColor: "#f3f3f3",
    },
    list: {
        overflowY: "scroll",
        height: "85%",
    },
}));

/**
 *
 * @prop - data
 * @component {Receives data as prop and displays search results for "Reverse Connectors", "Configured Syncs" and "Data Models" in list form}
 */

const ReverseSearchResults = ({ data }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    return <>
        <Grid style={{ height: "50%" }} xs={4}>
            <ListItem className={classes.headerContainer}>
                <ListItemText primary={<Typography variant="h6">Reverse Connectors</Typography>} />
            </ListItem>
            <List className={classes.list} component="div" disablePadding>
                {data.reverse_connectors.map((el) => (
                    <ListItem
                        onClick={(e) => navigate(`/reverse-connectors/${el.slug}`)}
                        button
                        key={el.id}
                        className={classes.nested}
                    >
                        <ListItemIcon>
                            <Image
                                className={classes.listImage}
                                src={`${Config.imageBaseUrl}images/connectors/${el.datasource_slug}.png`}
                                alt="image"
                                name={el.slug}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="h6">{el.display_label}</Typography>}
                            secondary={<ReverseConnectorActionButtons fontSize={15} reverseConnectorSlug={el.slug} />}
                        />
                    </ListItem>
                ))}
                {data.reverse_connectors.length === 0 ? (
                    <Grid style={{ height: "100%" }} alignItems="center" container justifyContent="center">
                        <Typography variant="h6">No Reverse Connectors Available</Typography>
                    </Grid>
                ) : null}
            </List>
        </Grid>

        <Grid xs={4} style={{ height: "50%" }}>
            <ListItem className={classes.headerContainer}>
                <ListItemText primary={<Typography variant="h6">Configured Syncs</Typography>} />
            </ListItem>
            <List className={classes.list} component="div" disablePadding>
                {data.configured_syncs.map((el) => (
                    <ListItem
                        onClick={(e) => navigate(`/reverse-connectors/${el.reverse_connector_slug}/sync/${el.id}`)}
                        key={el.id}
                        button
                        className={classes.nested}
                    >
                        <ListItemIcon>
                            <Image
                                className={classes.listImage}
                                src={`${Config.imageBaseUrl}images/connectors/${el.datasource_slug}.png`}
                                alt="image"
                                name={el.datasource_slug}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="h6">{el.label}</Typography>}
                            secondary={
                                <Grid style={{ marginTop: 5 }}>
                                    <ConfiguredSyncsActionButtons
                                        fontSize={15}
                                        reverseConnectorSlug={el.reverse_connector_slug}
                                        configuredSyncId={el.id}
                                    />
                                </Grid>
                            }
                        />
                    </ListItem>
                ))}
                {data.configured_syncs.length === 0 ? (
                    <Grid style={{ height: "100%" }} alignItems="center" container justifyContent="center">
                        <Typography variant="h6">No Configured Syncs Available</Typography>
                    </Grid>
                ) : null}
            </List>
        </Grid>

        <Grid xs={4} style={{ height: "50%" }}>
            <ListItem className={classes.headerContainer}>
                <ListItemText primary={<Typography variant="h6">Data Models</Typography>} />
            </ListItem>
            <List className={classes.list} component="div" disablePadding>
                {data.data_models.map((el) => (
                    <ListItem
                        onClick={() => navigate(`/data-models/edit/${el.id}`)}
                        key={el.id}
                        button
                        className={classes.nested}
                    >
                        <ListItemIcon>
                            <ShuffleIcon className={classes.listImage} />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="h6">{el.label}</Typography>}
                            secondary={
                                <Grid style={{ marginTop: 5 }}>
                                    <DataModelActionButtons
                                        fontSize={15}
                                        dataModelId={el.id}
                                        no_of_configured_syncs={data.configured_syncs.length}
                                    />
                                </Grid>
                            }
                        />
                    </ListItem>
                ))}
                {data.data_models.length === 0 ? (
                    <Grid style={{ height: "100%" }} alignItems="center" container justifyContent="center">
                        <Typography variant="h6">No Data Models Available</Typography>
                    </Grid>
                ) : null}
            </List>
        </Grid>
    </>;
};

export default ReverseSearchResults;
