import React from 'react'
import { Grid, IconButton, Tooltip, Typography, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const useStyles = makeStyles((theme) => ({
    container: {
        '& p': {
            margin: 0,
            fontFamily: 'Montserrat',
            fontSize: 11,
            fontWeight: 400
        },
        '& li': {
            margin: 0,
            fontFamily: 'Montserrat',
            fontSize: 11,
            fontWeight: 400
        },
        backgroundColor: '#FAFBFF',
        borderRadius: '10px',
        padding: 15,
        marginTop: 15,
    },

    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },


}));
const MenuBlogs = (props) => {
    const classes = useStyles()
    console.log(props.data)
    if (!props.data?.items?.length > 0) {
        return null
    }
    return (
        <Grid container item xs={12} className={classes.container}>
            <Grid container item justifyContent='space-between' alignItems='center' spacing={2} style={{ marginBottom: 5, marginTop: 5 }} >

                <Grid item>
                    <Typography style={{ color: '#627082', fontFamily: 'Montserrat', fontSize: 10, fontWeight: 500, padding: 0 }}>{moment(props.data.items[0]['published-on'] ? props.data.items[0]['published-on'] : props.data.items[0]['created-on']).format('MMM DD,YYYY')}</Typography>
                </Grid>
                <Grid item >
                    <Typography style={{ backgroundColor: '#DAA06D', color: '#800020', fontFamily: 'Montserrat', fontSize: 10, fontWeight: 600, padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 15 }} >BLOGS</Typography>
                </Grid>

            </Grid>
            <Grid container item xs={12}>
                {props.data.items.map((item) => {
                    return <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <Grid item container xs={12} justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10, marginTop: 5 }}>
                            <Typography variant='h5' style={{ fontSize: 13, fontWeight: 500, marginRight: 10, fontFamily: 'Montserrat', color: '#13253F', lineHeight: 2 }}>{item.name}</Typography>
                            {/* <Tooltip title="Read DC documentaion"><IconButton onClick={() => window.open(item['dc-documentation-url'])}> <LinkIcon /></IconButton></Tooltip> */}

                            {/* {item['connector-logo'] && <img src={item['connector-logo']['url']} alt={item.name} style={{ width: 150 }} />} */}
                        </Grid>
                        <Grid style={{ padding: 0, paddingLeft: 10 }}>
                            <Grid style={{ marginBottom: 5 }} container justifyContent='flex-start' xs={12}>
                                <Grid item>
                                {item?.blog_tag_item?.length>0&&  <Chip label={item?.blog_tag_item[0]?.name} style={{ backgroundColor: item?.blog_tag_item[0]['background-color'], }} />}
                                </Grid>

                                <Grid container item justifyContent='flex-start' style={{ marginLeft: 15 }} xs={4} alignItems='center'><AccessTimeIcon></AccessTimeIcon><Typography style={{ fontSize: 10, marginLeft: 10 }}>{item['time-to-read']}</Typography></Grid>
                            </Grid>

                            <Grid style={{ marginTop: 10 }}>{item['card-text'] && (<>
                                <p>{item['card-text']}</p></>)
                            }

                            </Grid>
                            <Grid >
                                <a href={`https://www.datachannel.co/blogs/${item.slug}`} target='_blank'>Explore more</a>
                            </Grid>
                        </Grid>
                    </Grid>
                })}
            </Grid >
        </Grid >
    )
}


export default MenuBlogs;