import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useNavigate } from "react-router";
import DCCompleteLogo from "assets/DC_complete_logo.png";
import sentEmailSVG from "assets/images/loginPage/sentEmail.svg";
import bgImage from "assets/images/loginPage/BackgroundSVG.svg";
import useApi from "hooks/useApi";

const useStyle = makeStyles((theme) => {
    return {
        logoContainer: {
            marginBottom: 20,
        },
        root: {
            marginLeft: 0,
            marginBottom: 60,
            [theme.breakpoints.up("md")]: {
                marginLeft: 100,
                marginBottom: 60,
            },
        },
        box: {
            backgroundColor: "#ffffff",
            boxShadow:
                "0px 7px 89px rgba(20, 12, 112, 0.08), 0px 1.56354px 19.8793px rgba(20, 12, 112, 0.0476886), 0px 0.465507px 5.91859px rgba(20, 12, 112, 0.0323114)",
            borderRadius: 32,
            padding: "30px 60px",
            [theme.breakpoints.down('xs')]: {
                padding: "30px 30px",
            },
        },
        logoContainer: {
            marginBottom: 30,
        },
        bgRadial: {
            height: "80vh",
            [theme.breakpoints.up("sm")]: {
                backgroundImage: `url(${bgImage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "100vh",
            },
        },
    };
});
const LinkSent = (props) => {
    const classes = useStyle();
    const navigate = useNavigate();
    const [response, isLoading, register] = useApi();
    const onResendLink = async () => {
        try {
            await register({
                module: "auth",
                method: "register",
                successToast: "Email Sent",
                apiData: {
                    data: { email: props.email },
                },
                returnResult: true,
                throwError: true,
            });
        } catch (e) {}
        //comment
    };
    return (
        <Grid
            container
            xs={12}
            justifyContent="center"
            // style={{ height: "60vh" }}
            spacing={0}
            direction="row"
            alignItems="center"
            className={classes.bgRadial}
        >
            <Grid item xs={12} container justifyContent="center" style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Grid container xs={12} item className={classes.logoContainer} justifyContent="center">
                    <Grid item>
                        <img src={DCCompleteLogo} style={{ height: 40 }} alt="DataChannel Logo"></img>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={5} className={classes.box}>
                    {/* <Grid item container xs={12} justifyContent="center">
                        <img src={LinkSentPNG} />
                    </Grid> */}
                    <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                        <Grid>
                            <Typography
                                style={{ fontSize: 20, fontFamily: "Plus Jakarta Sans", color: "#282876", fontWeight: 600 }}
                            >
                                Verify your account
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                style={{ fontSize: 14, fontFamily: "Plus Jakarta Sans", color: "#8181AC", fontWeight: 500 }}
                            >
                                Step 1/2
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Typography style={{ fontSize: 14, fontFamily: "Plus Jakarta Sans", color: "#8181AC", fontWeight: 500 }}>
                            You are just a step away from our full feature 14 day trial. An account activation link has been sent
                            to your e-mail.
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                        <Grid item>
                            <img src={sentEmailSVG} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Typography style={{ fontSize: 14, fontFamily: "Plus Jakarta Sans", color: "#8181AC", fontWeight: 500 }}>
                            Please be sure to check the spam folder as well, depending on your spam settings the mail may end up
                            there.
                        </Typography>
                    </Grid>
                    {/* To be Implemented Later */}

                    <Grid item xs={12} style={{ marginTop: 15 }} container justifyContent="center">
                        <Typography style={{ fontSize: 14, fontFamily: "Plus Jakarta Sans", color: "#8181AC", fontWeight: 500 }}>
                            Didn’t receive it ?{" "}
                            {isLoading ? (
                                <span
                                    onClick={onResendLink}
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Plus Jakarta Sans",
                                        color: "#8181AC",
                                        fontWeight: 500,
                                        cursor: "pointer",
                                    }}
                                >
                                    Send again <CircularProgress size={12} style={{ color: "#4E3EFF", marginLeft: 5 }} />
                                </span>
                            ) : (
                                <span
                                    onClick={onResendLink}
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "Plus Jakarta Sans",
                                        color: "#4A3AFF",
                                        fontWeight: 500,
                                        cursor: "pointer",
                                    }}
                                >
                                    Send again{" "}
                                </span>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 0 }} container justifyContent="center">
                        <Grid item sm={10} xs={12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => navigate("/login")}
                                style={{ borderRadius: "40px", backgroundColor: "#4E3EFF" }}
                            >
                                Go back to Log in
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LinkSent;
