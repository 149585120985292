import {
    SET_DATA_MODELS,
    SET_DATA_MODELS_IS_LOADING,
    SET_DATA_MODELS_LOGS,
    SET_DATA_MODELS_LOGS_IS_LOADING
} from "../actionTypes/actionTypes";

const initialState = {
    dataModels: [],
    dataModelsIsLoading: false,
    dataModelsLogs:[],
    isDataModelsLogsLoading:false,
};

export const dataModelReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_MODELS:
            return {
                ...state,
                dataModels: action.payload,
            };
        case SET_DATA_MODELS_IS_LOADING:
            return {
                ...state,
                dataModelsIsLoading: action.payload,
            };
        case SET_DATA_MODELS_LOGS:
                console.log(action.payload)
                return {
                    ...state,
                    dataModelsLogs:action.payload
                }
        case SET_DATA_MODELS_LOGS_IS_LOADING:
                console.log(action.payload)
                return{
                    ...state,
                    isDataModelsLogsLoading:action.payload
                }
        default:
            return state;
    }
};
