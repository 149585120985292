import {
    ADD_GLOBAL_NOTIFICATION,
    EMPTY_NOTIFICATIONS,
    TOGGLE_SIDENAV,
    MODAL_IS_OPEN,
    IS_ONBOARDING,
    TOGGLE_RESTRICTED_FEATURE_POPUP,
    TOGGLE_UPGRADE_TO_PREMIUM_POPUP,
    TOGGLE_RESTRICT_POPUP,
    TOGGLE_BANNER,
} from "../actionTypes/actionTypes";

const initialState = {
    globalNotifications: [],
    isSideNavOpen: true,
    showLogsConnectorBanner: false,
    hasShownLogsConenctorBanner: false,
    modalIsOpen: false,
    isOnboarding: false,
    restrictedFeaturePopup: null,
    restrictPopup: null,
    showUpgradeToPremiumPopup: false,
    showBanner: true,
};

export const uiStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_GLOBAL_NOTIFICATION:
            if (state.globalNotifications.filter((el) => el.message === action.message).length > 0) {
                return state;
            }
            return {
                ...state,
                globalNotifications: [action.payload, ...state.globalNotifications],
            };
        case EMPTY_NOTIFICATIONS:
            return {
                ...state,
                globalNotifications: [],
            };
        case TOGGLE_SIDENAV:
            if (action?.payload !== undefined) {
                return {
                    ...state,
                    isSideNavOpen: action.payload,
                };
            }
            return {
                ...state,
                isSideNavOpen: !state.isSideNavOpen,
            };
        case MODAL_IS_OPEN:
            return {
                ...state,
                modalIsOpen: action.payload,
            };
        case IS_ONBOARDING:
            return {
                ...state,
                isOnboarding: action.payload,
            };

        case TOGGLE_RESTRICTED_FEATURE_POPUP:
            return {
                ...state,
                restrictedFeaturePopup: action.payload,
            };

        case TOGGLE_RESTRICT_POPUP:
            return {
                ...state,
                restrictPopup: action.payload,
            };
        case TOGGLE_UPGRADE_TO_PREMIUM_POPUP:
            return {
                ...state,
                showUpgradeToPremiumPopup: action.payload,
            };
        case TOGGLE_BANNER:
            return {
                ...state,
                showBanner: !state.showBanner,
            };
        default:
            return state;
    }
};
