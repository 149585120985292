// import { getDataSourcesList } from "../../services/Api/api";
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";

import { DATASOURCE_LIST_LOADED, SET_IS_LOADING } from "../actionTypes/actionTypes";

// import { getAPIWrapper } from "../../utils/api-try-catch-wrapper/get-api-wrapper";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchDatasourceList = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_IS_LOADING,
        DATASOURCE_LIST_LOADED,
        {
            module: "datasource",
            method: "getConfiguredDatasources",
        },
        HttpErrorHandler
    );
};

export { fetchDatasourceList };
