import { trackPageView, newTracker, setUserId, trackSelfDescribingEvent } from "@snowplow/browser-tracker";
import config from "constants/config";
class EventTracker {
    static init = (app_id) => {
        newTracker("DataChannelFrontendV2Tracker", config.eventCollectorUrl, {
            appId: app_id,
            postPath: "",
            post: true,
            withCredentials: false,
            encodeBase64: false,
            customHeaders: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            },
        });
    };

    static trackPageView = () => {
        trackPageView();
    };

    static setUserId = (user_id) => {
        setUserId(user_id);
    };

    static trackSelfDescribingEvent = (event_name, event_data, user_id, account_id) => {
        const data = this.getSelfDescribingEventData(event_data, user_id, account_id, event_name);
        trackSelfDescribingEvent({
            event: {
                data,
            },
        });
    };

    static getSelfDescribingEventData = (data, user_id, account_id, event_name) => {
        return {
            ...data,
            user_id,
            account_id,
            event_name,
        };
    };
}

export default EventTracker;
