import { Grid, Tooltip, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import moment from "moment";
import LaunchIcon from "@mui/icons-material/Launch";
// import { set } from "js-cookie";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        width: "85%",
        // height: "fit-content",
        borderRadius: 10,
        backgroundColor: "#D9D9D933",
        // padding: "0 0 12px 0",
        // gap: "5px",
    },
    barWrapper: {
        margin: "5px 0",
        width: "130px",
    },
    header: {
        textAlign: "left",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "15px",
        cursor: "pointer",
    },
    heading: {
        fontSize: 14,
        fontWeight: 700,
        color: "#2c2c2c",
        textAlign: "left",
        width: "100%",
    },
    container: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "0 10px",
        marginBottom: "2px",
    },
    subHeading: {
        alignItem: "center",
        fontSize: "10px",
        color: "#2E394B",
        fontWeight: "500",
    },
}));

const BigPanel = ({
    classes,
    plan_name,
    subscription_status,
    isRowUsed,
    diffTimeFixed,
    diffTime,
    hourDiff,
    current_term_end_at,
    user,
    isHover,
    linkTextDyn,
}) => {
    const navigate = useNavigate();

    const handleOnclick = () => {
        if (user.user.account_role === "Super-Admin") {
            if (isRowUsed && plan_name !== "Unlimited") {
                navigate("/settings?tab=subscription_management&subTab=0&open_plan=true");
            }
            return null;
        }
    };

    return (
        <div
            className={classes.root}
            style={{
                backgroundColor: isHover ? "rgba(217, 217, 217, 0.3)" : "#D9D9D933",
                padding: isHover ? "10px 0 10px 0" : "0 0 0.6rem 0",
                width: isHover ? "170px" : "85%",
            }}
        >
            <div
                style={{
                    width: "100%",
                }}
            >
                {plan_name !== "Unlimited" ? (
                    <div className={classes.header} onClick={handleOnclick}>
                        <Typography variant="h6" className={classes.heading}>
                            {/* {user.user.plan_name} */}
                            {subscription_status === "trial"
                                ? "Free Trial"
                                : plan_name === "Unlimited"
                                ? "Professional"
                                : plan_name}
                        </Typography>
                        <LaunchIcon
                            fontSize="10px"
                            style={{
                                color: "#FD9567",
                            }}
                        />
                    </div>
                ) : (
                    <div className={classes.header} onClick={handleOnclick}>
                        <Typography variant="h6" className={classes.heading}>
                            {/* {user.user.plan_name} */}
                            {subscription_status === "trial"
                                ? "Free Trial"
                                : plan_name === "Unlimited"
                                ? "Professional"
                                : plan_name}
                        </Typography>
                        <LaunchIcon
                            fontSize="10px"
                            style={{
                                color: "#FD9567",
                            }}
                        />
                    </div>
                )}
                {subscription_status === "trial" && (
                    <Typography variant="h6" style={{ fontSize: "10px", color: "red", textAlign: "center", marginBottom: 5 }}>
                        {diffTimeFixed < 1
                            ? `${hourDiff} hours`
                            : `${diffTime}
            ${diffTimeFixed > 1 ? " days left" : " day left"}`}
                    </Typography>
                )}
            </div>
            {isRowUsed && plan_name !== "Unlimited" && (
                <>
                    <div
                        style={{
                            width: "90%",
                        }}
                    >
                        <Grid container alignItems="center" justifyContent="center" width="100%" rowGap="0">
                            {/* <Typography
                                style={{
                                    textAlign: "center",
                                    padding: "0.4rem 0.3rem",
                                    backgroundColor: "#FFFFFF",
                                    alignItem: "center",
                                    width: "100%",
                                    fontSize: "12px",
                                    color: "#2C2C2C",
                                    fontWeight: "500",
                                    borderRadius: "3px",
                                }}
                            >
                                Rows Moved - {(user.user.rows_moved_current_workspace / 1000000).toFixed(1)}M
                            </Typography> */}

                            {!isHover && user.user.account_role === "Super-Admin" && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/settings?tab=subscription_management&subTab=0&open_plan=true")}
                                    style={{
                                        textAlign: "center",
                                        padding: "0.4rem 0.3rem",
                                        marginBottom: "0",
                                        marginTop: "0.5rem",
                                        alignItem: "center",
                                        width: "100%",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        borderRadius: "3px",
                                    }}
                                >
                                    {linkTextDyn}
                                </Button>
                            )}
                        </Grid>
                    </div>
                </>
            )}
            {isRowUsed && plan_name === "Unlimited" && (
                <Grid container alignItems="center" justifyContent="center" width="90%">
                    {/* <Typography
                        style={{
                            textAlign: "center",
                            padding: "0.4rem 0.3rem",
                            backgroundColor: "#FFFFFF",
                            alignItem: "center",
                            width: "100%",
                            fontSize: "12px",
                            color: "#2C2C2C",
                            fontWeight: "500",
                            borderRadius: "3px",
                        }}
                    >
                        Rows Moved - {(user.user.rows_moved_current_workspace / 1000000).toFixed(1)}M
                    </Typography> */}
                </Grid>
            )}
        </div>
    );
};

/**
 * PlanMenu component displays the plan details
 * @component
 *
 * @param {string} planName - Plan name
 * @param {boolean} isRowUsed - If row usage is to be shown
 * @param {string} linkText - Text to be shown in the link
 * @param {boolean} isTrial - If the user is in trial
 * @param {boolean} isLive - If the user is live
 * @param {boolean} isHover - If the user is hovering
 * @returns {React.Component} - PlanMenu component
 * @example
 * <PlanMenu planName="Free Plan" isRowUsed={true} linkText="Explore Plans" isTrial={true} isLive={false} isHover={false} />
 *
 */
const PlanMenu = ({ planName, isRowUsed, linkText, isTrial, isLive, isHover, isOpen }) => {
    const classes = useStyles();
    const user = useSelector((state) => state.users);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffTimeFixed = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / oneDay).toFixed(1);
    const diffTime = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / oneDay).toFixed(0);

    const [onHover, setOnHover] = React.useState(false);

    let hourDiff;
    if (diffTimeFixed < 1) {
        hourDiff = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / (1000 * 60 * 60)).toFixed(0);
    }
    const subscription_status = useSelector((state) => state.users?.user?.subscription_status);
    const plan_name = useSelector((state) => state.users?.user?.plan_name);
    const current_term_end_at = useSelector((state) => state.users?.user.current_term_end_at);

    let linkTextDyn = "";
    if (subscription_status === "trial") {
        linkTextDyn = "Explore Plans";
    } else {
        if (plan_name === "Free Plan") {
            linkTextDyn = "Upgrade";
        } else {
            linkTextDyn = "Explore Plans";
        }
    }

    return (
        <div>
            {isOpen ? (
                <BigPanel
                    classes={classes}
                    plan_name={plan_name}
                    subscription_status={subscription_status}
                    isRowUsed={isRowUsed}
                    diffTimeFixed={diffTimeFixed}
                    diffTime={diffTime}
                    hourDiff={hourDiff}
                    current_term_end_at={current_term_end_at}
                    user={user}
                    linkTextDyn={linkTextDyn}
                />
            ) : (
                <div
                    // onMouseOver={() => setOnHover(true)}
                    onMouseEnter={() => setOnHover(true)}
                    onMouseLeave={() => setOnHover(false)}
                >
                    {/* <Typography
                        style={{
                            textAlign: "center",
                            padding: "1rem 0.2rem",
                            backgroundColor: "#D9D9D933",
                            width: "80%",
                            margin: "auto",
                            fontSize: "12px",
                            color: "#2C2C2C",
                            fontWeight: "500",
                            borderRadius: "5px",
                        }}
                    >
                        {(user.user.rows_moved_current_workspace / 1000000).toFixed(0)}M
                    </Typography> */}
                </div>
            )}

            {onHover ? (
                <div
                    style={{
                        position: "absolute",
                        top: "-100%",
                        left: "105%",
                        zIndex: 100,
                        borderRadius: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.69)",
                        border: "2px solid #D9D9D9",
                        boxShadow: "0px 2px 6.599999904632568px 0px #0000001A",
                    }}
                >
                    <BigPanel
                        classes={classes}
                        plan_name={plan_name}
                        subscription_status={subscription_status}
                        isRowUsed={isRowUsed}
                        diffTimeFixed={diffTimeFixed}
                        diffTime={diffTime}
                        hourDiff={hourDiff}
                        current_term_end_at={current_term_end_at}
                        user={user}
                        linkTextDyn={linkTextDyn}
                        isHover={true}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default PlanMenu;
