import React from "react";
import { Popover, Grid, Typography, Button, ButtonBase, TextField, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import axios from "axios";
import useApi from "hooks/useApi";
import { getAuth } from "firebase/auth";
import Cookies from "js-cookie";
import config from "constants/config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import EditWorkspaceIcon from "assets/images/workspace/Manage workspace.svg";
import ManageUserIcon from "assets/images/workspace/Manage User.svg";
import SwitchIcon from "assets/images/workspace/Workspace switcher.svg";
import { checkForFeature } from "services/featureFlagHOC";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const useStyle = makeStyles((theme) => ({
    root: {
        width: "350px",
        // minHeight: '200px',
        maxHeight: 400,
        // overflowY: "scroll",
    },
    current: {
        backgroundColor: "rgba(255, 246, 239, 1)",
    },
    customContent: {
        margin: "6px",
        marginRight: 0,
    },
    input: {
        margin: 0,
        padding: 2,
        paddingTop: 0,
        paddingLeft: 10,
        paddingBottom: 8,
    },
    input_field: {
        padding: "14px 0 0px 0",
        fontSize: 13,
    },
    TextField: {
        margin: 0 + "!important",
    },
    overrides: {
        margin: "0px",
    },
    containerDiv: {
        height: "400px",
        overflowY: "auto",
        position: "relative",
    },
    searchInput: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        position: "sticky",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
        backgroundColor: "white",
    },
    contentDiv: {
        paddingTop: "10px",
    },
    accordion: {
        borderBottom: "none",
        margin: 0,
    },
    accordionSummary: {
        padding: 0,
        marginBottom: 0,
        margin: 0,
        paddingRight: 13,
    },
    gridItem: {
        marginLeft: 10,
    },
}));
const WorkspaceMenu = ({ open, anchor, onClose }) => {
    const workspaces = useSelector((state) => state.users?.user.workspaces);
    const currentWorkspaceId = useSelector((state) => state.users?.user.current_workspace_id);
    const [expanded, setExpanded] = React.useState(false);
    const [, isLoading, switchWorkspace] = useApi();
    const navigate = useNavigate();
    const classes = useStyle();
    const plan_type = useSelector((state) => state.users?.user?.plan_type);
    const plan_name = useSelector((state) => state.users?.user?.plan_name);
    const account_role = useSelector((state) => state.users?.user?.account_role);
    const user_features = useSelector((state) => state.users?.user?.subscription_meta_data.features_flags);
    const [sortedWorkspaces, setSortedWorkspaces] = React.useState([]);

    function compareAtoZ(a, b) {
        if (a["display_name"] < b["display_name"]) {
            return -1;
        }
        if (a["display_name"] > b["display_name"]) {
            return 1;
        }
        return 0;
    }

    React.useEffect(() => {
        if (workspaces?.length > 0) {
            const copyArray = [...workspaces];
            const newArray = copyArray.sort(compareAtoZ);
            setSortedWorkspaces(newArray);
        }
    }, [workspaces]);

    const getFilteredDatasources = (sortedDatasources) => {
        /**
         * To not show deprecated connectors to new users (with num of reports 0)
         */
        const filtered = sortedDatasources.filter(
            (el) => el?.slug?.toLowerCase()?.includes(filter) || el?.name?.toLowerCase()?.includes(filter)
        );

        return filtered;
    };

    const [filter, setFilter] = React.useState("");
    const handleChange = (panel) => (event, isExpanded) => {
        console.log(panel);
        setExpanded(isExpanded ? panel : false);
    };
    const onWarehouseSwitch = async (workspace_id) => {
        await switchWorkspace({
            module: "workspace",
            method: "switchWorkspace",
            apiData: {
                workspace_id: workspace_id,
            },
        });
        const auth = getAuth();
        const user = auth.currentUser;
        const idToken = await user.getIdToken(true);
        Cookies.set("accessToken", idToken);
        Cookies.set("idToken", idToken);
        window.location.href = config.frontendUrl;
    };

    return (
        <Popover
            style={{ marginTop: 40, padding: 0 }}
            id="simple-menu"
            anchorEl={open}
            keepMounted
            open={anchor}
            onClose={onClose}
            MenuListProps={{
                style: {
                    padding: 0,
                },
            }}
        >
            <Grid className={classes.root}>
                <div className={classes.containerDiv}>
                    <div className={classes.searchInput}>
                        <TextField
                            type="search"
                            variant="filled"
                            placeholder="Search for Workspaces"
                            className={classes.overrides}
                            margin="none"
                            fullWidth
                            onChange={(e) => {
                                setFilter(e?.target?.value?.toLowerCase());
                            }}
                            InputProps={{
                                className: classes.input,
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input_field,
                                    root: classes.TextField,
                                },
                                startAdornment: (
                                    <InputAdornment position="start" padding="0">
                                        <SearchIcon sx={{ color: "action.active" }} />
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                style: {
                                    color: "rgba(253, 149, 103, 1)",
                                },
                            }}
                        />
                    </div>
                    <div className={classes.contentDiv}>
                        {getFilteredDatasources(sortedWorkspaces).length === 0 ? (
                            <Grid></Grid>
                        ) : (
                            getFilteredDatasources(sortedWorkspaces)
                                .filter((workspace) => workspace.workspace_id === currentWorkspaceId)
                                .map((workspace) => {
                                    return account_role === "Super-Admin" && checkForFeature(user_features, ["WORKSPACE"]) ? (
                                        <Accordion
                                            elevation={0}
                                            expanded={expanded == workspace.id}
                                            onChange={handleChange(workspace.id)}
                                            disableGutters
                                            style={{
                                                borderBottom: `${
                                                    expanded === workspace.id ? "2px solid rgba(240, 240, 240, 1)" : "none"
                                                }`,
                                            }}
                                            className={classes.accordion}
                                            sx={{
                                                borderBottom: "none",
                                                "& .MuiAccordion-root": {
                                                    "&.Mui-expanded": {
                                                        margin: 0,
                                                    },
                                                },
                                                margin: 0, // Set borderBottom to none to remove the border
                                            }} // Set borderBottom to none to remove the border
                                        >
                                            <AccordionSummary
                                                style={{
                                                    backgroundColor: `${
                                                        expanded !== workspace.id ? "rgba(255, 246, 239, 1)" : "white"
                                                    }`,
                                                    borderBottom: `${
                                                        expanded !== workspace.id ? "1px solid rgba(253, 149, 103, 1)" : "none"
                                                    }`,
                                                }}
                                                expandIcon={
                                                    <ExpandMoreIcon
                                                        fontSize="medium"
                                                        style={{
                                                            color: "rgba(253, 149, 103, 1)",
                                                            fill: "rgba(253, 149, 103, 1)",
                                                            paddingRight: 0,
                                                        }}
                                                    />
                                                }
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                classes={{ content: classes.customContent }}
                                                className={classes.accordionSummary}
                                            >
                                                <Grid style={{ height: 45, width: "100%" }} container alignItems="center">
                                                    <Grid container justifyContent="flex-start" alignItems="center">
                                                        <Grid item container xs={2}>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    border: "1px solid rgba(212, 220, 228, 1)",
                                                                    borderRadius: 4,
                                                                    width: 45,
                                                                    height: 45,
                                                                    marginLeft: 3,
                                                                    backgroundColor: "white",
                                                                }}
                                                                container
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Typography
                                                                    variant="h3"
                                                                    style={{
                                                                        fontSize: 18,
                                                                        fontWeight: 500,
                                                                        color: "rgba(98, 112, 130, 1)",
                                                                    }}
                                                                >
                                                                    {workspace.name[0]}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container xs={10} alignItems="center">
                                                            <Grid item style={{ marginLeft: 8 }}>
                                                                <Typography
                                                                    variant="h3"
                                                                    style={{
                                                                        fontSize: 14,
                                                                        fontWeight: 500,
                                                                        color: "rgba(21, 41, 69, 1)",
                                                                        textOverflow: "ellipsis",
                                                                        maxWidth: "200px",
                                                                        textAlign: "left",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {workspace?.name}
                                                                </Typography>
                                                            </Grid>
                                                            {workspace.is_default && (
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        marginLeft: 10,
                                                                        backgroundColor: "rgba(255, 223, 210, 1)",
                                                                        borderRadius: 5,
                                                                        paddingLeft: 8,
                                                                        paddingRight: 8,
                                                                        paddingTop: 2,
                                                                        paddingBottom: 2,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h3"
                                                                        style={{
                                                                            fontSize: 10,
                                                                            fontWeight: 500,
                                                                            color: "rgba(253, 149, 103, 1)",
                                                                        }}
                                                                    >
                                                                        Default
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: "0px 0 0 60px" }}>
                                                <Grid item container xs={12}>
                                                    <Grid item xs={12} style={{ marginTop: 0, marginBottom: 0 }}>
                                                        <ButtonBase
                                                            style={{ padding: 4 }}
                                                            onClick={() =>
                                                                navigate("/settings?tab=workspaces", {
                                                                    state: {
                                                                        tab:
                                                                            plan_type !== "custom" && plan_name !== "Unlimited"
                                                                                ? 8
                                                                                : 7,
                                                                        edit_workspace: workspace?.workspace_id,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <img src={EditWorkspaceIcon} />
                                                            <Typography
                                                                style={{
                                                                    fontSize: 14,
                                                                    color: "rgba(253, 149, 103, 1)",
                                                                    fontWeight: 500,
                                                                    marginLeft: 5,
                                                                }}
                                                            >
                                                                Manage Workspace
                                                            </Typography>
                                                        </ButtonBase>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: 0, marginBottom: 0 }}>
                                                        <ButtonBase
                                                            style={{ padding: 4 }}
                                                            onClick={() =>
                                                                navigate("/settings?tab=workspaces", {
                                                                    state: {
                                                                        tab:
                                                                            plan_type !== "custom" && plan_name !== "Unlimited"
                                                                                ? 8
                                                                                : 7,
                                                                        open_user: workspace?.workspace_id,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <img src={ManageUserIcon} />
                                                            <Typography
                                                                style={{
                                                                    fontSize: 14,
                                                                    color: "rgba(253, 149, 103, 1)",
                                                                    fontWeight: 500,
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                Manage Users
                                                            </Typography>
                                                        </ButtonBase>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : (
                                        <Grid
                                            style={{
                                                height: "55px",
                                                width: "100%",
                                                padding: 0,
                                                marginBottom: 0,
                                                backgroundColor: `${
                                                    expanded !== workspace.id ? "rgba(255, 246, 239, 1)" : "white"
                                                }`,
                                                borderBottom: `${
                                                    expanded !== workspace.id ? "1px solid rgba(253, 149, 103, 1)" : "none"
                                                }`,
                                            }}
                                            container
                                            alignItems="center"
                                            xs={12}
                                        >
                                            <Grid container justifyContent="flex-start" alignItems="center">
                                                <Grid item container xs={2}>
                                                    <Grid
                                                        item
                                                        style={{
                                                            border: "1px solid rgba(212, 220, 228, 1)",
                                                            borderRadius: 4,
                                                            width: 45,
                                                            height: 45,
                                                            marginLeft: 10,
                                                            backgroundColor: "white",
                                                        }}
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight: 500,
                                                                color: "rgba(98, 112, 130, 1)",
                                                            }}
                                                        >
                                                            {workspace.name[0]}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={10}>
                                                    <Grid item style={{ marginLeft: 10 }}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                color: "rgba(21, 41, 69, 1)",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "200px",
                                                                textAlign: "left",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {workspace?.name}
                                                        </Typography>
                                                    </Grid>
                                                    {workspace.is_default && (
                                                        <Grid
                                                            item
                                                            style={{
                                                                marginLeft: 10,
                                                                backgroundColor: "rgba(255, 223, 210, 1)",
                                                                borderRadius: 5,
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                paddingTop: 3,
                                                                paddingBottom: 3,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontWeight: 500,
                                                                    color: "rgba(253, 149, 103, 1)",
                                                                }}
                                                            >
                                                                Default
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                        )}
                        {getFilteredDatasources(sortedWorkspaces)
                            .filter((workspace) => workspace.workspace_id !== currentWorkspaceId)
                            .map((workspace) => {
                                return (
                                    <Grid style={{ borderBottom: "2px solid rgba(240, 240, 240, 1)" }}>
                                        <ButtonBase
                                            style={{ height: "55px", width: "100%" }}
                                            onClick={() => onWarehouseSwitch(workspace.workspace_id)}
                                        >
                                            <Grid container justifyContent="flex-start" alignItems="center">
                                                <Grid item container xs={2}>
                                                    <Grid
                                                        item
                                                        style={{
                                                            border: "1px solid rgba(212, 220, 228, 1)",
                                                            borderRadius: 4,
                                                            width: 45,
                                                            height: 45,
                                                            marginLeft: 10,
                                                        }}
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight: 500,
                                                                color: "rgba(98, 112, 130, 1)",
                                                            }}
                                                        >
                                                            {workspace.name[0]}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={8} alignItems="center">
                                                    <Grid item style={{ marginLeft: 10 }}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                color: "rgba(21, 41, 69, 1)",
                                                            }}
                                                        >
                                                            {workspace.name}
                                                        </Typography>
                                                    </Grid>
                                                    {workspace.is_default && (
                                                        <Grid
                                                            item
                                                            style={{
                                                                marginLeft: 10,
                                                                backgroundColor: "rgba(255, 223, 210, 1)",
                                                                borderRadius: 5,
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                paddingTop: 3,
                                                                paddingBottom: 3,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontWeight: 500,
                                                                    color: "rgba(253, 149, 103, 1)",
                                                                }}
                                                            >
                                                                Default
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                <Grid xs={2} justifyContent={"flex-end"}>
                                                    <img
                                                        src={SwitchIcon}
                                                        style={{
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    </Grid>
                                );
                            })}
                        {account_role === "Super-Admin" && (
                            <Grid xs={12}>
                                <ButtonBase
                                    style={{ width: "100%" }}
                                    onClick={() =>
                                        navigate("/settings?tab=workspaces", {
                                            state: {
                                                tab: plan_type !== "custom" && plan_name !== "Unlimited" ? 3 : 2,
                                                open: true,
                                            },
                                        })
                                    }
                                >
                                    <Grid
                                        xs={12}
                                        container
                                        style={{ paddingLeft: 20, paddingTop: 10, paddingBottom: 10, cursor: "pointer" }}
                                        justifyContent="start"
                                        alignItems="center"
                                    >
                                        <Grid item style={{ height: 24, paddingRight: 5 }}>
                                            <AddIcon style={{ color: "rgba(253, 149, 103, 1)" }} fontSize="medium" />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                style={{ fontSize: 14, color: "rgba(253, 149, 103, 1)", fontWeight: 500 }}
                                            >
                                                Create New Workspace
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ButtonBase>
                            </Grid>
                        )}
                    </div>
                </div>
            </Grid>
        </Popover>
    );
};

export default WorkspaceMenu;
