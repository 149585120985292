import { Typography, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "config/axios";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FormGroup } from "@mui/material";
import { object, string, ref } from "yup";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import logo from "assets/images/loginPage/dcLogo.png";
import Banner from "../../common/banner/Banner2";
import useApi from "hooks/useApi";
import config from "constants/config";
import { auth } from "config/firebase";
import { startState } from "codemirror";
import { Checkbox } from "@mui/material";
import Cookies from "js-cookie";
import useEventTracker from "services/EventTracker/useEventTracker";
import { useDispatch } from "react-redux";
import datachannel_logo from "assets/datachannel_logo.svg";
import data_channel_logo_full from "assets/datachannel_name_logo.svg";
import DCCompleteLogo from "assets/DC_complete_logo.png";
import sentEmailSVG from "assets/images/loginPage/sentEmail.svg";
import Alert from '@mui/material/Alert';
import LinkSent from "./LinkSent";
import bgImage from "assets/images/loginPage/BackgroundSVG.svg";
import "react-phone-input-2/lib/material.css";
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";
import ReactPhoneInput from "react-phone-input-2";
import { IS_ONBOARDING } from "redux/actionTypes/actionTypes";
import {signInWithEmailAndPassword} from "firebase/auth";
const initialValues = {
    password: "",
    confirmPassword: "",
    name: "",
    organization_name: "",
    termsChecked: false,
    phone_number:""
};
const useStyle = makeStyles((theme) => {
    return {
        banner: {
            height: "100vh",
            [theme.breakpoints.down('sm')]: {
                height: "80vh",
            },
        },
        logoContainer2: {
            marginBottom: 30,
        },
        textCenter: {
            textAlign: "center",
        },
        primColor: {
            color: theme.palette.primary.main,
        },
        hr: {
            marginTop: 20,
            marginBottom: 20,
        },
        welcome: {
            color: "#2C2C2C",
        },
        socialShare: {
            fontSize: "10px",
            color: "#626262",
            boxShadow: "0px 0px 3px #00000029",
            border: "none",
            marginTop: 25,
            height: 34,
            marginRight: 18,
        },
        logoContainer: {
            marginBottom: 5,
            marginTop: -10,
        },
        box: {
            backgroundColor: "#ffffff",
            boxShadow:
                "0px 7px 89px rgba(20, 12, 112, 0.08), 0px 1.56354px 19.8793px rgba(20, 12, 112, 0.0476886), 0px 0.465507px 5.91859px rgba(20, 12, 112, 0.0323114)",
            borderRadius: 32,
            padding: "30px 50px",
            [theme.breakpoints.down('sm')]: {
                padding: "30px 30px",
            },
        },
        input: {
            borderRadius: 40,
            fontFamily: "Plus Jakarta Sans",
            height:39
        },
        notchedOutline: { borderColor: "#282876 !important" },
        iconContainer: {
            [theme.breakpoints.down('sm')]: {
                display: "none",
            },
        },
        iconContainerSmallScreen: {
            marginBottom: 40,
        },
        bgRadial: {
            height: "80vh",
            [theme.breakpoints.up("sm")]: {
                backgroundImage: `url(${bgImage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "100vh",
            },
        },
        bannerHide: {
            [theme.breakpoints.down('md')]: {
                display: "none",
            },
        },
        borderClass: {
            "&.react-tel-input .form-control:focus": {
               borderColor: "#282876",

               boxShadow: "0px 0px 0px 1px #282876",  
            },
            "&.react-tel-input .form-control": {
                border:'1px solid #282876',
                color:"#282876",
                fontSize:"8px",
             },
             "&.special-label":{
                color:"#282876",
                fontSize:"8px",
             }
 

        }    
        
    };
});

const UserInput = () => {
    const classes = useStyle();
    const search = useLocation().search;
    const dispatch = useDispatch();
    const token = new URLSearchParams(search).get("token");
    const [verified, setVerified] = useState(false);
    const [confirmed, isConfirmedLoading, makeConfirmation] = useApi();
    const [email, setEmail] = useState("");
    const { state } = useLocation();
    const [termsChecked, setTermChecked] = useState(false);
    const getParams = new URLSearchParams(search);
    const idToken = getParams.get("idToken");
    const emailParam = getParams.get("email");
    const isSocial = getParams.get("isSocial");
    const EventTracker = useEventTracker();
    const [user, loading, setUser] = useApi();
    const [name, setName] = useState();
    const [linkSent, setLinkSet] = useState(false);
    const [response, isLoading, register] = useApi();
    const [wrongLink, setWrongLink] = useState(false);
    const [linkExpired, setLinkExpired] = useState(true);
    const [OTP, setOtp] = useState(null)
    const navigate = useNavigate();
    const [signInIsLoading,setSignInIsLoading] = useState()
    const [, checkForFirebaseIdIsLoading, checkForFirebaseIdCall] = useApi();

    console.log(loading);
    useEffect(() => {
        // axios
        //     .get(`auth/confirm_link/${token}`)
        //     .then(async (response) => {
        //         console.log(response);
        //         setEmail(response.data.data.email);
        //         await sleep(1000);
        //         if (response.data.verified) {
        //             setVerified(true);
        //         } else {
        //             setError(response.data.message);
        //         }
        //     })
        //     .catch((e) => {
        //         setError(e.response.data.message);
        //     });
        async function req() {
            try {
                const response = await makeConfirmation({
                    module: "auth",
                    method: "confirmLink",
                    apiData: {
                        data: token,
                    },
                    returnResult: true,
                    throwError: true,
                });
                setEmail(response.email);
                EventTracker.trackSelfDescribingEvent("user_signup_registerLink_verification_success", { email: response.email });
                setVerified(true);
            } catch (e) {
                if (e?.response?.status === 422) {
                    console.log(e?.response?.data?.data?.email);
                    setEmail(e?.response?.data?.data?.email);
                    setWrongLink(false);
                    setVerified(false);
                    setLinkExpired(true);
                }
                if (e?.response?.status === 500) {
                    setWrongLink(true);
                }
                console.log(e);
                EventTracker.trackSelfDescribingEvent("user_signup_registerLink_verification_error", {
                    email: response?.email,
                    error: e?.response,
                });
            }
        }
        if (!state?.isSocial) {
            setEmail(state?.email);
            setVerified(state?.verified);
            setOtp(state?.otp)
            console.log(state?.otp)
            EventTracker.trackSelfDescribingEvent("user_signup_registerLink_verification_success", { email: state?.email });
        } else {
            setEmail(state?.email);
            setName(state?.name);
            setVerified(true);
        }
    }, []);

    const checkForFirebaseId = async (firebase_id, email) => {
        return await checkForFirebaseIdCall({
            module: "auth",
            method: "checkUserFromFirebase",
            apiData: {
                firebase_id: firebase_id,
                data: {
                    email: email,
                },
            },
            returnResult: true,
            throwError: true,
        });
    };

    const signInWithEmail = async (email, password) => {
        try {
            setSignInIsLoading(true)
            const result = await signInWithEmailAndPassword(auth, (email = email), (password = password));
            let firebase_id = result.user.uid;
            console.log(firebase_id);
            let res = await checkForFirebaseId(firebase_id, result.user.email);
            if (res.isUser === true) {
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                Cookies.remove("expiresIn");
                window.localStorage.clear();
                dispatch({ type: "SET_USER", payload: null });

                console.log(Cookies.get("accessToken"));
                axios.defaults.headers.common["Authorization"] = `Bearer ${result._tokenResponse.idToken}}`;
                // const user = await getUser();
                //dispatch({ type: "SET_USER", payload: user.data.data });
                Cookies.set("accessToken", result._tokenResponse.idToken);
                Cookies.set("refreshToken", result._tokenResponse.refreshToken);
                Cookies.set("idToken", result._tokenResponse.idToken);
                Cookies.set("expiresIn", new Date().getTime() + result._tokenResponse.expiresIn * 1000);
                // Cookies.set("refreshToken", res.data.refresh_token);
                // Cookies.set("expiresIn", new Date().getTime() + res.data.expires_in * 1000);
                setSignInIsLoading(false);
                window.location.href = config.frontendUrl;
            } else if (res.isUser === false && res.sameDomain === true) {
                setError("This email domain already registered, please contact your admin");
            } else {
                navigate("/confirm_link", {
                    state: {
                        firebaseId: result.user.uid,
                        isSocial: true,
                        email: result.user.email,
                        idToken: result._tokenResponse.idToken,
                        refreshToken: result._tokenResponse.refreshToken,
                    },
                });
            }
        } catch (e) {
            console.log(e);
            setSignInIsLoading(false);
            if (e.code == "auth/wrong-password") {
                console.log(e.code);
                setError("Wrong credentials");
                return;
            }
            if (e.code == "auth/user-not-found") {
                console.log(e.code);
                setError("Email does not exists");
            } else {
                setError("something went wrong");
            }
            throw e;
        }
    };


    const onResendLink = async () => {
        try {
            await register({
                module: "auth",
                method: "register",
                // successToast: "Email Sent",
                apiData: {
                    data: { email: email },
                },
                returnResult: true,
                throwError: true,
            });
            setLinkSet(true);
            setLinkExpired(false);
            setWrongLink(false);
        } catch (e) {
            if (e?.response?.status === 422) {
                formikHelpers.setErrors({
                    email: e?.response.data.message,
                });
            }
        }
        //comment
    };
    let render = null;
    console.log(isConfirmedLoading);
    let validationSchema = {
        password: string().required().min(6),
        confirmPassword: string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: string().oneOf([ref("password")], "Both password need to be the same"),
        }),
        phone_number:string().required("Phone number is required"),
        name: string().required(),
        organization_name: string()
            .required()
            .matches(/^[a-zA-Z0-9 ]+$/, "Only alphanumeric characters and spaces are allowed."),
    };
    if (state?.isSocial) {
        validationSchema = {
            phone_number:string().required("Phone number is required"),
            organization_name: string()
                .required()
                .matches(/^[a-zA-Z0-9 ]+$/, "Only alphanumeric characters and spaces are allowed."),
        };
    }
    console.log(validationSchema);
    if (isConfirmedLoading) {
        render = (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
                <CircularProgress />
            </Grid>
        );
    } else if (true) {
        render = (
            <Grid item container justifyContent="center">
                <Grid
                    xs={12}
                    item
                    className={classes.iconContainerSmallScreen}
                    container
                    alignItems="center"
                    justifyContent="center"
                >
                    {/* <img style={{ marginRight: "8px" }} src={datachannel_logo} alt="DataChannel Logo"></img>
                            <img src={data_channel_logo_full} alt="DataChannel Logo"></img> */}
                    <img src={DCCompleteLogo} style={{ height: 40 }} alt="DataChannel Logo"></img>
                </Grid>
                <Grid item xs={12} sm={10} justifyContent="center" alignItems="center" className={classes.box}>
                    {/* <Typography variant="h6" className={classes.textCenter + " " + classes.welcome}>
                    Welcome To
                </Typography>
                <Grid container justify="center" className={classes.logoContainer}>
                    <img src={logo} alt="wc logo" />
                </Grid> */}
                    <Formik
                        validationSchema={object(validationSchema)}
                        initialValues={initialValues}
                        onSubmit={async (values, formikHelpers) => {
                            let isSocial = state?.isSocial ? true : false;
                            if (!isPossiblePhoneNumber(`+${values.phone_number}`)) {
                                formikHelpers.setErrors({
                                    phone_number: "Invalid Phone number"
                                });
                                return
                            }
                            values = {
                                ...values,
                                // token: token,
                                email: email,
                                isSocial: isSocial,
                                idToken: state?.idToken,
                                firebase_id: state?.firebaseId,
                            };
                            if (!termsChecked) {
                                return;
                            }
                            console.log(values);
                            // axios
                            //     .post("/auth/user_input", values)
                            //     .then(function (response) {
                            //         console.log(response);
                            //         setRegistered(true);
                            //     })
                            //     .catch(function (error) {
                            //         console.log(error);
                            //     });
                            // let token = Cookies.get("accessToken");

                            try {
                                await setUser({
                                    module: "auth",
                                    method: "userUpdate",
                                    apiData: {
                                        data: { ...values, otp: OTP },
                                    },
                                    returnResult: true,
                                    throwError: true,
                                });
                                console.log("fjdksjfklsd-1");

                                Cookies.remove("accessToken");
                                Cookies.remove("refreshToken");
                                Cookies.remove("expiresIn");
                                console.log("fjdksjfklsd-2");
                                dispatch({ type: "SET_USER", payload: null });
                                console.log("fjdksjfklsd-3");
                                window.localStorage.clear();

                                if (state?.idToken) {
                                    console.log("fjdksjfklsd-4");
                                    axios.defaults.headers.common["Authorization"] = `Bearer ${state?.idToken}}`;
                                } 
                                console.log("fjdksjfklsd");
                                // const user = await getUser();
                                //dispatch({ type: "SET_USER", payload: user.data.data });
                                // Cookies.set("accessToken", props.accessToken);
                                // Cookies.set("refreshToken", result._tokenResponse.refreshToken);
                                // Cookies.set("idToken", result._tokenResponse.idToken);
                                // Cookies.set("expiresIn", new Date().getTime() + result._tokenResponse.expiresIn * 1000);
                                // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                                // window.location.href = `${config.authUrl}auth?response_type=${config.responseType}&client_id=${config.clientId}&redirect_url=${config.redirectUrl}&scopes=${config.scopes}`;
                                EventTracker.trackSelfDescribingEvent("user_signup_register_success", {
                                    email: email,
                                    name: values.name,
                                    organization_name: values.organization_name,
                                });
                                EventTracker.trackGaEvent("sign_up");
                                console.log(config.frontendUrl);
                                dispatch({ type: IS_ONBOARDING, payload: true })
                                if (!state?.isSocial){
                                await signInWithEmail(email,values.password)
                                }
                                window.location.href = config.frontendUrl;
                            } catch (e) {
                                if (e?.response?.status === 422) {
                                    formikHelpers.setErrors({
                                        organization_name: e?.response.data.message,
                                    });
                                }
                                EventTracker.trackSelfDescribingEvent("user_signup_register_error", {
                                    email: response?.email,
                                    name: values.name,
                                    organization_name: values.organization_name,
                                    error: e?.response,
                                });
                            }
                        }}
                    >
                        {({ values, errors, isSubmitting, isValidating, touched,setFieldValue,handleChange }) => (
                            <Form>
                                {/* <FormGroup>
                                <Field
                                    name="email"
                                    as={TextField}
                                    label="Work Email"
                                    variant="outlined"
                                    disabled
                                    value={email}
                                    fullWidth
                                    margin="dense"
                                />
                            </FormGroup> */}
                                <Grid
                                    item
                                    style={{ marginBottom: 5 }}
                                    xs={12}
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    {state?.isSocial ? (
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    fontSize: 18,
                                                    fontFamily: "Plus Jakarta Sans",
                                                    color: "#282876",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Hey {name?.split(" ")?.length != 0 ? name?.split(" ")[0] : null}, Let’s Set up
                                                your DataChannel Account
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={9}>
                                            <Typography
                                                style={{
                                                    fontSize: 18,
                                                    fontFamily: "Plus Jakarta Sans",
                                                    color: "#282876",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Set up your DataChannel Account
                                            </Typography>
                                        </Grid>
                                    )}
                                    {!state?.isSocial ? (
                                        <Grid item xs={3}>
                                            <Typography
                                                style={{
                                                    fontSize: 12,
                                                    fontFamily: "Plus Jakarta Sans",
                                                    color: "#8181AC",
                                                    fontWeight: 500,
                                                }}
                                                align="right"
                                            >
                                                Step 2/2
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <Typography
                                        style={{
                                            fontSize: 14,
                                            fontFamily: "Plus Jakarta Sans",
                                            color: "#4A3AFF",
                                            fontWeight: 500,
                                        }}
                                    >
                                        {email}
                                    </Typography>
                                </Grid>
                                {(!state || !state?.isSocial) && (
                                    <FormGroup>
                                        <Field
                                            error={touched.name && errors.hasOwnProperty("name")}
                                            name="name"
                                            as={TextField}
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            helperText={touched.name && errors?.name}
                                            InputProps={{
                                                className: classes.input,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "#282876",
                                                    fontFamily: "Plus Jakarta Sans",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup>
                                    <Field
                                        error={touched.organization_name && errors.hasOwnProperty("organization_name")}
                                        name="organization_name"
                                        as={TextField}
                                        label="Name of your organisation"
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        margin="dense"
                                        helperText={touched.organization_name && errors?.organization_name}
                                        InputProps={{
                                            className: classes.input,
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: "#282876",
                                                fontFamily: "Plus Jakarta Sans",
                                            },
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                <ReactPhoneInput
                                    inputStyle={{
                                        width: "100%",
                                        height: "10px",
                                        fontSize: "13px",
                                        borderRadius: "20px",
                                        
                                    }}
                                
                                    containerClass={classes.borderClass}
                                    specialLabel="Phone number"
                                    containerStyle={{marginTop:7,marginBottom:7,color:"#282876",fontSize:"8px",
                                }}
                                    defaultCountry={"india"}
                                    name="phone_number"
                                    type="tel"
                                    isValid={!errors.hasOwnProperty("phone_number")}
                                    variant="outlined"
                                    // country={"us"}
                                    onChange={(phone, country) => {
                                        setFieldValue("phone_number", phone);
                                        handleChange("phone_number");
                                    }}
                                    
                                />
                                {touched.phone_number && errors.hasOwnProperty("phone_number") && (
                                    <Typography style={{ color: "red", fontSize: 12, marginTop: 5, marginLeft: 15 }}>
                                        {touched.phone_number && errors?.phone_number}
                                    </Typography>
                                )}
                            </FormGroup>

                                {(!state || !state?.isSocial) && (
                                    <FormGroup>
                                        <Field
                                            error={touched.password && errors.hasOwnProperty("password")}
                                            name="password"
                                            as={TextField}
                                            label="Password"
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            placeholder="Min. 6 Characters"
                                            margin="dense"
                                            helperText={touched.password && errors?.password}
                                            InputProps={{
                                                className: classes.input,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "#282876",
                                                    fontFamily: "Plus Jakarta Sans",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                )}
                                {(!state || !state?.isSocial) && (
                                    <FormGroup>
                                        <Field
                                            error={touched.confirmPassword && errors.hasOwnProperty("confirmPassword")}
                                            name="confirmPassword"
                                            as={TextField}
                                            label="Confirm Password"
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            margin="dense"
                                            helperText={touched.confirmPassword && errors?.confirmPassword}
                                            InputProps={{
                                                className: classes.input,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: "#282876",
                                                    fontFamily: "Plus Jakarta Sans",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ marginTop: 15 }}
                                    >
                                        <Grid item>
                                            <Checkbox
                                                checked={termsChecked}
                                                onChange={(e) => setTermChecked(e.target.checked)}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                color="secondary"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                                style={{
                                                    fontSize: 11,
                                                    fontWeight: 400,
                                                    color: "#2E394B",
                                                    fontFamily: "Plus Jakarta Sans",
                                                }}
                                            >
                                                I agree to the DataChannel
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center">
                                            <a href="https://datachannel.co/terms-of-services/" target="_blank">
                                                {" "}
                                                <Typography
                                                    style={{
                                                        fontSize: 11,
                                                        color: "#4A3AFF",
                                                        fontWeight: "bold",
                                                        fontFamily: "Plus Jakarta Sans",
                                                    }}
                                                >
                                                    &nbsp;Terms & Conditions&nbsp;
                                                </Typography>
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                                style={{
                                                    fontSize: 11,
                                                    fontWeight: 400,
                                                    color: "#2E394B",
                                                    fontFamily: "Plus Jakarta Sans",
                                                }}
                                            >
                                                &nbsp; and &nbsp;
                                            </Typography>
                                        </Grid>
                                        <Grid item align="center">
                                            <a href="https://datachannel.co/privacy-policy/" target="_blank">
                                                <Typography
                                                    style={{
                                                        fontSize: 11,
                                                        color: "#4A3AFF",
                                                        fontWeight: "bold",
                                                        fontFamily: "Plus Jakarta Sans",
                                                    }}
                                                >
                                                    {" "}
                                                    Privacy Policy
                                                </Typography>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={loading || !termsChecked}
                                    fullWidth
                                    style={{ borderRadius: "40px", backgroundColor: "#4E3EFF", color: "#ffffff" }}
                                >
                                    {loading || signInIsLoading ? (
                                        <CircularProgress
                                            style={{
                                                marginRight: 10,
                                                color: "white",
                                            }}
                                            size={16}
                                        />
                                    ) : null}
                                    Submit
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        );
    } else if (!verified && !isConfirmedLoading && !wrongLink && linkExpired) {
        return (
            <Grid
                container
                xs={12}
                justifyContent="center"
                // style={{ height: "60vh" }}
                spacing={0}
                direction="row"
                alignItems="center"
                className={classes.bgRadial}
            >
                <Grid item xs={12} container justifyContent="center" style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <Grid container xs={12} item className={classes.logoContainer2} justifyContent="center">
                        <Grid item>
                            <img src={DCCompleteLogo} style={{ height: 40 }} alt="DataChannel Logo"></img>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={5} className={classes.box}>
                        {/* <Grid item container xs={12} justifyContent="center">
                        <img src={LinkSentPNG} />
                    </Grid> */}
                        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 20 }}>
                            <Alert severity="error">Your previous verification link has expired.</Alert>
                        </Grid>
                        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                            <Grid>
                                <Typography
                                    style={{ fontSize: 20, fontFamily: "Plus Jakarta Sans", color: "#282876", fontWeight: 600 }}
                                >
                                    Resend Verification Link
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Typography
                                style={{ fontSize: 14, fontFamily: "Plus Jakarta Sans", color: "#8181AC", fontWeight: 500 }}
                            >
                                We’ll again send you a link to verify your email.
                            </Typography>
                        </Grid>

                        {/* To be Implemented Later */}

                        {/* <Grid item xs={12} style={{ marginTop: 15 }} container justifyContent="center">
                        <Typography style={{ fontSize: 14, fontFamily: 'Plus Jakarta Sans', color: '#8181AC', fontWeight: 500 }}>
                            Didn’t receive it ? <span style={{ fontSize: 14, fontFamily: 'Plus Jakarta Sans', color: '#4A3AFF', fontWeight: 500 }}>Send again</span>
                        </Typography>

                    </Grid> */}
                        <Grid item xs={12} container style={{ marginTop: 20 }}>
                            <Grid item xs={12}>
                                <Grid container xs={12} justifyContent="center">
                                    <Grid item xs={10} sm={8}>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            disabled={isLoading}
                                            fullWidth
                                            style={{
                                                borderRadius: "40px",
                                                backgroundColor: "#4E3EFF",
                                                marginTop: 20,
                                                color: "#ffffff",
                                            }}
                                            disable={email === ""}
                                            onClick={onResendLink}
                                        >
                                            {isLoading ? (
                                                <CircularProgress
                                                    style={{
                                                        marginRight: 10,
                                                        color: "white",
                                                    }}
                                                    size={16}
                                                />
                                            ) : null}
                                            Resend verification Link
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else if (wrongLink && !isConfirmedLoading) {
        return (
            <Grid
                container
                xs={12}
                justifyContent="center"
                // style={{ height: "60vh" }}
                spacing={0}
                direction="row"
                alignItems="center"
                className={classes.bgRadial}
            >
                <Grid item xs={12} container justifyContent="center" style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <Grid container xs={12} item className={classes.logoContainer2} justifyContent="center">
                        <Grid item>
                            <img src={DCCompleteLogo} style={{ height: 40 }} alt="DataChannel Logo"></img>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={5} className={classes.box}>
                        {/* <Grid item container xs={12} justifyContent="center">
                  <img src={LinkSentPNG} />
              </Grid> */}
                        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 20 }}>
                            <Alert severity="error"> Wrong Verification Link.</Alert>
                        </Grid>
                        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                            <Grid>
                                <Typography
                                    style={{ fontSize: 20, fontFamily: "Plus Jakarta Sans", color: "#282876", fontWeight: 600 }}
                                >
                                    Wrong Verification Link
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* To be Implemented Later */}

                        {/* <Grid item xs={12} style={{ marginTop: 15 }} container justifyContent="center">
                  <Typography style={{ fontSize: 14, fontFamily: 'Plus Jakarta Sans', color: '#8181AC', fontWeight: 500 }}>
                      Didn’t receive it ? <span style={{ fontSize: 14, fontFamily: 'Plus Jakarta Sans', color: '#4A3AFF', fontWeight: 500 }}>Send again</span>
                  </Typography>

              </Grid> */}
                        <Grid item xs={12} container style={{ marginTop: 20 }}>
                            <Grid item xs={12}>
                                <Grid container xs={12} justifyContent="center">
                                    <Grid item xs={8}>
                                        <Button
                                            variant="contained"
                                            onClick={() => navigate("/register")}
                                            fullWidth
                                            style={{
                                                borderRadius: "40px",
                                                backgroundColor: "#4E3EFF",
                                                marginTop: 20,
                                                color: "#ffffff",
                                            }}
                                        >
                                            Go back To register
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    if (linkSent) {
        return (
            <Grid container justifyContent="center" className={classes.bgRadial}>
                <Grid item xs={12} className={classes.banner}>
                    <Grid container className={classes.banner} style={{ paddingTop: 30 }}>
                        <LinkSent email={email} />
                    </Grid>
                </Grid>
                {/* <Banner /> */}
            </Grid>
        );
    }
    return (
        <Grid container justifyContent="center" className={classes.bgRadial}>
            <Grid
                container
                justifyContent="center"
                xs={12}
                sm={6}
                style={{ paddingLeft: 20, paddingRight: 20 }}
                alignItems="center"
            >
                {/* <Grid item style={{
                    position: 'absolute', top: 30, left: 120,
                }} className={classes.iconContainer}>
                    <img style={{ marginRight: "8px" }} src={datachannel_logo} alt="DataChannel Logo"></img>
                    <img src={data_channel_logo_full} alt="DataChannel Logo"></img>
                </Grid> */}

                <Grid container xs={12} className={classes.banner} alignItems="center" justifyContent="center">
                    <Grid item container justifyContent="center">
                        <Grid container xs={12} justifyContent="center" alignItems="center">
                            {render}
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Banner /> */}
            </Grid>
        </Grid>
    );
};

export default UserInput;
