import React from "react";
import { Typography, Grid, Button, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        margin: "65px 10px 0px 0px",
        padding: "5px 0px",
        width: "calc(100% - 135px);",
        right: 10,
        borderRadius: 5,
        border: "1px solid #e7e7e7",
        zIndex: 10,
    },
    drawerOpen: {
        width: "calc(100% - 245px);",
    },
    size14: {
        fontSize: 12,
        fontWeight: 600,
        color: "white",
    },
    infoStyles: {
        backgroundColor: "#2196f3",
    },
}));

export default function GlobalNotifications({ notifications }) {
    const currentNotification = notifications[0];
    const open = useSelector((state) => state.uiStates.isSideNavOpen);
    const classes = useStyles();
    return (
        <Paper elevation={2}>
            <Grid container className={clsx(classes.root, { [classes.infoStyles]: currentNotification.type === "INFO", [classes.drawerOpen]: open })}>
                <Grid
                    container
                    item
                    style={{ marginLeft: 15, marginRight: 10 }}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" className={classes.size14}>
                        {currentNotification.message}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}
