import React from 'react';
import { Modal, Fade, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { Categories } from './config';

// Import icons for Windows keys
import { FaAngleUp as WindowsCtrl, FaArrowUp as WindowsShift } from 'react-icons/fa';

// Import icons for Mac keys
import { IoMdKey as MacCtrl, IoIosArrowRoundUp as MacShift } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '40vw',
      borderRadius: 4,
    },
    titleContainer: {
      borderBottom: '1px solid #e0e0e0', // Add a bottom border here
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2), // Add margin to create separation
    },
    title: {
      fontSize: '26px !important',
      fontWeight: 600,
    },
    closeButton: {
      cursor: 'pointer',
    },
    categoryLabel: {
      marginTop: theme.spacing(1), // Add margin to create separation between label and shortcuts\
      marginBottom: theme.spacing(1),
      fontWeight: 600, // Make the category label bold to resemble a heading
      fontSize: '18px', // Adjust the font size
      color: theme.palette.primary.main, // Customize the color
    },
    shortcut: {
      marginBottom: theme.spacing(1), // Add margin to create separation between shortcuts
      fontSize: '14px', // Adjust the font size for the shortcuts
      fontStyle: 'italic', // Make the shortcuts italic
      color: theme.palette.text.secondary, // Customize the color
      display: 'flex', // Add flex display for icon alignment
      alignItems: 'center', // Align icons vertically
    },
    icon: {
      marginRight: theme.spacing(1), // Add margin between icon and text
      // fontSize: '15px', // Adjust the font size for the shortcuts
      fontStyle: 'italic', // Make the shortcuts italic
      color: '#555',
    },
    space: {
        marginRight: theme.spacing(2), // Add margin for spacing  
    },
  }));

const isMac = () => {
  return (navigator.userAgent.indexOf('Mac OS X') != -1);
};


const ShortcutPopUp = ({ togglePopUp, showPopUp }) => {
  const classes = useStyles();
  const useMacIcons = isMac();

  // Mapping between keys and their corresponding icons
  // const keyIcons = {
  //   ctrl: useMacIcons ? <MacCtrl className="icon" /> : <WindowsCtrl className="icon" />,
  //   shift: <WindowsShift className="icon" />,
  // };
  const keyIcons = {
    ctrl: "Ctrl",
    shift: "Shift",
  };

  return (
    <Modal className={classes.modal} open={showPopUp} onClose={togglePopUp}>
      <Fade in={showPopUp}>
        <div className={classes.paper}>
          <div className={classes.titleContainer}>
            <Typography variant="h5" className={classes.title}>
              Keyboard Shortcuts
            </Typography>
            <CloseIcon
              className={classes.closeButton}
              onClick={togglePopUp}
              fontSize="small"
            />
          </div>

          <Grid container>
            {Categories.map((category) => (
              <Grid item xs={6} key={category.label}>
                <Typography variant="subtitle1" className={classes.categoryLabel}>
                  {category.label}
                </Typography>
                <>
                  {category.events.map((shortcut) => (
                    <Typography
                      variant="body2"
                      key={shortcut.label}
                      className={classes.shortcut}
                    >
                      <div style={{display: "flex", justifyContent: "space-between", width: '100%'}}>
                        <div style={{flex: '1'}}>
                          {/* {shortcut.control} */}
                          {shortcut.control.split('+').map((key, index) => (
                          <span key={index} className={`${classes.icon}`}>
                            {keyIcons[key?.toLowerCase()] || key?.toUpperCase()}
                          </span>
                          ))}
                        </div>
                        <div style={{flex: '1'}}>{shortcut.label}</div>
                      </div>                      
                    </Typography>
                  ))}
                </>
              </Grid>
            ))}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ShortcutPopUp;
