const moment = require("moment-timezone");

class UserTransformation {
    getDateWiseCombinedDataPostTransformer(data, endPointData) {
        const newArray = [];

        data.forEach((el) => {
            const tmp = {
                date: moment(el.date).format("YYYY-MM-DD"),
                rows_moved: (el.rows_moved / 1000000).toFixed(2),
            };
            newArray.push(tmp);
        });
        return newArray;
    }
}

export default UserTransformation;
