import {
    SET_REVERSE_CONNECTOR,
    SET_REVERSE_CONNECTOR_IS_LOADING,
    SET_SYNCS,
    SET_SYNCS_IS_LOADING,
    SET_CONFIGURED_SYNCS,
    SET_CONFIGURED_SYNCS_IS_LOADING,
} from "../actionTypes/actionTypes";

const initialState = {
    reverseConnector: null,
    reverseConnectorIsLoading: false,
    syncs: [],
    syncsIsLoading: false,
    configuredSyncs: [],
    configuredSyncsIsLoading: false,
};

export const reverseConnectorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REVERSE_CONNECTOR:
            return {
                ...state,
                reverseConnector: action.payload,
                configuredSyncs: [],
            };
        case SET_REVERSE_CONNECTOR_IS_LOADING:
            return {
                ...state,
                reverseConnectorIsLoading: action.payload,
            };
        case SET_SYNCS:
            return {
                ...state,
                syncs: action.payload,
            };
        case SET_SYNCS_IS_LOADING:
            return {
                ...state,
                syncsIsLoading: action.payload,
            };
        case SET_CONFIGURED_SYNCS:
            return {
                ...state,
                configuredSyncs: action.payload,
            };
        case SET_CONFIGURED_SYNCS_IS_LOADING:
            return {
                ...state,
                configuredSyncsIsLoading: action.payload,
            };
        default:
            return state;
    }
};
