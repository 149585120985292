import React from "react";
import config from "constants/config";
import Cookies from "js-cookie";
import Ably from "ably";
import { useSelector, useDispatch } from "react-redux";
import { INCREMENT_COUNT, APPEND_NOTIFICATION_LIST } from "redux/actionTypes/actionTypes";
import axios from "config/axios";

const createTokenRequest = () => axios.get(`${config.backendUrl}/ably/auth?token=${Cookies.get("accessToken")}`);

export default function Notification() {
	const users = useSelector((state) => state.users);
	const dispatch = useDispatch();
	// const [client, setClient] = React.useState(null);
	const account_slug = users?.user?.account_slug;
	React.useEffect(() => {
		if (users.user !== null) {
			const client = new Ably.Realtime({
				authCallback: async (tokenParams, callback) => {
					try {
						const tokenRequest = (await createTokenRequest()).data;
						callback(null, tokenRequest);
					} catch (error) {
						callback(error, null);
					}
				}
			});
			var channel = client.channels.get(`dc-private:${account_slug}`);
			channel.subscribe(function (message) {
				dispatch({ type: INCREMENT_COUNT, payload: message.data });
				console.log("should be dispatched");
			});
		}
	}, [users?.user]);
	return null;
}
