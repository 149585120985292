import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class TransformationEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `transformations`;
        this.transformer = null;
    }
    getAll() {
        return axios.get(`${this.resourceEndpoint}`);
    }
    runTransformation(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.transformationId}/run`);
    }
    deleteTransformation(data) {
        return axios.delete(`${this.resourceEndpoint}/${data.apiData.transformationId}/delete`);
    }
    getLogs(data) {
        return axios.get(`${this.resourceEndpoint}/${data.apiData.transformationId}/change_history`);
    }
}

export default TransformationEndpoints;
