import axios from "../../config/axios";
import config from "../../constants/config";
import axiosReverse from "../../config/reverseETLaxios";
/*
  THIS IS A LEGACY FILE. PLEASE DON'T DEFINE YOU API ENDPOINTS HERE.
  USE THE API PROVIDER AND ENDPOINTS CLASS INSTEAD PRESENT IN THE SERVICES FOLDER.
*/

//DataSources Endpoints

export const getDataSourcesList = () => axios.get("/datasources");

export const getDataSourceDetail = (slug) => axios.get(`/datasources/${slug}`);

export const getDataWareHousesAvailableOptions = () => axios.get("/datawarehouse-type");

export const getDataWareHousesList = (accountName) => axios.get(`/datawarehouses`);

export const getCredentials = (slug) => axios.get(`/datasources/${slug}/credentials`);

export const getWarehousesforDatasource = (slug) => axios.get(`/datawarehouses/${slug}`);

export const setWarehouseForDatasource = (data, slug) => axios.post(`/datawarehouses/${slug}`, data);

export const deleteWarehouseForDatasource = (data, slug) => axios.delete(`/datawarehouses/${slug}`, data);

export const getReports = (slug) => axios.get(`/datasources/${slug}/reports`);

export const getConfiguredReports = (slug) => axios.get(`/datasources/${slug}/configured_reports`);

export const getTemplates = (slug) => axios.get(`/datasources/${slug}/templates`);

export const getReportsFormData = (dataPipelineID, dataSourceSlug) =>
    axios.get(`/datasources/${dataSourceSlug}/reports/${dataPipelineID}`);

export const getReportsLevelOptions = (levelid, credentialsid) => axios.get(`/level/${levelid}/credentials/${credentialsid}`);

export const getReportsLevelOptionsWithSelectedOption = (levelid, credentialsid, currentSelectedOptionData) =>
    axios.post(`/level/${levelid}/credentials/${credentialsid}`, currentSelectedOptionData);

export const getTableSchema = (data) => axios.get(`/datasources/${data.slug}/configured_reports/${data.reportId}/table-schema`);
export const patchReportDetails = (slug, reportId, dataToPatch) =>
    axios.patch(`/datasources/${slug}/configured_reports/${reportId}`, dataToPatch);

export const createReport = (reportData, dataSourceSlug) => axios.post(`/datasources/${dataSourceSlug}/reports/add`, reportData);

export const createTemplate = (reportData, dataSourceSlug) => axios.post(`/datasources/${dataSourceSlug}/templates`, reportData);

export const createReportForFilesConnector = (reportData, dataSourceSlug) =>
    axios.post(`/datasources/${dataSourceSlug}/reports/add/files`, reportData);

export const getTepmlateDetails = (templateId, dataSourceSlug) =>
    axios.get(`/datasources/${dataSourceSlug}/templates/${templateId}`);

export const getConfiguredReportData = (dataSourceSlug, reportID) =>
    axios.get(`/datasources/${dataSourceSlug}/configured_reports/${reportID}`);

export const runReport = (dataSourceSlug, report_id) =>
    axios.post(`/datasources/${dataSourceSlug}/configured_reports/${report_id}/manual_run`);

export const deleteReport = (dataSourceSlug, report_id) =>
    axios.delete(`/datasources/${dataSourceSlug}/configured_reports/${report_id}`);

export const changeStatusOfReport = (dataSourceSlug, report_id, data) =>
    axios.patch(`/datasources/${dataSourceSlug}/configured_reports/${report_id}/status`, data);

export const backFill = (dataSourceSlug, reportId, data) =>
    axios.post(`/datasources/${dataSourceSlug}/configured_reports/${reportId}/backfill`, data);

export const reportRunLogGraph = (reportId) => axios.get(`/configured_reports/${reportId}/run/logs/graph`);

export const getReportEditLogs = (data) => axios.get(`/datasources/${data.slug}/configured_reports/${data.reportId}/changelog`);

export const patchSchedule = (datasourceSlug, reportId, data) =>
    axios.patch(`/datasources/${datasourceSlug}/configured_reports/${reportId}`, data);

export const patchCredentials = (datasourceSlug, reportId, data) =>
    axios.patch(`/datasources/${datasourceSlug}/configured_reports/${reportId}`, data);

export const deleteCredential = (datasourceSlug, credId) => axios.delete(`/datasources/${datasourceSlug}/credentials/${credId}`);

export const patchReportNotificationLevel = (data) =>
    axios.patch(`/datasources/${data.datasourceSlug}/configured_reports/${data.reportId}/notification_level`, {
        notificationLevel: data.notificationLevel,
    });

export const addNewWareHouse = (wareHouseData) => axios.post("/datawarehouses", wareHouseData);

export const editWareHouse = (wareHouseID, wareHouseData) => axios.post(`/datawarehouses/${wareHouseID}`, wareHouseData);

//Authentication Endpoints
export const getToken = (authorizationCode) =>
    axios.post(`${config.authUrl}token`, {
        grant_type: "authorization_code",
        authorization_code: authorizationCode,
        client_id: config.clientId,
        client_secret: "sample-client-secret",
        redirect_url: config.redirectUrl,
        scopes: config.scopes,
    });

export const refreshAccessToken = (refreshToken) =>
    axios.post(
        `${config.authUrl}refresh_token`,
        {
            client_id: config.clientId,
            refresh_token: refreshToken,
        },
        {
            headers: {
                Authorization: `Bearer ${refreshToken}`,
            },
        }
    );

//Transformations Endpoints

export const getTransformations = () => axios.get("/transformations");

export const getSchedule = () => axios.get("/schedule_options");

export const getTransformation = (id) => axios.get(`/transformations/${id}`);

export const addTransformation = (data) => axios.post(`/transformations`, data);

export const editTransformation = (id, data) => axios.patch(`/transformations/${id}`, data);

export const getTransformationRunLogs = (url, queryParameters) => axios.get(url + queryParameters);

export const transformationRunLogGraph = (transformationId) => axios.get(`/transformations/${transformationId}/run/logs/graph`);

export const orchestrationRunLogGraph = (orchestrationId) => {
    return axios.get(`/orchestrations/${orchestrationId}/runs`);
};
export const dbtTransformationRunLogGraph = (transformationId) =>
    axios.get(`/transformations/dbt/${transformationId}/run/logs/graph`);

export const manualRunTransformation = (transformationId) => axios.get(`/transformations/${transformationId}/run`);

export const deleteTransformation = (transformationId) => axios.delete(`/transformations/${transformationId}/delete`);

export const changeStatusOfTransformation = (transformationId, data) =>
    axios.patch(`/transformations/${transformationId}/status`, data);

//Template endpoints

export const getDefaultReports = (datasourceSlug) => axios.get(`/datasources/${datasourceSlug}/default-reports`);

export const getLevelsFromTemplates = (datasourceSlug, templates) =>
    axios.get(`/datasources/${datasourceSlug}/default-reports/levels?default_reports=${templates}`);
// export const postTemplates = () => new Promise((resolve, reject) => {
//     setTimeout(() => {
//         reject()
//     }, 3000)
// })
export const postTemplates = (datasourceSlug, data) => axios.post(`/datasources/${datasourceSlug}/default-reports`, data);

// Users Endpoints

export const getUsers = () => axios.get(`/users`);

export const getUser = () => axios.get("/me");

export const addUser = (data) => axios.post(`/users/sent`, data);

export const updateUser = (data) => axios.patch(`/users`, data);

export const getRoles = () => axios.get("/roles");

export const generateApiKey = () => axios.post("/account/generatehash");

export const postTimezoneForAccount = (data) => axios.post(`/account/timezone`, data);

// Notification Endpoints

export const getNotificationEnginesList = () => axios.get("/notifications");

export const getNotificationEngineDetails = (engine_slug) => axios.get(`/notifications/${engine_slug}/details`);

export const getNotificationEngineConfigration = (engine_slug) =>
    axios.get(`/notifications/${engine_slug}/configured_notification`);

export const getNotificationEvents = () => axios.get(`/notifications/events`);

export const attachNotification = (account_notification_id, data) =>
    axios.post(`/notification/${account_notification_id}/events`, data);

// Dashboard Endpoints

export const getDatasourceStats = (data) => axios.get(`/logs${data}`);

export const getReportsLogs = (data) => axios.get(`/datasources/${data.datasource_slug}/logs${data.queryString}`);

export const getOverAllStats = (data) => axios.get(`/overall_stats${data}`);

export const getOverAllRunStats = (data) => axios.get(`/overall_run_stats${data}`);

export const getRecentActivities = (page) => axios.get(`/recent-activities?page=${page}`);

export const getRowsMovedByDate = (data) => axios.get(`/datewise-stats/rows-moved${data}`);

export const getDataMovedByDate = (data) => axios.get(`/datewise-stats/data-moved${data}`);

export const getErrorsForDatasources = (data) =>
    axios.get(`datasource/${data.datasourceSlug}/datewise-error-logs${data.queryString}`);

// Search Api
export const globalSearch = (query, cancelToken) =>
    axios.get(`search?q=${query}`, {
        cancelToken: cancelToken.token,
    });

// Realtime Notifications Endpoints

export const getNotificationsList = (data) => axios.get(`/notification-logs?category=notification&page=${data.page}`);

// Data Preview Endpoints
export const getDatasourcesForPreview = (data) =>
    axios.get(`/data_explorer/ui/datasources`, {
        headers: {
            apiKey: data.apikey,
        },
    });

export const getConfiguredReportsForDatasourceForPreveiw = (data) =>
    axios.get(`/data_explorer/ui/datasources/${data.datasourceId}/pipelines`, {
        headers: {
            apiKey: data.apikey,
        },
    });

export const getDataForPreview = (data) => {
    if (data.sort === null) {
        return axios.get(
            `$/data_explorer/ui/datasources/${data.datasourceId}/pipelines/${data.configuredReportId}/data?limit=${data.limit}&offset=${data.offset}`,
            {
                headers: {
                    apiKey: data.apikey,
                },
            }
        );
    }
    return axios.get(
        `$/data_explorer/ui/datasources/${data.datasourceId}/pipelines/${data.configuredReportId}/data?limit=${data.limit}&offset=${data.offset}&order_by=${data.sort}`,
        {
            headers: {
                apiKey: data.apikey,
            },
        }
    );
};
export const getSchemaForPreview = (data) =>
    axios.get(`/data_explorer/ui/datasources/${data.datasourceId}/pipelines/${data.configuredReportId}/schema`, {
        headers: {
            apiKey: data.apikey,
        },
    });

// for data preview by warehouse
export const getWarehousesForPreview = (data) =>
    axios.get(`/data_explorer/ui/warehouses`, {
        headers: {
            apiKey: data.apikey,
        },
    });

export const getTablesForWarehouseForPreveiw = (data) =>
    axios.get(`/data_explorer/ui/warehouses/${data.datasourceId}/tables`, {
        headers: {
            apiKey: data.apikey,
        },
    });

export const getDataByWarehouseForPreview = (data) => {
    if (data.sort === null) {
        return axios.get(
            `/data_explorer/ui/warehouses/${data.datasourceId}/tables/${data.configuredReportId}/data?limit=${data.limit}&offset=${data.offset}`,
            {
                headers: {
                    apiKey: data.apikey,
                },
            }
        );
    }
    return axios.get(
        `/data_explorer/ui/warehouses/${data.datasourceId}/tables/${data.configuredReportId}/data?limit=${data.limit}&offset=${data.offset}&order_by=${data.sort}`,
        {
            headers: {
                apiKey: data.apikey,
            },
        }
    );
};

export const getSchemaByWarehouseForPreview = (data) =>
    axios.get(`/data_explorer/ui/warehouses/${data.datasourceId}/tables/${data.configuredReportId}/schema`, {
        headers: {
            apiKey: data.apikey,
        },
    });

export const getSyncLevelOptions = (levelid, credentialsid) =>
    axiosReverse.get(`/sync_level/${levelid}/credentials/${credentialsid}`);

export const getSyncLevelOptionsWithSelectedOption = (levelid, credentialsid, currentSelectedOptionData) =>
    axiosReverse.post(`/sync_level/${levelid}/credentials/${credentialsid}`, currentSelectedOptionData);
