import { Button, Fade, Grid, Modal, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
    TOGGLE_RESTRICT_POPUP,
    TOGGLE_RESTRICTED_FEATURE_POPUP,
    TOGGLE_UPGRADE_TO_PREMIUM_POPUP,
} from "redux/actionTypes/actionTypes";
import UpgradeToPremiumPopup from "../UpgradeToPremiumPopup";
import StartIcon from "assets/images/onboarding/Star_icon_png.png";
import lockIcon from "assets/images/onboarding/Lock_icon_png.png";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
    cross: {
        // margin: "15px 20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        height: "65vh",
        width: "65vw",
        display: "flex",
        backgroundColor: "rgb(248,249,255,.85)",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4),
        borderRadius: "10px",
        position: "relative",
        border: "1px solid rgba(146, 158, 255, 1)",
    },
    icon: {
        paddingRight: "36px",
    },
}));

function AdblockerPrompt({ isEarlyAccessFeature }) {
    const restrictedFeaturePopup = useSelector((state) => state.uiStates.restrictedFeaturePopup);
    const restrictPopup = useSelector((state) => state.uiStates.restrictPopup);
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users);
    const navigate = useNavigate();
    const onStartFreeTrial = () => {
        dispatch({ type: TOGGLE_UPGRADE_TO_PREMIUM_POPUP, payload: true });
    };
    const handleClose = () => {
        dispatch({
            type: TOGGLE_RESTRICT_POPUP,
            payload: { show_popup: false, isClosable: null, showUpgradeButtons: null, showSupportButton: null },
        });
    };
    const handleHelpAndSupport = () => {
        document.getElementById("zohohc-asap-web-launcherbox").click();
        if (restrictPopup.isClosable || restrictedFeaturePopup.isClosable) {
            handleClose();
        }
    };

    return (
        <Modal
            className={classes.modal}
            open={restrictedFeaturePopup?.show_popup || restrictPopup?.show_popup}
            onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    // Set 'open' to false, however you would do that with your particular code.
                    dispatch({
                        type: TOGGLE_RESTRICT_POPUP,
                        payload: { show_popup: false, isClosable: null, showUpgradeButtons: null },
                    });
                }
            }}
        >
            <Fade in={restrictedFeaturePopup?.show_popup || restrictPopup?.show_popup}>
                <div className={classes.paper}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <img src={lockIcon} width={100} />
                        </Grid>
                        <Grid item container xs={12} style={{ height: "65%" }}>
                            {isEarlyAccessFeature ? (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            style={{ fontSize: 16, color: "rgba(40, 40, 118, 1)", fontWeight: 600 }}
                                        >
                                            This is an early access feature being released to limited users only.
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            style={{ fontSize: 15, color: "rgba(40, 40, 118, 1)", fontWeight: 500 }}
                                        >
                                            Once approved, you will receive a confirmation mail on the email id provided by you.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" container style={{ height: "50%" }}>
                                        <Grid item xs={12} container justifyContent="center">
                                            <Grid item xs={4}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    style={{ borderRadius: 30 }}
                                                    onClick={(e) =>
                                                        window.open(
                                                            "https://docs.google.com/forms/d/e/1FAIpQLSdUGZf9E_qF2ZeyXGWsNUXW3I7UeenUglgKb77x0sscgv8bgg/viewform"
                                                        )
                                                    }
                                                >
                                                    Request Access
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    fullWidth
                                                    style={{ borderRadius: 30 }}
                                                    onClick={(e) => navigate("/")}
                                                >
                                                    Home
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            style={{ fontSize: 16, color: "rgba(40, 40, 118, 1)", fontWeight: 600 }}
                                        >
                                            You do not have access to this feature in your current plan. Please upgrade to get
                                            access .
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            style={{
                                                fontSize: 16,
                                                color: "rgba(40, 40, 118, 1)",
                                                fontWeight: 600,
                                                marginTop: 10,
                                            }}
                                        >
                                            Your current plan is {user.user.plan_name}. View plan options{" "}
                                            <span
                                                style={{ color: "rgba(253, 149, 103, 1)", cursor: "pointer" }}
                                                onClick={() => {
                                                    if (restrictPopup?.isClosable || restrictedFeaturePopup?.isClosable) {
                                                        handleClose();
                                                    }
                                                    navigate("/settings?tab=subscription_management&subTab=0&open_plan=true");
                                                }}
                                            >
                                                here
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" container style={{ height: "50%" }}>
                                        {(restrictedFeaturePopup?.showSupportButton || restrictPopup?.showSupportButton) && (
                                            <Grid item xs={12} container justifyContent="center">
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        style={{ borderRadius: 30 }}
                                                        onClick={handleHelpAndSupport}
                                                    >
                                                        Contact Support{" "}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(restrictedFeaturePopup?.showUpgradeButtons || restrictPopup?.showUpgradeButtons) && (
                                            <Grid item xs={12} container justifyContent="center">
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        style={{ borderRadius: 30 }}
                                                        onClick={() => {
                                                            if (restrictPopup?.isClosable || restrictedFeaturePopup?.isClosable) {
                                                                handleClose();
                                                            }
                                                            navigate(
                                                                "/settings?tab=subscription_management&subTab=0&open_plan=true"
                                                            );
                                                        }}
                                                    >
                                                        Upgrade{" "}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!user?.user?.trial_used &&
                                            user?.user?.zoho_plan_code === "datachannel_free_monthly" &&
                                            (restrictedFeaturePopup?.showUpgradeButtons || restrictPopup?.showUpgradeButtons) && (
                                                <Grid item xs={12} container justifyContent="center">
                                                    <Grid item xs={4}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            fullWidth
                                                            style={{ borderRadius: 30 }}
                                                            onClick={onStartFreeTrial}
                                                        >
                                                            Start your free trial now{" "}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}

                                        <Grid item xs={12} container justifyContent="center">
                                            <Grid item xs={4}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    fullWidth
                                                    style={{ borderRadius: 30 }}
                                                    onClick={() => window.open("https://calendly.com/datachannelsales/15min")}
                                                >
                                                    Talk to Sales{" "}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {(restrictedFeaturePopup?.isClosable || restrictPopup?.isClosable) && (
                        <CloseIcon
                            className="hoverable"
                            style={{ position: "absolute", margin: 12, top: 0, right: 0 }}
                            onClick={() => handleClose()}
                            fontSize="small"
                        />
                    )}
                </div>
            </Fade>
        </Modal>
    );
}

export default AdblockerPrompt;
