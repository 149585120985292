import { DATASOURCE_LIST_LOADED, SET_IS_LOADING } from "../actionTypes/actionTypes";

const initialState = {
    dataSources: [],
    isLoading: false,
};

export const dataSourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATASOURCE_LIST_LOADED:
            console.log(action.payload);
            let depDataSource = [];
            let DataSource = [];
            action.payload.forEach((payload) => {
                if (payload?.display_name?.split(" ")?.splice(-1)[0]?.toLowerCase() === "dep") {
                    depDataSource.push(payload);
                } else {
                    DataSource.push(payload);
                }
            });
            action.payload = [...DataSource, ...depDataSource];
            return {
                ...state,
                dataSources: action.payload,
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};
