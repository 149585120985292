import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";

import {
    SET_REVERSE_CONNECTORS,
    SET_REVERSE_CONNECTORS_IS_LOADING,
} from "../actionTypes/actionTypes";

// import { getAPIWrapper } from "../../utils/api-try-catch-wrapper/get-api-wrapper";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchReverseConnectorsList = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_REVERSE_CONNECTORS_IS_LOADING,
        SET_REVERSE_CONNECTORS,
        {
            module: "reverseConnectors",
            method: "getAll",
        },
        HttpErrorHandler
    );
};

export { fetchReverseConnectorsList };
