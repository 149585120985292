import {
    SET_TABLE_SCHEMA_IS_LOADING,
    SET_TABLE_SCHEMA
} from "../actionTypes/actionTypes"

const initialState = {
    tableSchema: {
        "all_fields" : [],
        "selected_fields": []
    },
    tableSchemaIsLoading: false
};

export const configuredReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TABLE_SCHEMA:
            return {
                ...state,
                tableSchema: action.payload
            }
        case SET_TABLE_SCHEMA_IS_LOADING:
            return {
                ...state,
                tableSchemaIsLoading: action.payload
            }
        default:
            return state
    }
};
