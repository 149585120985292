import {
    SET_DBT_CLOUD_TRANSFORMATIONS,
    SET_DBT_CLOUD_TRANSFORMATIONS_LOADING,
    SET_DBT_CLOUD_CREDENTIALS,
    SET_DBT_CLOUD_CREDENTIALS_LOADING,
} from "../actionTypes/actionTypes";

const initialState = {
    dbtCloudTransformations: [],
    dbtCloudCredentials: [],
    isLoading: false,
    credentialsLoading: false,
};

export const dbtCloudTransformationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DBT_CLOUD_TRANSFORMATIONS:
            return {
                ...state,
                dbtCloudTransformations: action.payload,
            };
        case SET_DBT_CLOUD_TRANSFORMATIONS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_DBT_CLOUD_CREDENTIALS:
            return {
                ...state,
                dbtCloudCredentials: action.payload,
            };
        case SET_DBT_CLOUD_CREDENTIALS_LOADING:
            return {
                ...state,
                credentialsLoading: action.payload,
            };
        default:
            return state;
    }
};
