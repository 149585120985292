import { SET_SEGMENTS, SET_SEGMENTS_IS_LOADING } from "../actionTypes/actionTypes";

const initialState = {
    segments: [],
    segmentsIsLoading: false,
};

export const segmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEGMENTS:
            return {
                ...state,
                segments: action.payload,
            };
        case SET_SEGMENTS_IS_LOADING:
            return {
                ...state,
                segmentsIsLoading: action.payload,
            };
        default:
            return state;
    }
};
