import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#FD9567",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#2E394B",
            contrastText: "#FFFFFF",
        },
        brand: {
            main: "#2E394B",
        },
        action: {
            main: "#EAFFEE",
        },
        background1: {
            main: "#F3F6FF",
        },
    },
    // breakpoints: {
    //     values: {
    //       xs: 0,
    //       sm: 600,
    //       md: 960,
    //       lg: 1280,
    //       xl: 1920,
    //     },
    //   },
    typography: {
        fontFamily: ["Montserrat", "Nunito"],
        h2: {
            fontFamily: "Montserrat, Nunito",
            fontSize: "22px",
            lineHeight: "29px",
            fontWeight: 600,
            letterSpacing: "0px",
            color: "#626262",
        },
        h3: {
            fontFamily: "Montserrat, Nunito",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 600,
            letterSpacing: "0px",
            color: "#2C2C2C",
        },
        h4: {
            textAlign: "left",
            fontFamily: "Montserrat, Nunito",
            fontSize: "30px",
            lineHeight: "44px",
            fontWeight: "normal",
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        h5: {
            textAlign: "left",
            fontFamily: "Montserrat, Nunito",
            fontSize: "28px",
            lineHeight: "34px",
            fontWeight: 500,
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        h6: {
            textAlign: "left",
            fontFamily: "Montserrat, Nunito",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "normal",
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        subtitle1: {
            fontFamily: "Montserrat, Nunito",
            fontSize: "12px",
            lineHeight: "19px",
            fontWeight: 500,
            letterSpacing: "0px",
            color: "#2C2C2C",
        },
        subtitle2: {
            fontFamily: "Montserrat, Nunito",
            fontSize: "9px",
            lineHeight: "14px",
            fontWeight: "normal",
            letterSpacing: "0px",
            opacity: 1,
            color: "#626262",
        },
        body1: {
            fontFamily: "Montserrat, Nunito",
            fontSize: "14px",
        },
        caption: {
            fontFamily: "Montserrat, Nunito",
        },
        button: {
            fontFamily: "Montserrat, Nunito",
        },
    },
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: "11px",
                },
            },
        },

        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: "24px",
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: "8px",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginRight: "0px",
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: "Montserrat, Nunito",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                inputMarginDense: {
                    paddingTop: "8.5px",
                    paddingBottom: "8.5px",
                    fontSize: "12px",
                },
            },
            defaultProps: {
                size: "small",
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    fontSize: "10px",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "12px",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginTop: "8px",
                    marginBottom: "8px",
                    color: "#909090",
                    fontSize: "12px",
                },
            },
            defaultProps: {
                size: "small",
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    marginTop: "15px",
                    marginBottom: "15px",
                    minWidth: "unset",
                    textTransform: "unset",
                },
                containedPrimary: {
                    color: "#FFFFFF",
                },
                contained: {
                    boxShadow: "0px 3px 10px #0000000F",
                },
            },
            // outlined: {
            //     color: "#FFFFFF",
            //     border: "1px solid #FFFFFF",
            // },
        },
        // MuiDrawer: {
        //     paper: {
        //         position: "relative",
        //     },
        // },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: "unset",
                },
                defaultGrey: {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiTimelineItem: {
            styleOverrides: {
                missingOppositeContent: {
                    "&:before": {
                        flex: "unset",
                        padding: "0px",
                    },
                },
            },
        },
        MuiTimelineConnector: {
            styleOverrides: {
                root: {
                    backgroundColor: "#B8C2CC",
                    width: "1px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    verticalAlign: "baseline",
                    borderBottom: "unset",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    padding: "8px",
                    fontSize: "12px",
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 3px 10px #0000000F",
                    border: "1px solid #EDEDED",
                    borderRadius: "4px",
                    marginTop: "16px",
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: "13px",
                },
            },
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    padding: "unset",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: "1px solid #B8C2CC",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                caption: {
                    fontWeight: 500,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "unset",
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    color: "#2c2c2c",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: 12,
                },
                defaultProps: {
                    size: "small",
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    marginBottom: 0,
                },
                displayedRows: {
                    marginBottom: 0,
                },
            },
        },
    },
});

export default theme;
