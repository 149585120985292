import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "config/firebase";
import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import EventTrackerComponent from "services/EventTracker/EventTrackerComponent";
import RealTimeNotifications from "services/Realtime/Notification";
import Notifier from "./components/common/Notifier/Notifier";
import KeyboardShortcut from "components/common/KeyboardShortcut/KeyboardShortcut";
import { persistor, Store } from "./redux/store";
import Routing from "./services/routing";
import "./styles/global.css";
import theme from "./utils/theme";

function FallBack() {
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
            <CircularProgress />
        </Grid>
    );
}

function App() {
    window.addEventListener("storage", (event) => {
        try {
            const oldValue = JSON.parse(JSON.parse(event.oldValue)?.users);
            const newValue = JSON.parse(JSON.parse(event.newValue)?.users);
            if (oldValue?.user?.email !== newValue?.user?.email) {
                document.location.reload();
            }
        } catch (e) {
            // console.log(e);
        }
    });

    return (
        <>
            <Provider store={Store}>
                <PersistGate loading={null} persistor={persistor}>
                    <EventTrackerComponent />
                    <SnackbarProvider>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <Suspense fallback={<FallBack />}>
                                    <Notifier />
                                    <RealTimeNotifications />
                                    <BrowserRouter>
                                        <Routing />
                                        <KeyboardShortcut />
                                    </BrowserRouter>
                                </Suspense>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
