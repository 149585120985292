import {
    TRANSFORMATIONS_LIST_LOADED,
    SET_TRANSFORMATIONS_IS_LOADING,
    TRANSFORMATIONS_LOGS_LOADED,
    SET_TRANSFORMATIONS_LOGS_IS_LOADING,
} from "../actionTypes/actionTypes"

const initialState = {
    transformations: [],
    isLoading: false,
    transformationLogs:[],
    isTransformationLogsLoading:false,
};

export const transformationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSFORMATIONS_LIST_LOADED:
            return {
                ...state,
                transformations: action.payload
            }
        case SET_TRANSFORMATIONS_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case TRANSFORMATIONS_LOGS_LOADED:
            console.log(action.payload)
            return {
                ...state,
                transformationLogs:action.payload
            }
        case SET_TRANSFORMATIONS_LOGS_IS_LOADING:
            console.log(action.payload)
            return{
                ...state,
                isTransformationLogsLoading:action.payload
            }
        default:
            return state
    }
};
