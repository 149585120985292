import React, { Component } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import ErrorImage from "../assets/errorImage.jpg";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100vh",
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "2em",
    },

    title: {
        fontSize: 20,
        fontWeight: 500,
    },
    messageDiv: {
        alignSelf: "center",
        marginRight: 10,
        fontSize: 12,
        fontWeight: 500,
        color: "#2C2C2C",
    },

    image: {
        backgroundColor: "#FAFAFA",
        // width: "50vw",
    },
    content: {
        fontSize: 50,
        fontWeight: 750,
        fontFamily: "Montserrat",
    },
    btn: {
        fontFamily: "Nunito",
    },
}));

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    resetError = () => {
        this.setState({ hasError: false });
    };

    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            return <ErrorFallback error={error} resetError={this.resetError} />;
        }

        return this.props.children;
    }
}

const ErrorFallback = ({ error, resetError }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleRefreshClick = () => {
        resetError();
        navigate("/");
    };

    return (
        <div container className={classes.container}>
            <div>
                <Typography className={classes.content}>Oops, we hit a snag!</Typography>
            </div>

            <div className={classes.messageDiv}>
                <Typography className={classes.title}>Please refresh while we work on the issue</Typography>
            </div>
            <div>
                <img src={ErrorImage} alt="Error" className={classes.image} />
            </div>
            <Button variant="contained" size="medium" onClick={handleRefreshClick} className={classes.btn}>
                Refresh
            </Button>
        </div>
    );
};

const errorBoundary = (WrappedComponent) => {
    return class extends ErrorBoundary {
        render() {
            const { hasError, error } = this.state;

            if (hasError) {
                return <ErrorFallback error={error} resetError={this.resetError} />;
            }

            return <WrappedComponent {...this.props} />;
        }
    };
};

export { errorBoundary };
