import { TRANSFORMATIONS_LIST_LOADED, SET_TRANSFORMATIONS_IS_LOADING,SET_TRANSFORMATIONS_LOGS_IS_LOADING,TRANSFORMATIONS_LOGS_LOADED } from "../actionTypes/actionTypes";
// import { getTransformations } from "../../services/Api/api"
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchTransformations = () => async (dispatch) => {
	apiWrapper(
		dispatch,
		SET_TRANSFORMATIONS_IS_LOADING,
		TRANSFORMATIONS_LIST_LOADED,
		{
			module: "transformation",
			method: "getAll"
		},
		HttpErrorHandler
	);
};

const fetchTransformationLogs = (data)=> async (dispatch)=>{
	apiWrapper(
		dispatch,
		SET_TRANSFORMATIONS_LOGS_IS_LOADING,
		TRANSFORMATIONS_LOGS_LOADED,
		{
			module:"transformation",
			method:"getLogs",
			apiData: {
				transformationId:data.transformationId
			}
		},
		HttpErrorHandler
	)
}

export { fetchTransformations,fetchTransformationLogs };
