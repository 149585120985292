import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";
import DatasourceTransformation from "../DataTransformations/DatasourceTransformation";

class DatasourceEndpoints extends BaseEndpointsClass {
    constructor() {
        super();
        this.resourceEndpoint = `datasource`;
        this.transformer = new DatasourceTransformation();
    }
    getErrorsForDatasource(data) {
        console.log(data);
        return axios.get(`${this.resourceEndpoint}/${data.apiData.datasourceSlug}/datewise-error-logs${data.apiData.data}`);
    }
    getPartialSucesssForDatasource(data) {
        console.log(data);
        return axios.get(
            `${this.resourceEndpoint}/${data.apiData.datasourceSlug}/datewise-partial-success-logs${data.apiData.data}`
        );
    }
    getAll() {
        return axios.get("/datasources");
    }
    getOne(data) {
        return axios.get(`/datasources/${data.apiData.slug}`);
    }
    getCredentials(data) {
        return axios.get(`/datasources/${data.apiData.slug}/credentials`);
    }
    getReports(data) {
        return axios.get(`/datasources/${data.apiData.slug}/reports`);
    }
    getConfiguredReports(data) {
        return axios.get(`/datasources/${data.apiData.slug}/configured_reports`);
    }
    getTemplates(data) {
        return axios.get(`/datasources/${data.apiData.slug}/default-reports`);
    }
    getDatasourceStats(data) {
        return axios.get(`/logs${data.apiData.data}`);
    }
    getWarehousesforDatasource(data) {
        return axios.get(`/datawarehouses/${data.apiData.slug}`);
    }
    setWarehouseForDatasource(data) {
        return axios.post(`/datawarehouses/${data.apiData.slug}`, data.apiData.data);
    }
    deleteWarehouseForDatasource(data) {
        return axios.delete(`/datawarehouses/${data.apiData.slug}`, { data: data.apiData.data });
    }
    getConfiguredDatasources(data) {
        return axios.get(`/datasources/configured`);
    }
    addEventForCredentialRedirectUrl(data) {
        return axios.post(
            `/credentials/credential-redirect-urls/${data.apiData.redirect_url_id}/events?token=${data.access_token}`,
            data.apiData.data
        );
    }
    deleteTemplates(data) {
        return axios.delete(`/datasources/${data.apiData.slug}/default-reports`, { data: data.apiData.data });
    }
    getTemplateById(data) {
        return axios.get(`/datasources/${data.apiData.slug}/default-reports/${data.apiData.reportId}`);
    }
    patchTemplate(data) {
        return axios.patch(`/datasources/${data.apiData.slug}/templates/${data.apiData.reportId}`, data.apiData.data);
    }
}

export default DatasourceEndpoints;
