import axios from "config/axios";
import BaseEndpointsClass from "./BaseEndpointsClass";

class AssetsEndpoints extends BaseEndpointsClass {
    constructor(data) {
        super();
        this.resourceEndpoint = "accounts/assets";
        this.transformer = null;
    }
    fetchAssets(data) {
        return axios.get(`${this.resourceEndpoint}`);
    }
}

export default AssetsEndpoints;
