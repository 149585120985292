import React from "react";
import useEventTracker from "./useEventTracker";

const EventTrackerComponent = () => {
    const EventTracker = useEventTracker();
    React.useEffect(() => {
        EventTracker.init("DataChannelFrontendV2");
        EventTracker.setUserId();
    }, []);
    return null;
};

export default EventTrackerComponent;
