import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";

import {
    SET_REVERSE_CONNECTOR,
    SET_REVERSE_CONNECTOR_IS_LOADING,
    SET_SYNCS,
    SET_SYNCS_IS_LOADING,
    SET_CONFIGURED_SYNCS,
    SET_CONFIGURED_SYNCS_IS_LOADING,
} from "../actionTypes/actionTypes";

// import { getAPIWrapper } from "../../utils/api-try-catch-wrapper/get-api-wrapper";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchReverseConnector = (slug) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_REVERSE_CONNECTOR_IS_LOADING,
        SET_REVERSE_CONNECTOR,
        {
            module: "reverseConnectors",
            method: "getOne",
            apiData: {
                slug: slug,
            },
        },
        HttpErrorHandler
    );
};

const fetchSyncs = (slug) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_SYNCS_IS_LOADING,
        SET_SYNCS,
        {
            module: "reverseConnectors",
            method: "getSyncs",
            apiData: {
                slug: slug,
            },
        },
        HttpErrorHandler
    );
};

const fetchConfiguredSyncs = (slug) => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_CONFIGURED_SYNCS_IS_LOADING,
        SET_CONFIGURED_SYNCS,
        {
            module: "reverseConnectors",
            method: "getConfiguredSyncs",
            apiData: {
                slug: slug,
            },
        },
        HttpErrorHandler
    );
};

export { fetchReverseConnector, fetchSyncs, fetchConfiguredSyncs };
